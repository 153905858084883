import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SideMenu, Header, Success, Failed } from '../../components';
import { Country, State, City } from 'country-state-city';
import { customStyles } from '../../Utils/utils';
import { Icon } from '@ohif/ui';
import { reportTemplateValidationSchema } from '../auth/helper/Schema';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'platform/custom/store/store';
import { hospitalTemplateApi } from '../../rtk-apis/clinics/reportTemplate';
import { getHospitalTemplateApi } from '../../rtk-apis/clinics/getReportTemplate';
import { clearState } from '../../store/slices/clinics/reportTemplate';
import { useNavigate } from 'react-router-dom';
import { uploadClinicLogoApi } from '../../rtk-apis/clinics/uploadClinicLogo';

const ReportTemplate = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const hospitalId = sessionStorage.getItem('hospitalId');

  const [ReportTempleteDetails, setReportTempleteDetails] = useState({});

  const [headerStateOptions, setHeaderStateOptions] = useState([]);
  const [headerCityOptions, setHeaderCityOptions] = useState([]);

  const [headerSelectedCountry, setHeaderSelectedCountry] = useState('');
  const [headerSelectedState, setHeaderSelectedState] = useState('');
  const [headerSelectedCity, setHeaderSelectedCity] = useState('');

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSuccessProfileModal, setShowSuccessProfileModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const breadcrumbs = [{ label: 'Report Template', icon: 'sidebar-icon' }];

  // ******************** REDUX STATES ****************************************
  const { status } = useSelector((state: RootState) => state.reportTemplateApiResponse);

  const {
    status: getReportTemplateStatus,
    result: getReportTemplteData,
    msg,
    loading,
    error,
  } = useSelector((state: RootState) => state.getReportTemplateApiResponse);

  // ********************************************************************************
  useEffect(() => {
    if (getReportTemplteData?.data?.length > 0) {
      setReportTempleteDetails(getReportTemplteData?.data[0]);
      setHeaderSelectedCountry(getReportTemplteData?.data[0]?.Country);
      setHeaderSelectedState(getReportTemplteData?.data[0]?.State);
      setHeaderSelectedCity(getReportTemplteData?.data[0]?.City);
      setImagePreview(getReportTemplteData?.data[0]?.hospitalLogo);
    }
  }, [getReportTemplteData]);

  const initialValues = {
    hospitalLogo: ReportTempleteDetails?.hospitalLogo ? ReportTempleteDetails?.hospitalLogo : '',
    hospitalName: ReportTempleteDetails?.name ? ReportTempleteDetails?.name : '',
    headerCountryCode: ReportTempleteDetails?.countryCode
      ? ReportTempleteDetails?.countryCode
      : '+91',
    headerContactNumber: ReportTempleteDetails?.contactDetails
      ? ReportTempleteDetails?.contactDetails
      : '',
    headerEmailAddress: ReportTempleteDetails?.email ? ReportTempleteDetails?.email : '',
    headerAddress: ReportTempleteDetails?.Address ? ReportTempleteDetails?.Address : '',
    headerZipCode: ReportTempleteDetails?.ZipCode ? ReportTempleteDetails?.ZipCode : '',
    headerCountry: ReportTempleteDetails?.Country ? ReportTempleteDetails?.Country : '',
    headerState: ReportTempleteDetails?.State ? ReportTempleteDetails?.State : '',
    headerCity: ReportTempleteDetails?.City ? ReportTempleteDetails?.City : '',
    website: ReportTempleteDetails?.website ? ReportTempleteDetails?.website : '',
    fax: ReportTempleteDetails?.fax ? ReportTempleteDetails?.fax : '',
  };

  // ***************************** GET CITY STATES**************************************************

  useEffect(() => {
    if (headerSelectedCountry) {
      const states = State.getStatesOfCountry(headerSelectedCountry);
      setHeaderStateOptions(states);
      setHeaderCityOptions([]);
    }
  }, [headerSelectedCountry]);

  useEffect(() => {
    if (headerSelectedState) {
      const cities = City.getCitiesOfState(headerSelectedCountry, headerSelectedState);
      setHeaderCityOptions(cities);
    }
  }, [headerSelectedState, headerSelectedCountry]);

  // *********************************************************************************

  useEffect(() => {
    if (status === 'SUCCESS') {
      setShowSuccessModal(true);
      dispatch(clearState());
    } else if (status === 'ERROR') {
      setShowErrorModal(true);
      dispatch(clearState());
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (hospitalId) {
      dispatch(getHospitalTemplateApi());
    }
  }, [hospitalId]);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    folderPath: string
  ) => {
    const file = event.target.files?.[0];
    const folderName = 'uploads';

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('folderName', folderName);

      const previewUrl = URL.createObjectURL(file);
      // setImagePreview(previewUrl);

      try {
        const result = await dispatch(uploadClinicLogoApi({ formData })).unwrap();
        setImagePreview(result['result']);
        console.log('File uploaded successfully:', result);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handleFormSubmit = async formData => {
    try {
      const payload = {
        hospitalId: hospitalId,
        hospitalLogo: imagePreview,
        hospitalHeader: {
          hospitalName: formData?.hospitalName,
          contactNumberCountryCode: formData?.headerCountryCode,
          contactNumber: formData?.headerContactNumber,
          email: formData?.headerEmailAddress,
          address: formData?.headerAddress,
          zipCode: formData?.headerZipCode,
          country: formData?.headerCountry,
          state: formData?.headerState,
          city: formData?.headerCity,
          website: formData?.website,
          fax: formData?.fax,
        },
      };
      await dispatch(hospitalTemplateApi(payload)).unwrap();
    } catch (error) {
      console.error('Failed to update hospital template:', error);
    }
  };

  const handleCancel = () => {
    navigate('/reports');
  };

  return (
    <section
      className="layoutContainer"
      style={customStyles.layoutBackground}
    >
      <SideMenu />
      <div className="layoutGrid">
        <div className="page">
          <Header breadcrumbs={breadcrumbs} />
          <div className="page__body">
            <Formik
              initialValues={initialValues}
              validationSchema={reportTemplateValidationSchema}
              onSubmit={handleFormSubmit}
              enableReinitialize={true}
            >
              {({ setFieldValue, isSubmitting, values }) => (
                <Form className="form">
                  <div className="page__body__head">
                    <h1 className="title title--h6 title--normal">Update Report Template</h1>
                    <div className="btn__group">
                      <button
                        className="btn btn__white btn__outlined"
                        type="button"
                        disabled={isSubmitting}
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn__primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <section className="form__section">
                    <h1 className="form__section__title">Template Details</h1>
                    <div className="formRow">
                      <div className="formCol">
                        <div className="form__Field">
                          <label
                            className="form__uploadProfile"
                            htmlFor="hospitalLogo"
                            style={{ position: 'relative', display: 'block' }}
                          >
                            {imagePreview ? (
                              <img
                                src={imagePreview}
                                alt="Hospital Logo"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  zIndex: 1,
                                }}
                              />
                            ) : (
                              <>
                                <Icon name="form-upload-profile-background" />
                                <span className="form__uploadProfile__overlay">
                                  <Icon name="form-upload-profile-overlay" />
                                </span>
                              </>
                            )}
                          </label>
                          <input
                            type="file"
                            id="hospitalLogo"
                            name="hospitalLogo"
                            className="form__uploadfiled"
                            accept="image/*"
                            onChange={event => {
                              const folderPath = 'uploads';
                              handleImageChange(event, folderPath);
                              setFieldValue('hospitalLogo', event.currentTarget.files[0]);
                            }}
                          />
                          <ErrorMessage
                            name="hospitalLogo"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="hospitalName"
                            className="form__label"
                          >
                            Hospital Name<span className="required-asterisk">*</span>
                          </label>
                          <Field
                            type="text"
                            id="hospitalName"
                            name="hospitalName"
                            className="form__input form__input--sm"
                            placeholder="Name"
                          />
                          <ErrorMessage
                            name="hospitalName"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="headerEmailAddress"
                            className="form__label"
                          >
                            Email Address<span className="required-asterisk">*</span>
                          </label>
                          <Field
                            type="email"
                            id="headerEmailAddress"
                            name="headerEmailAddress"
                            className="form__input form__input--sm"
                            placeholder="Email Address"
                          />
                          <ErrorMessage
                            name="headerEmailAddress"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="headerAddress"
                            className="form__label"
                          >
                            Address<span className="required-asterisk">*</span>
                          </label>
                          <Field
                            type="text"
                            id="headerAddress"
                            name="headerAddress"
                            className="form__input form__input--sm"
                            placeholder="Address"
                          />
                          <ErrorMessage
                            name="headerAddress"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                      <div className='formCol'>
                        <div className='formRow formRow__4'>
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="headerZipCode"
                                className="form__label"
                              >
                                Zip Code<span className="required-asterisk">*</span>
                              </label>
                              <Field
                                type="text"
                                id="headerZipCode"
                                name="headerZipCode"
                                className="form__input form__input--sm"
                                placeholder="Zip Code"
                              />
                              <ErrorMessage
                                name="headerZipCode"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="headerCountry"
                                className="form__label"
                              >
                                Country<span className="required-asterisk">*</span>
                              </label>
                              <Field
                                as="select"
                                id="headerCountry"
                                name="headerCountry"
                                value={headerSelectedCountry}
                                onChange={e => {
                                  setHeaderSelectedCountry(e.target.value);
                                  setFieldValue('headerCountry', e.target.value);
                                  setFieldValue('headerState', '');
                                  setFieldValue('headerCity', '');
                                  setHeaderSelectedState('');
                                  setHeaderSelectedCity('');
                                }}
                                className="form__select form__select--sm"
                              >
                                <option value="">-Select-</option>
                                {Country.getAllCountries().map(country => (
                                  <option
                                    key={country.isoCode}
                                    value={country.isoCode}
                                  >
                                    {country.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="headerCountry"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="headerState"
                                className="form__label"
                              >
                                State<span className="required-asterisk">*</span>
                              </label>
                              <Field
                                as="select"
                                id="headerState"
                                name="headerState"
                                value={headerSelectedState}
                                onChange={e => {
                                  setHeaderSelectedState(e.target.value);
                                  setFieldValue('headerState', e.target.value);
                                  setFieldValue('headerCity', '');
                                  setHeaderSelectedCity('');
                                }}
                                className="form__select form__select--sm"
                                disabled={!headerSelectedCountry}
                              >
                                <option value="">-Select-</option>
                                {headerStateOptions.map(state => (
                                  <option
                                    key={state.isoCode}
                                    value={state.isoCode}
                                  >
                                    {state.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="headerState"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="headerCity"
                                className="form__label"
                              >
                                City<span className="required-asterisk">*</span>
                              </label>
                              <Field
                                as="select"
                                id="headerCity"
                                name="headerCity"
                                value={headerSelectedCity}
                                onChange={e => {
                                  setHeaderSelectedCity(e.target.value);
                                  setFieldValue('headerCity', e.target.value);
                                }}
                                className="form__select form__select--sm"
                                disabled={!headerSelectedState}
                              >
                                <option value="">-Select-</option>
                                {headerCityOptions.map(city => (
                                  <option
                                    key={city.name}
                                    value={city.name}
                                  >
                                    {city.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="headerCity"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="headerContactNumber"
                            className="form__label"
                          >
                            Contact Number<span className="required-asterisk">*</span>
                          </label>
                          <div className="form__groupField form__groupField--phonenumber">
                            <Field
                              as="select"
                              id="headerCountryCode"
                              name="headerCountryCode"
                              className="form__select form__select--sm"
                              defaultValue="+91"
                            >
                              <option value="+91">+91</option>
                              <option value="+61">+61</option>
                            </Field>
                            <Field
                              type="text"
                              id="headerContactNumber"
                              name="headerContactNumber"
                              maxLength={10}
                              className="form__input form__input--sm"
                              placeholder="Contact Number"
                            />
                          </div>
                          <ErrorMessage
                            name="headerContactNumber"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="website"
                            className="form__label"
                          >
                            Website
                          </label>
                          <Field
                            type="text"
                            id="website"
                            name="website"
                            className="form__input form__input--sm"
                            placeholder="Website"
                          />
                        </div>
                      </div>
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="fax"
                            className="form__label"
                          >
                            Fax
                          </label>
                          <Field
                            type="text"
                            id="fax"
                            name="fax"
                            className="form__input form__input--sm"
                            placeholder="Fax"
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Success
        title="Clinic details updated."
        isSuccessModalOpen={showSuccessModal}
        handleCloseModal={() => setShowSuccessModal(false)}
      />
      <Success
        title="Profile picture updated."
        isSuccessModalOpen={showSuccessProfileModal}
        handleCloseModal={() => setShowSuccessProfileModal(false)}
      />
      <Failed
        title="Clinic details update failed."
        isFailedModalOpen={showErrorModal}
        handleCloseModal={() => setShowErrorModal(false)}
      />
    </section>
  );
};

export default ReportTemplate;
