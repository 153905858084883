import React, { useEffect, useState } from 'react';
import { Icon } from '@ohif/ui';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { loginApi } from '../../rtk-apis/auth/login';
import { customStyles } from '../../Utils/utils';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { isValidUser } from '../../Utils/authUtils';
import { loginInitialValues, loginValidationSchema } from './helper/Schema';
import { useNavigate } from 'react-router-dom';
import { Failed, Loader, Success } from '../../components';
import '../../styled/pages/login.scss';
import { clearState } from '../../store/slices/Auth/login';

const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loginResponse = useSelector((state: RootState) => state.loginResponse);
  const [showPassword, setShowPassword] = useState(false);
  const [displayMessage, setDisplayMessage] = useState('');
  const [apiRejectedReponse, setApiRejectedReponse] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submitLogin = (
    values: { email: string; password: string; rememberMe: boolean },
    { setSubmitting }: any
  ) => {
    const payload = {
      email: values.email.toLowerCase(),
      password: values.password,
    };

    // Dispatch login API action
    setLoading(true);
    dispatch(loginApi(payload));
    setSubmitting(false);
  };

  useEffect(() => {
    dispatch(clearState());
    setLoading(false);
    if (loginResponse.status === 'SUCCESS') {
      setDisplayMessage(loginResponse?.result?.msg);
      setShowSuccessModal(true);

      // Save token
      const token = loginResponse?.result?.accessToken;

      if (token) {
        // Save token based on the "Remember Me" toggle
        if (rememberMe) {
          console.log(loginResponse);
          localStorage.setItem('token', token);
          localStorage.setItem('role', loginResponse?.result?.data?.role);
          localStorage.setItem('name', loginResponse?.result?.data?.name);
          localStorage.setItem(
            'hospitalId',
            loginResponse?.result?.data?.hospitalId || loginResponse?.result?.data?._id
          );
          localStorage.setItem('email', loginResponse?.result?.data?.email);
          localStorage.setItem('type', loginResponse?.result?.data?.type);
          localStorage.setItem('hospitalName', loginResponse?.result?.data?.name);
        } else {
          console.log(loginResponse);
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('role', loginResponse?.result?.data?.role);
          sessionStorage.setItem('name', loginResponse?.result?.data?.name);
          sessionStorage.setItem(
            'hospitalId',
            loginResponse?.result?.data?.hospitalId || loginResponse?.result?.data?._id
          );
          sessionStorage.setItem('email', loginResponse?.result?.data?.email);
          sessionStorage.setItem('type', loginResponse?.result?.data?.type);
          sessionStorage.setItem('hospitalName', loginResponse?.result?.data?.name);
        }
        setTimeout(() => {
          setShowSuccessModal(false);
          navigate('/dashboard');
        }, 500);
      } else {
        setApiRejectedReponse(loginResponse?.result?.msg);
        console.error('Token not found in login response');
      }
    }

    if (loginResponse.status === 'ERROR') {
      setShowFailedModal(true);
      setApiRejectedReponse(loginResponse?.msg);
    }
  }, [loginResponse.status]);

  useEffect(() => {
    // check if the user is logged in, if not navigate to home
    if (isValidUser()) {
      navigate('/clinics');
    }
  }, []);

  return (
    <>
      <section
        className="login"
        style={customStyles.loginBackground}
      >
        <div className="login__container">
          <div className="login__wrap">
            <div className="branding">
              <div className="branding__info">
                <h1 className="title title--h3 title--normal">Hi, Welcome Back!</h1>
              </div>
            </div>
            <div className="login__form">
              <Formik
                initialValues={loginInitialValues}
                validationSchema={loginValidationSchema}
                onSubmit={submitLogin}
              >
                {({ isSubmitting, errors, values, setFieldValue }) => (
                  <Form>
                    <div className="form__group">
                      <div className="form__groupField">
                        <Icon name="envelope-icon" />
                        <Field
                          type="email"
                          name="email"
                          autocomplete="off"
                          className="form__input"
                          placeholder="Email address"
                        />
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="form__error"
                      />
                    </div>

                    <div className="form__group">
                      <div className="form__groupField">
                        <Icon name="lock-icon" />
                        <Field
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          autocomplete="off"
                          className="form__input"
                          placeholder="Password"
                        />
                        <button
                          type="button"
                          className="btn"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <Icon name={showPassword ? 'eye-open' : 'eye-close'} />
                        </button>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="form__error"
                      />
                    </div>
                    {apiRejectedReponse && (
                      <div className="form__error">
                        <p>{apiRejectedReponse}</p>
                      </div>
                    )}
                    <div className="form__flexrow">
                      <div className="form__group">
                        <div className="form__switchBtn">
                          <div className="form__switchBtn__option">
                            <div className="switchBtn">
                              <input
                                type="checkbox"
                                className="toggle"
                                id="Active"
                                name="rememberMe"
                                checked={rememberMe}
                                onChange={() => setRememberMe(!rememberMe)}
                              />
                              <label htmlFor="Active"></label>
                            </div>
                            <div className="form__label">
                              <span className="textPrimary">Remember me</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form__group">
                        <p className="para para--right">
                          <button
                            type="button"
                            onClick={() => navigate('/forgotPassword')}
                            className="link"
                          >
                            Forgot password?
                          </button>
                        </p>
                      </div>
                    </div>

                    <div className="form__button">
                      <button
                        className="btn btn__primary btn--full"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Signing In...' : 'Sign In'}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Success
          handleCloseModal={() => setShowSuccessModal(false)}
          title={displayMessage}
          isSuccessModalOpen={showSuccessModal}
        />
        <Failed
          handleCloseModal={() => setShowFailedModal(false)}
          title={apiRejectedReponse}
          isFailedModalOpen={showFailedModal}
        />
        <Loader isLoading={loading} />
      </section>
    </>
  );
};

export default Login;
