import { createSlice } from '@reduxjs/toolkit';
import { getHospitalDoctorsList } from '../../../rtk-apis/Reports/getHospitalDoctorsList';

const initialState = {
  status: '',
  data: '',
  msg: '',
  loading: false,
};

const getHospitalDoctorsListSlice = createSlice({
  name: 'getHospitalDoctorsListSlice',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.data = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getHospitalDoctorsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getHospitalDoctorsList.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.data = action?.payload?.data;
        state.msg = action?.payload?.msg;
      })
      .addCase(getHospitalDoctorsList.rejected, (state, action) => {
        state.loading = false;
        state.status = 'FAILED';
        state.data = (action?.payload as string) || 'Something went wrong';
      });
  },
});

export const { clearState } = getHospitalDoctorsListSlice.actions;
export default getHospitalDoctorsListSlice.reducer;
