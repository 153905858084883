import { createSlice } from '@reduxjs/toolkit';
import { loginApi } from '../../../rtk-apis/auth/login';

const initialState = {
  status: '',
  result: '',
  msg: '',
  loading: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loginApi.pending, (state, action) => {
        state.status = 'LOADING';
        state.loading = true;
        state.msg = 'LOADING...';
      })
      .addCase(loginApi.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
        state.msg = 'Login successfully.';
      })
      .addCase(loginApi.rejected, (state, action) => {
        state.loading = false;
        state.status = 'ERROR';
        state.result = '';
        state.msg = action?.payload || 'Something went wrong!';
      });
  },
});

export default loginSlice.reducer;
export const { clearState } = loginSlice.actions;
