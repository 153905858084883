import { createSlice } from '@reduxjs/toolkit';
import { changePasswordApi } from '../../../rtk-apis/auth/changePassword';

const initialState = {
  status: '',
  result: '',
  msg: '',
  loading: false,
};

const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(changePasswordApi.pending, state => {
        state.status = 'LOADING';
        state.loading = true;
        state.msg = 'LOADING...';
      })
      .addCase(changePasswordApi.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action.payload;
        state.msg = action.payload?.message || 'Password updated successfully.';
      })
      .addCase(changePasswordApi.rejected, (state, action) => {
        state.loading = false;
        state.status = 'ERROR';
        state.result = '';
        state.msg = (action.payload as string) || 'Something went wrong!';
      });
  },
});

export default changePasswordSlice.reducer;
export const { clearState } = changePasswordSlice.actions;
