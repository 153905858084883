import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { CustomTable } from '../../../../components';
import { useNavigate } from 'react-router-dom';

const StudyDataComponent = ({ data, dataTotal, isLoadingData, studyInstanceUID }) => {
  const navigate = useNavigate();

  const columns = [
    { key: 'patientName', title: 'Patient Name' },
    { key: 'mrn', title: 'MRN' },
    { key: 'studyDateTime', title: 'Study Date' },
    { key: 'description', title: 'Description' },
    { key: 'modalities', title: 'Modality' },
    { key: 'accession', title: 'Accession' },
    { key: 'instances', title: 'Instances' },
  ];

  const actions = [
    {
      label: 'View Study',
      iconName: 'eye-open',
      onClick: () => {
        console.log('first');
        navigate(`/viewer?StudyInstanceUIDs=${studyInstanceUID}`);
      },
    },
  ];

  const [tableData, setTableData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (dataTotal > 0) {
      const studyDetails = data
        ?.filter(item => item.studyInstanceUid === studyInstanceUID)
        ?.map(item => {
          const studyDateTime = `${
            item?.date && moment(item?.date, ['YYYYMMDD', 'YYYY.MM.DD'], true).isValid()
              ? moment(item.date, ['YYYYMMDD', 'YYYY.MM.DD']).format(
                  t('Common:localDateFormat', 'MMM-DD-YYYY')
                )
              : null
          }  ${
            item?.time &&
            moment(item?.time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).isValid() &&
            moment(item?.time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).format(
              t('Common:localTimeFormat', 'hh:mm A')
            )
          }`;
          return {
            ...item,
            studyDateTime,
          };
        });

      setTableData(studyDetails);
    }
  }, [data, dataTotal, isLoadingData, studyInstanceUID, t]);

  return (
    <div className="tableWrap">
      <CustomTable
        columns={columns}
        tableData={tableData}
        actions={actions}
      />
    </div>
  );
};

export default StudyDataComponent;
