import { createSlice } from '@reduxjs/toolkit';
import { editUserApi } from '../../../rtk-apis/users/editUser';

const initialState = {
  status: '',
  result: '',
  msg: '',
  loading: false,
};

const editUserSlice = createSlice({
  name: 'editUser',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(editUserApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editUserApi.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
        state.msg = action?.payload?.msg || 'User updated successfully.';
      })
      .addCase(editUserApi.rejected, (state, action: any) => {
        state.loading = false;
        state.status = 'FAILED';
        state.result = action?.payload;
      });
  },
});

export default editUserSlice.reducer;
export const { clearState } = editUserSlice.actions;
