import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ReportsBarChartComponent = ({ reportsData }) => {

  const getStatusColor = (status) => {
    switch (status) {
      case 'Awaiting Review': return '#0c8597';
      case 'Unreported': return '#F44336';
      case 'Finalized': return '#4CAF50';
      case 'Pending': return '#FFC107';
      default: return '#9E9E9E';
    }
  };

  const chartData = Object.entries(reportsData).map(([status, count]) => ({
    name: status,
    y: count,
    color: getStatusColor(status),
  }));

  const options = {
    chart: {
      type: 'column',
      backgroundColor: null,
    },
    title: {
      text: '',
    },
    plotOptions: {
      column: {
        pointWidth: 50,
        borderWidth: 0
      }
    },
    xAxis: {
      categories: Object.keys(reportsData),
      title: {
        text: 'Report Status',
        style: {
          color: 'white'
        }
      },
      labels: {
        style: {
          color: 'white'
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Number of Reports',
        style: {
          color: 'white'
        }
      },
      labels: {
        style: {
          color: 'white'
        }
      },
      tickInterval: 1,
      allowDecimals: false
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{point.y} reports',
    },
    series: [
      {
        name: 'Reports',
        data: chartData,
      },
    ],
    credits: {
      enabled: false,
    }
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ReportsBarChartComponent;
