import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLINICS } from '../../constant';

interface UploadClinicLogoPayload {
  formData: FormData;
}

export const uploadClinicLogoApi = createAsyncThunk(
  'uploadClinicLogoApi',
  async ({ formData }: UploadClinicLogoPayload, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getToken(),
        },
        body: formData,
      };

      const url = `${CLINICS.UPLOAD_CLINIC_LOGO}`;
      const res = await fetch(url, requestOptions);

      if (!res.ok) {
        const errorData = await res.json();
        return rejectWithValue(errorData.msg || 'Failed to upload clinic logo');
      }

      const data = await res.json();
      return data;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Upload failed');
    }
  }
);
