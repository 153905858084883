import { ICONS } from '@ohif/ui';

export const customStyles = {
  loginBackground: {
    backgroundImage: `url(${ICONS.loginBg})`,
  },
  layoutBackground: {
    backgroundImage: `url(${ICONS.layoutBg})`,
  },
};

export const maskEmail = (email: string) => {
  if (!email) {
    return email;
  }
  const emailArr = email.split('@');
  if (emailArr.length < 2) {
    return email;
  }
  return `${emailArr[0].slice(0, 2)}****@${emailArr[1]}`;
};

export const getInitials = (name?: string): string => {
  if (!name || typeof name !== 'string') {
    return '';
  }

  const nameArr = name.trim().split(' ');

  if (nameArr.length === 0 || !nameArr[0]) {
    return '';
  }

  if (nameArr.length < 2) {
    return nameArr[0][0]?.toUpperCase() || '';
  }

  return `${nameArr[0][0]?.toUpperCase() || ''}${nameArr[1][0]?.toUpperCase() || ''}`;
};

export function capitalizeFirstLetterOfEachWord(input) {
  return input
    ?.split(' ')
    ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    ?.join(' ');
}

export const formatDateToDDMMYYYY = dateStr => {
  // Regular expression to check if the date is already in dd/mm/yyyy format
  const ddmmyyyyRegex = /^\d{2}\/\d{2}\/\d{4}$/;

  // If the date string matches the format, return it as is
  if (ddmmyyyyRegex.test(dateStr)) {
    return dateStr;
  }

  const date = new Date(dateStr);

  // Format date as dd/mm/yyyy using toLocaleDateString
  const formattedDate = date.toLocaleDateString('en-GB'); // e.g., "03/11/2024"

  return formattedDate;
};

export const formatTimeInAMPM = timeStr => {
  const [hour, minute] = timeStr?.split(':');

  // Convert 24-hour time to 12-hour format with AM/PM
  const hourInt = parseInt(hour, 10);
  const period = hourInt >= 12 ? 'PM' : 'AM';
  const formattedHour = hourInt % 12 || 12;

  const formattedTime = `${formattedHour}:${minute} ${period}`; // Outputs "3:00 PM"
  return `${formattedTime}`;
};

export async function convertBlobToBase64(blobUrl) {
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export const calculateAge = birthdate => {
  const year = parseInt(birthdate.substring(0, 4), 10);
  const month = parseInt(birthdate.substring(4, 6), 10) - 1; // Months are 0-based in JS
  const day = parseInt(birthdate.substring(6, 8), 10);

  // Create a Date object for the birthdate
  const birthDate = new Date(year, month, day);

  // Get the current date
  const currentDate = new Date();

  // Calculate the age
  let age = currentDate.getFullYear() - birthDate.getFullYear();

  // Adjust if the birthdate hasn't occurred yet this year
  const isBirthdayPassed =
    currentDate.getMonth() > birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() >= birthDate.getDate());

  if (!isBirthdayPassed) {
    age--;
  }

  console.log(age);

  return age;
};

export const sideMenuBasedOnRole = (role: string) => {
  if (role === 'SUPER_ADMIN' || role === 'System Admin') {
    return [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: 'home-icon',
      },
      {
        title: 'Manage Clinics',
        path: '/clinics',
        icon: 'manage-clinics-icon',
      },
      {
        title: 'Manage Users',
        path: '/users',
        icon: 'manage-users-icon',
      },
      {
        title: 'Profile',
        path: '/myProfile',
        icon: 'profile-icon',
      },
      {
        title: 'Change Password',
        path: '/changePassword',
        icon: 'sidebar-lock-icon',
      },
    ];
  } else if (role === 'Admin') {
    return [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: 'home-icon',
      },
      {
        title: 'Manage Users',
        path: '/users',
        icon: 'manage-users-icon',
      },
      // {
      //   title: 'Report Template',
      //   path: '/reportTemplate',
      //   icon: 'create-template-icon',
      // },
      {
        title: 'Reports',
        path: '/reports',
        icon: 'clinic-admin-reports-icon',
      },
      {
        title: 'Audit Logs',
        path: '/auditLogs',
        icon: 'auditlogs-icon',
      },
      {
        title: 'Clinic Profile',
        path: '/clinicProfile',
        icon: 'clinic-admin-reports-icon',
      },
      {
        title: 'My Profile',
        path: '/myProfile',
        icon: 'profile-icon',
      },
      {
        title: 'Change Password',
        path: '/changePassword',
        icon: 'sidebar-lock-icon',
      },
    ];
  } else if (role === 'Technician') {
    return [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: 'home-icon',
      },
      {
        title: 'Studies',
        path: '/',
        icon: 'studies-icon',
      },
      {
        title: 'Reports',
        path: '/reports',
        icon: 'profile-icon',
      },
      {
        title: 'Templates',
        path: '/templates',
        icon: 'studies-icon',
      },
      {
        title: 'My Profile',
        path: '/myProfile',
        icon: 'profile-icon',
      },
      {
        title: 'Change Password',
        path: '/changePassword',
        icon: 'sidebar-lock-icon',
      },
    ];
  } else if (role === 'Radiologist' || role === 'Cardiologist' || role === 'Clinician') {
    return [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: 'home-icon',
      },
      {
        title: 'Reports',
        path: '/reports',
        icon: 'profile-icon',
      },
      {
        title: 'Template',
        path: '/templates',
        icon: 'create-template-icon',
      },
      {
        title: 'My Profile',
        path: '/myProfile',
        icon: 'profile-icon',
      },
      {
        title: 'Change Password',
        path: '/changePassword',
        icon: 'sidebar-lock-icon',
      },
    ];
  }
};

export const SideMenuFooterItems = [
  {
    title: 'Settings',
    path: '/settings',
    icon: 'settings-icon',
    subMenu: [
      { routeName: 'Report Template', redirectTo: '/reportTemplate', icon: 'create-template-icon' },
      { routeName: 'PACS', redirectTo: '/pacs' },
    ],
  },
  {
    title: 'Logout Account',
    path: '/login',
    icon: 'logout-icon',
  },
];
