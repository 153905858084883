export const AUTH_TOKEN_KEY = 'token';
// export const baseURL = 'http://localhost:3003';
export const baseURL = 'http://dicom-eco-backend.trythis.online';
// export const baseURL = 'https://07a8-106-219-164-186.ngrok-free.app';
export const frontendBaseURL = 'http://localhost:3000/';
export const BASE_URL = 'http://localhost:5985';
// export const PACS_URL = 'https://6vgc6mbq-8081.inc1.devtunnels.ms';
// export const PACS_URL = 'http://localhost:8081';
export const PACS_URL = 'http://3.24.18.37:8081';

export const AUTH = {
  LOGIN_URL: `${baseURL}/auth/login`,
  CHANGE_PASSWORD_URL: `${baseURL}/auth/changePassword`,
  FORGOT_PASSWORD_URL: `${baseURL}/auth/forgot-password`,
  RESET_PASSWORD_URL: `${baseURL}/auth/resetPassword`,
  OTP_VERIFICATION_URL: `${baseURL}/auth/getOTPfromDB`,
};

export const CLINICS = {
  ADD_CLINIC_URL: `${baseURL}/clinic/addClinic`,
  UPDATE_CLINIC_URL: `${baseURL}/clinic/editClinic`,
  GET_CLINIC_URL: `${baseURL}/clinic/getClinic`,
  DELETE_CLINIC_URL: `${baseURL}/clinic/deleteClinic`,
  HOSPITAL_TEMPLATE_URL: `${baseURL}/clinic/hospitaltemplate`,
  GET_HOSPITAL_TEMPLATE_URL: `${baseURL}/clinic/hospitalDetail`,
  UPLOAD_CLINIC_LOGO: `${baseURL}/report/uploadPhoto`,
};

export const USERS = {
  ADD_USER_URL: `${baseURL}/user/addUser`,
  GET_USER_URL: `${baseURL}/user/getUser`,
  UPDATE_USER_URL: `${baseURL}/user/updateUser`,
  DELETE_USER_URL: `${baseURL}/user/deleteUser`,
};

export const PROFILE = {
  GET_PROFILE_DETAILS: `${baseURL}/user/getProfile`,
  UPDATE_PROFILE_DETAILS: `${baseURL}/user/updateProfile`,
};

export const COMMON_DATA = {
  GET_COMMON_DATA: `${baseURL}/common`,
};

export const REPORTS = {
  GET_HOPSPITAL_DOCTORS_LIST: `${baseURL}/user/fetchHospitalDoctors`,
  GET_REPORTS_LIST: `${baseURL}/report/getReports`,
  ASSIGN_REPORT: `${baseURL}/report/assignReport`,
  FETCH_DOCTORS_TO_ASSIGN: `${baseURL}/report/fetchDoctorsToAssign`,

  CREATE_REPORT_TEMPLATE_URL: `${baseURL}/report/createReportTemplate`,
  GET_REPORT_TEMPLATE_URL: `${baseURL}/report/getReportTemplates`,
  UPDATE_REPORT_TEMPLATE_URL: `${baseURL}/report/updateReportTemplate`,
  DELETE_REPORT_TEMPLATE_URL: `${baseURL}/report/deleteReportTemplate`,

  CREATE_REPORT_URL: `${baseURL}/report/createReport`,
  GET_REPORT_URL: `${baseURL}/report/getReport`,
  UPDATE_REPORT_URL: `${baseURL}/report/updateReport`,
  DELETE_REPORT_URL: `${baseURL}/report/deleteReport`,

  GET_CURRENT_REPORT_URL: `${baseURL}/report/getCurrentReport`,
  CHANGE_REPORT_STATUS: `${baseURL}/report/changeReportStatus`,
  UPDATE_REPORT: `${baseURL}/report/updateReportTemplateHtml`,
  GET_REPORT_DATA_BY_ID: `${baseURL}/report/getReportById`,
};

export const DASHBOARD = {
  GET_DASHBOARD_DATA: `${baseURL}/dashboard/graphData`,
};

export const _pacsUrls = {
  GET_PACS: `${baseURL}/pacs/fetchPacs`,
  CREATE_PACS: `${baseURL}/pacs/create`,
  EDIT_PACS: `${baseURL}/pacs/edit`,
  DELETE_PACS: `${baseURL}/pacs/delete`,
};
