import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLINICS } from '../../constant';

export const getHospitalTemplateApi = createAsyncThunk(
  'hospitalTemplate/get',
  async (_, { rejectWithValue }) => {
    try {
      const hospitalId = sessionStorage.getItem('hospitalId');
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken(),
        },
      };

      const url = `${CLINICS.GET_HOSPITAL_TEMPLATE_URL}?hospitalId=${hospitalId}`;
      const res = await fetch(url, requestOptions);

      if (!res.ok) {
        const errorData = await res.json();
        return rejectWithValue(errorData.msg || 'Failed to fetch hospital template');
      }

      const data = await res.json();
      console.log('data', data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
