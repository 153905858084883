import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { SideMenu, Header, ConfirmationModal, Success, Failed, Loader } from '../../components';
import { customStyles } from '../../Utils/utils';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Icon } from '@ohif/ui';
import { Country, State, City } from 'country-state-city';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDetails } from '../../rtk-apis/profile/getProfileDetails';
import { ClinicProfileValidationSchema } from '../auth/helper/Schema';
import { editClinicApi } from '../../rtk-apis/clinics/editClinic';
import { clearState } from '../../store/slices/clinics/editClinic';

const ClinicProfile = () => {
  const dispatch: AppDispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [clinicData, setClinicData] = useState({});
  const [displayMessage, setDisplayMessage] = useState('');
  const [OpenSuccessModal, setOpenSuccessModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [OpenFailureModal, setOpenFailureModal] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const breadcrumbs = [{ label: 'Clinic Profile', icon: 'sidebar-icon' }];

  // ****************** REDUX STATES **********************************

  const { data, loading } = useSelector((state: RootState) => state.getProfileDetailsResponse);

  const {
    status: updateClinicStatus,
    result: updateClinicResult,
    msg: updateClinicMessage,
    loading: updateClinicLoading,
  } = useSelector((state: RootState) => state.editClinicResponse);

  useEffect(() => {
    if (Object.keys(data)?.length > 0) {
      setClinicData(data);
      setSelectedCountry(data?.Country);
      setSelectedState(data?.State);
      setSelectedCity(data?.City);
    }
  }, [data]);

  const initialValues = {
    name: clinicData?.name ? clinicData?.name : '',
    email: clinicData?.email ? clinicData?.email : '',
    countryCode: clinicData?.countryCode ? clinicData?.countryCode : '',
    contactNumber: clinicData?.contactDetails ? clinicData?.contactDetails : '',
    address: clinicData?.Address ? clinicData?.Address : '',
    zipCode: clinicData?.ZipCode ? clinicData?.ZipCode : '',
    country: clinicData?.Country ? clinicData?.Country : '',
    state: clinicData?.State ? clinicData?.State : '',
    city: clinicData?.City ? clinicData?.City : '',
  };

  // ******************* Use-Effects *************************************************

  useEffect(() => {
    getClinicDetails();
  }, [updateClinicStatus]);

  // Update states when country changes
  useEffect(() => {
    if (selectedCountry) {
      const states = State.getStatesOfCountry(selectedCountry);
      setStateOptions(states);
      setCityOptions([]); // Reset city options when country changes
    }
  }, [selectedCountry]);

  // Update cities when state changes
  useEffect(() => {
    if (selectedState) {
      const cities = City.getCitiesOfState(selectedCountry, selectedState);
      setCityOptions(cities);
    }
  }, [selectedState, selectedCountry]);

  // ******************************************************************************

  const getClinicDetails = () => {
    const payload = {};
    dispatch(getProfileDetails(payload));
  };

  const handleSubmit = () => {
    if (formValues) {
      setOpenConfirmationModal(false);
      const payload = {
        id: clinicData?._id || '',
        name: formValues.name,
        email: formValues.email.toLowerCase(),
        countryCode: formValues.countryCode,
        contactDetails: formValues.contactNumber,
        Address: formValues.address,
        ZipCode: formValues.zipCode,
        Country: formValues.country,
        State: formValues.state,
        City: formValues.city,
      };
      dispatch(editClinicApi(payload));
    }
  };

  useEffect(() => {
    const handleSuccess = message => {
      setDisplayMessage(message);
      setOpenSuccessModal(true);
      setTimeout(() => {
        setOpenSuccessModal(false);
        setDisplayMessage('');
      }, 1500);
    };

    const handleFailure = message => {
      setDisplayMessage(message);
      setOpenFailureModal(true);
      setTimeout(() => {
        setOpenFailureModal(false);
        setDisplayMessage('');
      }, 1500);
    };

    if (updateClinicStatus === 'SUCCESS') {
      handleSuccess(updateClinicMessage);
    } else if (updateClinicStatus === 'FAILED') {
      handleFailure(updateClinicMessage);
    }

    return () => {
      dispatch(clearState());
    };
  }, [updateClinicMessage, updateClinicStatus]);

  return (
    <>
      <section
        className="layoutContainer"
        style={customStyles.layoutBackground}
      >
        <SideMenu />
        <div className="layoutGrid">
          <div className="page">
            <Header breadcrumbs={breadcrumbs} />
            <div className="page__body">
              <Formik
                initialValues={initialValues}
                validationSchema={ClinicProfileValidationSchema}
                onSubmit={values => {
                  setFormValues(values);
                  setOpenConfirmationModal(true);
                }}
                enableReinitialize
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <>
                    <Form className="form">
                      <div className="page__body__head">
                        <h1 className="title title--h6 title--normal">Update Clinic Information</h1>
                        <div className="btn__group">
                          <button
                            className="btn btn__white btn__outlined"
                            type="button"
                            onClick={() => { }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn__primary"
                            type="submit"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                      <section className="form__section">
                        <h1 className="form__section__title">Clinic Profile Details</h1>

                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="name"
                                className="form__label"
                              >
                                Name <sup className="form__label__asterisk">*</sup>
                              </label>
                              <Field
                                type="text"
                                id="name"
                                name="name"
                                className="form__input form__input--sm"
                                placeholder="Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>

                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="emailAddress"
                                className="form__label"
                              >
                                Email Address <sup className="form__label__asterisk">*</sup>
                              </label>
                              <Field
                                type="text"
                                id="email"
                                name="email"
                                className="form__input form__input--sm"
                                placeholder="Email Address"
                                autoComplete="newEmail"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="address"
                                className="form__label"
                              >
                                Address <sup className="form__label__asterisk">*</sup>
                              </label>
                              <Field
                                type="address"
                                id="address"
                                name="address"
                                className="form__input form__input--sm"
                                placeholder="Address"
                                autoComplete="address"
                              />
                              <ErrorMessage
                                name="clinicName"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                          <div className='formCol'>
                            <div className='formRow formRow__4'>
                              <div className="formCol">
                                <div className="form__group">
                                  <label
                                    htmlFor="zipCode"
                                    className="form__label"
                                  >
                                    Zip Code <sup className="form__label__asterisk">*</sup>
                                  </label>
                                  <Field
                                    type="text"
                                    id="zipCode"
                                    name="zipCode"
                                    className="form__input form__input--sm"
                                    placeholder="Zip Code"
                                  />
                                  <ErrorMessage
                                    name="zipCode"
                                    component="div"
                                    className="form__error"
                                  />
                                </div>
                              </div>

                              <div className="formCol">
                                <div className="form__group">
                                  <label
                                    htmlFor="country"
                                    className="form__label"
                                  >
                                    Country <sup className="form__label__asterisk">*</sup>
                                  </label>
                                  <Field
                                    as="select"
                                    id="country"
                                    name="country"
                                    value={selectedCountry}
                                    onChange={e => {
                                      setSelectedCountry(e.target.value);
                                      setFieldValue('country', e.target.value);
                                      setFieldValue('state', '');
                                      setFieldValue('city', '');
                                      setSelectedState('');
                                      setSelectedCity('');
                                    }}
                                    className="form__select form__select--sm"
                                  >
                                    <option value="">-Select-</option>
                                    {Country.getAllCountries().map(country => (
                                      <option
                                        key={country.isoCode}
                                        value={country.isoCode}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name="country"
                                    component="div"
                                    className="form__error"
                                  />
                                </div>
                              </div>

                              <div className="formCol">
                                <div className="form__group">
                                  <label
                                    htmlFor="State"
                                    className="form__label"
                                  >
                                    State <sup className="form__label__asterisk">*</sup>
                                  </label>
                                  <Field
                                    as="select"
                                    id="state"
                                    name="state"
                                    value={selectedState}
                                    onChange={e => {
                                      setSelectedState(e.target.value);
                                      setFieldValue('state', e.target.value);
                                      setFieldValue('city', '');
                                      setSelectedCity('');
                                    }}
                                    className="form__select form__select--sm"
                                    disabled={!selectedCountry}
                                  >
                                    <option value="">-Select-</option>
                                    {stateOptions.map(state => (
                                      <option
                                        key={state.isoCode}
                                        value={state.isoCode}
                                      >
                                        {state.name}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name="city"
                                    component="div"
                                    className="form__error"
                                  />
                                </div>
                              </div>

                              <div className="formCol">
                                <div className="form__group">
                                  <label
                                    htmlFor="city"
                                    className="form__label"
                                  >
                                    City <sup className="form__label__asterisk">*</sup>
                                  </label>
                                  <Field
                                    as="select"
                                    id="city"
                                    name="city"
                                    value={selectedCity}
                                    onChange={e => {
                                      setSelectedCity(e.target.value);
                                      setFieldValue('city', e.target.value);
                                    }}
                                    className="form__select form__select--sm"
                                    disabled={!selectedState}
                                  >
                                    <option value="">-Select-</option>
                                    {cityOptions.map(city => (
                                      <option
                                        key={city.name}
                                        value={city.name}
                                      >
                                        {city.name}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name="state"
                                    component="div"
                                    className="form__error"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="contactNumber"
                                className="form__label"
                              >
                                Contact Number <sup className="form__label__asterisk">*</sup>
                              </label>
                              <div className="form__groupField form__groupField--phonenumber">
                                <Field
                                  as="select"
                                  name="contactNumber"
                                  className="form__select form__select--sm"
                                  id="contactNumber"
                                  defaultValue="+91"
                                >
                                  <option value="1">+91</option>
                                  <option value="2">+61</option>
                                </Field>
                                <Field
                                  type="tel"
                                  name="contactNumber"
                                  className="form__input form__input--sm"
                                  placeholder="Contact Number"
                                  maxLength={10}
                                  onInput={e => (e.target.value = e.target.value.replace(/\D/, ''))}
                                />
                              </div>
                              <ErrorMessage
                                name="contactNumber"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
      <Loader isLoading={loading || updateClinicLoading} />
      <ConfirmationModal
        title="Update clinic profile!"
        description="Are you sure you want to update the clinic profile?"
        isConfirmationModalOpen={openConfirmationModal}
        handleCloseModal={() => {
          setOpenConfirmationModal(false);
        }}
        handleConfirm={handleSubmit}
        cancelActionText="No, Cancel"
        confirmActionText="Yes, Confirm"
      />
      <Success
        isSuccessModalOpen={OpenSuccessModal}
        handleCloseModal={() => {
          setOpenSuccessModal(false);
        }}
        title={displayMessage}
      />
      <Failed
        isFailedModalOpen={OpenFailureModal}
        handleCloseModal={() => {
          setOpenFailureModal(false);
        }}
        title={displayMessage}
      />
    </>
  );
};

export default ClinicProfile;
