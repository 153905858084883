import React from 'react';
import '../styled/pages/dashboard.scss';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  itemsPerPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
  onItemsPerPageChange: (itemsPerPage: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  itemsPerPage,
  totalItems,
  onPageChange,
  onItemsPerPageChange,
}) => {
  const handlePrevPage = () => {
    if (currentPage > 1) onPageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) onPageChange(currentPage + 1);
  };

  return (
    <div className="pagination">
      <div className="pagination__result">
        {itemsPerPage * (currentPage - 1) + 1}-{Math.min(itemsPerPage * currentPage, totalItems)} of{' '}
        {totalItems} items
      </div>
      <nav aria-label="pagination__navigation">
        <ul className="pagination__navigation__list">
          {/* Go to first page */}
          <li className="pagination__navigation__item">
            <button
              className="btnPrev"
              type="button"
              aria-label="Go to first page"
              onClick={() => onPageChange(1)}
              disabled={currentPage === 1}
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
              >
                <path
                  d="M7.83333 11.3332L4.5 7.99984L7.83333 4.6665M12.5 11.3332L9.16667 7.99984L12.5 4.6665"
                  stroke="#1EA9EA"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              First
            </button>
          </li>

          {/* Go to previous page */}
          <li className="pagination__navigation__item">
            <button
              className="btnPrev"
              type="button"
              aria-label="Go to previous page"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
              >
                <path
                  d="M10.5 12L6.5 8L10.5 4"
                  stroke="#1EA9EA"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Prev
            </button>
          </li>

          {/* Page numbers */}
          {Array.from({ length: totalPages }).map((_, index) => (
            <li
              className="pagination__navigation__item"
              key={index}
            >
              <button
                className={`btn ${currentPage === index + 1 ? 'btn--active' : ''}`}
                type="button"
                onClick={() => onPageChange(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}

          {/* Go to next page */}
          <li className="pagination__navigation__item">
            <button
              className="btnPrev"
              type="button"
              aria-label="Go to next page"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
              >
                <path
                  d="M6.5 12L10.5 8L6.5 4"
                  stroke="#1EA9EA"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </li>

          {/* Go to last page */}
          <li className="pagination__navigation__item">
            <button
              className="btnPrev"
              type="button"
              aria-label="Go to last page"
              onClick={() => onPageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              Last
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8.66667 11.3332L12 7.99984L8.66667 4.6665M4 11.3332L7.33333 7.99984L4 4.6665"
                  stroke="#1EA9EA"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </li>
        </ul>
      </nav>

      {/* Page selector */}
      <div className="pagination__actions">
        <select
          className="formField__select"
          value={currentPage}
          onChange={e => onPageChange(Number(e.target.value))}
        >
          {Array.from({ length: totalPages }).map((_, index) => (
            <option
              key={index}
              value={index + 1}
            >
              {index + 1}
            </option>
          ))}
        </select>
        <h6>Go to page</h6>
      </div>

      {/* <div className="pagination__itemsPerPage">
        <select
          className="formField__select"
          value={itemsPerPage}
          onChange={e => onItemsPerPageChange(Number(e.target.value))}
        >
          {[10, 25, 50, 100].map(size => (
            <option
              key={size}
              value={size}
            >
              {size} items per page
            </option>
          ))}
        </select>
      </div> */}
    </div>
  );
};

export default Pagination;
