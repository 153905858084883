import React from 'react';
import '../styled/pages/login.scss';
import { Icon } from '@ohif/ui';

const Failed = ({ handleCloseModal, title, isFailedModalOpen }) => {
  setTimeout(() => {
    handleCloseModal(); // Call your function to close the modal
  }, 1500);
  if (!isFailedModalOpen) return null;
  return (
    <div className="modal modalSuccessfull modal--open">
      <div className="modal__dialog">
        <div className="modal__content">
          <div className="modal__header">
            <div className="modal__header--logo">
              <Icon name="failed-icon" />
            </div>
          </div>
          <div className="modal__body text-center">
            <h4 className="title title--h2 title--semibold">{title}</h4>
          </div>
        </div>
      </div>
      <div className="modal__backdrop"></div>
    </div>
  );
};

export default Failed;
