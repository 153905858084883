import { createSlice } from '@reduxjs/toolkit';
import { addPacs } from '../../../rtk-apis/pacs/addPacs';

const initialState = {
  status: '',
  result: '',
  msg: '',
  loading: false,
};

const addPacsSlice = createSlice({
  name: 'addPacs',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(addPacs.pending, (state, action) => {
        state.status = 'LOADING';
        state.loading = true;
        state.msg = 'LOADING...';
      })
      .addCase(addPacs.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
        state.msg = 'Pacs added successfully.';
      })
      .addCase(addPacs.rejected, (state, action) => {
        state.loading = false;
        state.status = 'ERROR';
        state.result = '';
        state.msg = action?.payload || 'Something went wrong!';
      });
  },
});

export default addPacsSlice.reducer;
export const { clearState } = addPacsSlice.actions;
