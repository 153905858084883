import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { USERS } from '../../constant';

interface Payload {
  id: string;
  name?: string;
  role?: string;
  email?: string;
  clinicName?: string;
  countryCode?: string;
  contactDetails?: string;
}

export const editUserApi = createAsyncThunk(
  'editUser',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await fetch(USERS.UPDATE_USER_URL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.msg || 'Add Clinic failed');
      }

      const result = await response.json();

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
