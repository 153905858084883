import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLINICS } from '../../constant';

interface Payload {
  search: any;
  limit: any;
  page: any;
  id?: string;
  // Add other search parameters as optional fields if needed
}

export const getClinicApi = createAsyncThunk(
  'Get Clinic',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      // Create URLSearchParams from the payload
      const params = new URLSearchParams(payload as Record<string, string>);

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken(),
        },
      };
      let res = await fetch(
        `${CLINICS.GET_CLINIC_URL}?page=${payload?.page}&limit=${payload?.limit}&search=${payload?.search}`,
        requestOptions
      );

      // Check if the response is not okay (status is 400 or other errors)
      if (!res.ok) {
        const errorData = await res.json(); // Extract error message from the res
        return rejectWithValue(errorData.msg || 'Failed to get clinics');
      }

      const data = await res.json();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
