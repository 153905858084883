import React from 'react';

import DataSourceWrapper from '../../../../../app/src/routes/DataSourceWrapper';
import StudyDataComponent from './StudyDataComponent';

import { servicesManager } from '../../../../../app/src/App';

const PatientReportImages = ({ ReportData }) => {
  return (
    <>
      <DataSourceWrapper servicesManager={servicesManager}>
        {({ data, isLoadingData, onRefresh, dataTotal }) => (
          <StudyDataComponent
            data={data}
            isLoadingData={isLoadingData}
            dataTotal={dataTotal}
            studyInstanceUID={ReportData?.data?.studyInstanceUID}
          />
        )}
      </DataSourceWrapper>
    </>
  );
};

export default PatientReportImages;
