import { createSlice } from '@reduxjs/toolkit';
import { getProfileDetails } from '../../../rtk-apis/profile/getProfileDetails';

const initialState = {
  status: '',
  data: '',
  msg: '',
  loading: false,
};

const getProfileDetailsSlice = createSlice({
  name: 'getProfileDetailsSlice',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.data = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProfileDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProfileDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.data = action?.payload?.data;
        state.msg = action?.payload?.msg;
      })
      .addCase(getProfileDetails.rejected, (state, action) => {
        state.loading = false;
        state.status = 'FAILED';
        state.data = (action?.payload as string) || 'Something went wrong';
      });
  },
});

export const { clearState } = getProfileDetailsSlice.actions;
export default getProfileDetailsSlice.reducer;
