import { Icon } from '@ohif/ui';
import { capitalizeFirstLetterOfEachWord } from '../../../../Utils/utils';
import React, { useState } from 'react';

const PatientDistribution = ({ patientName, reportType, patientDob, reportDate, reportTime }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <div className="tabsContent__content tabsContent__content--selected">
        <div className="measurementsDetail measurementsDetail--h100">
          <div className="addDistributionBox">
            <button
              className="btn border_0 p_0"
              onClick={handleOpenModal}
            >
              + Add Distribution
            </button>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal modal--addDistribution modal--open">
          <div className="modal__dialog">
            <div className="modal__content modal__content--lg">
              <div className="modal__header">
                <h1 className="modal__header--title">Add a Distribution</h1>
                <p className="modal__header--para">
                  Please complete the following details to proceed
                </p>
                <button
                  className="btn btn__transparent btnClose"
                  onClick={handleCloseModal}
                >
                  <Icon name="close" />
                </button>
              </div>
              <div className="modal__body modal-body-left">
                <div className="distributionDetial">
                  <div className="distributionDetial__row">
                    <h3 className="title title--h4 title--semibold">Reports Details</h3>
                    <ul className="list">
                      <li className="list__item">
                        <strong>Patient Name :</strong>
                        {capitalizeFirstLetterOfEachWord(patientName)} Information
                      </li>
                      <li className="list__item">
                        <strong>{capitalizeFirstLetterOfEachWord(patientName)}:</strong>
                        {reportType ? (
                          <button className="btn btn__success">{reportType}</button>
                        ) : (
                          <></>
                        )}
                      </li>
                      <li className="list__item">
                        <strong>Date of Birth :</strong>
                        {patientDob}
                      </li>
                      <li className="list__item">
                        <strong>Date :</strong>
                        {reportDate}, {reportTime}
                      </li>
                    </ul>
                  </div>
                  <div className="distributionDetial__row">
                    <h3 className="title title--h4 title--semibold">Distribution Details</h3>
                    <div className="formRow">
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="distributionType"
                            className="form__label"
                          >
                            Distribution Type
                          </label>
                          <select
                            id="distributionType"
                            className="form__select form__select--sm"
                          >
                            <option
                              value="1"
                              defaultValue=""
                            >
                              -Select-
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="formRow">
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="fromProvider"
                            className="form__label"
                          >
                            From Provider
                          </label>
                          <select
                            id="fromProvider"
                            className="form__select form__select--sm"
                          >
                            <option
                              value="1"
                              defaultValue=""
                            >
                              -Select-
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="formRow">
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="toProvider"
                            className="form__label"
                          >
                            To Provider
                          </label>
                          <select
                            id="toProvider"
                            className="form__select form__select--sm"
                          >
                            <option
                              value="1"
                              defaultValue=""
                            >
                              -Select-
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="distributionDetial__row">
                    <h3 className="title title--h4 title--semibold">Distribution Trigger</h3>
                    <div className="formRow mb_0">
                      <div className="formCol">
                        <div className="form__group">
                          <div className="form__radio">
                            <div className="form__radio__option">
                              <input
                                type="radio"
                                id="SendtoFinalize"
                                className="form__radio__input"
                              />
                              <label
                                className="form__radio__label"
                                htmlFor="SendtoFinalize"
                              >
                                <strong>Send to Finalize</strong>
                                Send the distribution when the report is finalized
                              </label>
                            </div>
                            <div className="form__radio__option selected">
                              <input
                                type="radio"
                                checked
                                id="SendLater"
                                className="form__radio__input"
                              />
                              <label
                                className="form__radio__label"
                                htmlFor="SendLater"
                              >
                                <strong>Send Later</strong>
                                Send the distribution at a later time
                              </label>
                            </div>
                            <div className="form__radio__option">
                              <input
                                type="radio"
                                id="SendNow"
                                className="form__radio__input"
                              />
                              <label
                                className="form__radio__label"
                                htmlFor="SendNow"
                              >
                                <strong>Send Now</strong>
                                Send the distribution immedietly
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal__footer">
                <div className="btn__group">
                  <button
                    className="btn btn__white btn__outlined"
                    onClick={handleCloseModal}
                  >
                    No, cancel
                  </button>
                  <button className="btn btn__primary">Save</button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal__backdrop"></div>
        </div>
      )}
    </div>
  );
};

export default PatientDistribution;
