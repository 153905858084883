import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { REPORTS } from '../../constant';

export const createReportApi = createAsyncThunk(
  REPORTS.CREATE_REPORT_URL,
  async (payload: any, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await fetch(REPORTS.CREATE_REPORT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.msg || 'Create Report failed');
      }

      const result = await response.json();

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
