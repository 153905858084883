import { createAsyncThunk } from '@reduxjs/toolkit';
import { REPORTS } from '../../constant';
import { getToken } from "../../Utils/authUtils"

interface Payload {
  reportType: string;
  reportStatus: string;
  endDate: string;
  startDate: string;
  reportAssignedTo: string;
  limit: number;
  search: string;
  hospitalId: string;
  page: number;
}

export const getHospitalReportsList = createAsyncThunk(
  'getHospitalReportsList',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const token = getToken();
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      };
      let res = await fetch(`${REPORTS?.GET_REPORTS_LIST}?page=${payload?.page}&limit=${payload?.limit}&search=${payload?.search}&hospitalId=${payload?.hospitalId}&reportAssignedTo=${payload?.reportAssignedTo}&startDate=${payload?.startDate}&endDate=${payload?.endDate}&reportStatus=${payload?.reportStatus}&reportType=${payload?.reportType}`, requestOptions);

      // Check if the response is not okay (status is 400 or other errors)
      if (!res.ok) {
        const errorData = await res.json(); // Extract error message from the res
        return rejectWithValue(errorData.message || 'Fetch Reports List Failed!');
      }

      const data = await res.json();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
