import { createSlice } from '@reduxjs/toolkit';
import { forgotPasswordApi } from '../../../rtk-apis/auth/forgotPassword';

const initialState = {
  status: '',
  result: '',
  msg: '',
  loading: false,
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(forgotPasswordApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(forgotPasswordApi.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
        state.msg = action?.payload?.message ?? '';
      })
      .addCase(forgotPasswordApi.rejected, (state, action) => {
        state.loading = false;
        state.status = 'ERROR';
        state.result = '';
        state.msg = action?.error?.message ?? '';
      });
  },
});

export default forgotPasswordSlice.reducer;
export const { clearState } = forgotPasswordSlice.actions;
