import { Icon } from '@ohif/ui';
import React from 'react';
import '../styled/pages/dashboard.scss';

const ReportTemplatePreview = ({ isOpen, closeModal }) => {
  if (!isOpen) return null;
  return (
    <div className="modal modalTrash modal--open">
      <div className="modal__dialog">
        <div className="modal__content">
          <div className="modal__header flex items-center justify-between">
            <div className="">
              <h4 className="title title--h4 title--semibold">Select Report Template</h4>
              <p className="para -mt-5">Select one template from below to continue</p>
            </div>
            <div className="button">
              <button
                type="button"
                className="btn btn__primary"
                aria-label="Continue"
              >
                Continue
              </button>
            </div>
          </div>
          <div className="modal__footer">
            <Icon name="reportPreview" />
          </div>
        </div>
      </div>
      <div
        className="modal__backdrop"
        onClick={closeModal}
      ></div>
    </div>
  );
};

export default ReportTemplatePreview;
