import { createAsyncThunk } from '@reduxjs/toolkit';
import { _pacsUrls } from '../../constant';
import { getToken } from '../../Utils/authUtils';

interface Payload {
  aeTitle: string;
  ipAddress: string;
  port: string;
  hospitalId: string;
}

export const addPacs = createAsyncThunk(
  'addPacs',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
      };
      let res = await fetch(_pacsUrls.CREATE_PACS, requestOptions);

      if (!res.ok) {
        const errorData = await res.json();
        return rejectWithValue(errorData.message);
      }

      const data = await res.json();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
