import * as Yup from 'yup';
import { isValidName, phoneNumberRegex } from '../../auth/helper/regex';

// Initial form values
export const resetPasswordInitialValues = {
  newPassword: '',
  confirmPassword: '',
};

// Reset Password Validation Schema
export const resetPasswordValidationSchema = Yup.object({
  newPassword: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Please confirm your password'),
});

// Initial form values
export const forgotPasswordInitialValues = {
  email: '',
};

//Forgot Password Validation Schema
export const forgotPasswordValidationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

export const loginInitialValues = {
  email: '',
  password: '',
};

// Login Validation Schema
export const loginValidationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

// OTP Schema
export const otpSchema = Yup.object().shape({
  otp: Yup.array()
    .of(
      Yup.string()
        .required('Required')
        .matches(/^[0-9]{1}$/, 'Invalid OTP digit')
    )
    .length(6, 'OTP must be 6 digits'),
});

// Add/Update Clinic Validation Schema
export const clinicFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(isValidName, 'Please enter valid name.')
    .min(1, 'Please enter valid name.')
    .required('Name is required.'),
  email: Yup.string().email('Please enter a valid email address.').required('Email is required.'),
  address: Yup.string().required('Address is required.'),
  zipCode: Yup.string().required('Zip Code is required.'),
  country: Yup.string().required('Country is required.'),
  city: Yup.string().required('City is required.'),
  state: Yup.string().required('State is required.'),
  countryCode: Yup.string(),
  contactNumber: Yup.string()
    .matches(phoneNumberRegex, 'Please enter a valid phone number.')
    .required('Contact number is required.'),
  adminName: Yup.string()
    .trim()
    .matches(isValidName, 'Please enter valid name.')
    .min(1, 'Please enter valid name.')
    .required('Name is required.'),
  adminEmail: Yup.string()
    .email('Please enter a valid email address.')
    .required('Email is required.'),
  adminContactNumber: Yup.string()
    .matches(phoneNumberRegex, 'Please enter a valid conatct number.')
    .required('Contact number is required.'),
  adminCountryCode: Yup.string(),
});

// Report Template Validation Schema
export const reportTemplateValidationSchema = Yup.object().shape({
  hospitalName: Yup.string().required('Hospital name is required.'),
  hospitalLogo: Yup.mixed().required('Profile picture is required.'),
  headerCountryCode: Yup.string().required('Country code is required.'),
  headerContactNumber: Yup.string()
    .matches(phoneNumberRegex, 'Must be only digits.')
    .required('Contact number is required.'),
  headerEmailAddress: Yup.string()
    .email('Invalid email address.')
    .required('Email address is required.'),
  headerAddress: Yup.string().required('Address is required.'),
  headerZipCode: Yup.string().required('Zip code is required.'),
  headerCountry: Yup.string().required('Country is required.'),
  headerState: Yup.string().required('State is required.'),
  headerCity: Yup.string().required('City is required.'),
});

// Add/Update User validation Schema
export const userFormValidationSchema = Yup.object().shape({
  hospitalId: Yup.string(),
  name: Yup.string()
    .trim()
    .matches(isValidName, 'Please enter valid name.')
    .min(1, 'Please enter valid name.')
    .required('Name is required.'),
  role: Yup.string().required('Role is required.'),
  countryCode: Yup.string(),
  email: Yup.string().email('Please enter a valid email address.').required('Email is required.'),
  clinicName: Yup.string().required('Clinic name is required.'),
  contactNumber: Yup.string()
    .matches(phoneNumberRegex, 'Please enter a valid contact number.')
    .required('Contact number is required.'),
});

export const myProfileValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .matches(isValidName, 'Please enter valid name.')
    .min(1, 'Please enter valid name.')
    .required('Name is required.'),
  role: Yup.string().required('Role is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  contactNumber: Yup.string()
    .matches(phoneNumberRegex, 'Please enter a valid contact number.')
    .required('Contact number is required.'),
  countryCode: Yup.string(),
});

export const ClinicProfileValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .matches(isValidName, 'Please enter valid name.')
    .min(1, 'Please enter valid name.')
    .required('Name is required.'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  countryCode: Yup.string().required('Country Code is required'),
  contactNumber: Yup.string()
    .matches(phoneNumberRegex, 'Please enter a valid contact number.')
    .required('Contact number is required.'),
  address: Yup.string(),
  zipCode: Yup.string(),
  country: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
});

export const PacsValidationSchema = Yup.object({
  aeTitle: Yup.string().required('AE Title is required'),
  ipAddress: Yup.string().required('IP Address is required'),
  port: Yup.number().required('Port is required'),
});
