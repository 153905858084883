import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PROFILE } from "../../constant"

type Payload = {
};

export const getProfileDetails = createAsyncThunk(
  'getProfileDetails',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await fetch(`${PROFILE?.GET_PROFILE_DETAILS}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.msg || 'Get User failed');
      }

      const result = await response.json();

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
