import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH } from '../../constant';

interface Payload {
  username: string;
  oldPassword: string;
  newPassword: string;
}

export const changePasswordApi = createAsyncThunk(
  'auth/changePassword',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem('token') || localStorage.getItem('token');
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      };
      let res = await fetch(AUTH.CHANGE_PASSWORD_URL, requestOptions);
      const data = await res.json();
      console.log('YESSSSSSSSS', data);

      if (data?.status === 'ERROR') {
        console.log('NOOOOOOOOOOO', data);
        return rejectWithValue(data?.message || data?.msg);
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error?.message || 'An unexpected error occurred');
    }
  }
);
