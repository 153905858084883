import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import './styles.scss';

import { BubbleMenu, EditorProvider, useCurrentEditor } from '@tiptap/react';
import { getHospitalId } from '../../../../platform/custom/Utils/authUtils';

import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { createReportApi } from '../../rtk-apis/reports/createReport';
import { clearState } from '../../store/slices/reports/createReport';
import { extensions } from '../../Utils/reportExtension';
import { OHIFCornerstoneSRContainer } from '../../../../extensions/cornerstone-dicom-sr/src/components/OHIFCornerstoneSRContainer';

import {
  fetchPatientDetails,
  extractPatientDemographics,
  convertMeasurementsToHTML,
  extractSeriesInstanceUID,
  extractContentItemData,
  getSeriesMetadata,
} from './helper';
import { Button, Icon } from '@ohif/ui';
import { formatDateToDDMMYYYY } from '../../../custom/Utils/utils';

import { RestBullsEyePlot } from './components/RestBullsEyePlot';
import { StressBullsEyePlot } from './components/StressBullsEyePlot';
import { Apical2Ch } from './components/Apical2Ch';
import { Apical3Ch } from './components/Apical3Ch';
import { Apical4Ch } from './components/Apical4Ch';

import { StressApical2Ch } from './components/StressApical2Ch';
import { updateReport } from '../../rtk-apis/Reports/updateReport';
import { clearState as clearUpdateState } from '../../store/slices/Reports/updateReport';
import { ConfirmationModal, Failed, Loader, Success } from '../../../custom/components';
import { useNavigate } from 'react-router-dom';

interface ReportLayoutProps {
  closeReportPanel?: any;
  reportTemplate?: any;
  headerDetails?: any;
  ref?: any;
  setIsLoading?: any;
  setIsSuccess?: any;
  setSRInstance?: any;
  SRInstance?: any;
  setUpdatedEditorContent?: any;
}

export const MenuBar = ({ editor }) => {
  return (
    <div className="control-group">
      <BubbleMenu editor={editor}>
        <div className="bubble-menu">
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'is-active' : ''}
          >
            <strong>B</strong>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'is-active' : ''}
          >
            <strong>
              <em>I</em>
            </strong>
          </button>
          <button
            onClick={() => editor.chain().focus().setParagraph().run()}
            className={editor.isActive('paragraph') ? 'is-active' : ''}
          >
            P
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
          >
            <strong>H1</strong>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
          >
            <strong>H2</strong>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
          >
            <strong>H3</strong>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
            className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
          >
            <strong>H4</strong>
          </button>
        </div>
      </BubbleMenu>
    </div>
  );
};

/**
 * ReportLayout component is responsible for rendering the report editor layout.
 * It utilizes the current editor instance to populate a template with data and
 * manages the editor state to reflect the content.
 *
 * Props:
 * - closeReportPanel: A function to close the report panel.
 * - reportTemplate: A template string that is populated with data and set into the editor.
 *
 * This component:
 * - Populates the provided report template with data upon mounting.
 * - Updates the editor content once the template is populated.
 * - Provides a save button to log the current editor content.
 * - Includes a BubbleMenu with formatting options like bold, italic, and headings.
 */
const ReportLayout: React.FC<ReportLayoutProps> = forwardRef((props, ref) => {
  const { editor } = useCurrentEditor();
  const [content, setContent] = useState('');
  const [patientDemographics, setPatientDemographics] = useState({});
  const [measurementsHTML, setMeasurementsHTML] = useState(null);
  const [data, setData] = useState({
    report_status: 'Awaiting Review',
  });
  const [seriesInstanceUID, setSeriesInstanceUID] = useState(null);
  const [SRInstanceData, setSRInstanceData] = useState(null);
  const [htmlString, setHtmlString] = useState('');
  if (!editor) {
    return null;
  }

  const dispatch = useDispatch<AppDispatch>();
  const {
    status,
    data: createReponseData,
    msg,
    loading,
  } = useSelector((state: RootState) => state.createReportResponse);

  const currentUrl = window.location.href;
  const currUrl = new URL(currentUrl);
  const queryParams = new URLSearchParams(currUrl.search);
  const studyInstanceUID = queryParams.get('StudyInstanceUIDs');

  useEffect(() => {
    console.log('seriesInstanceUID', seriesInstanceUID);
    const data = getSeriesMetadata(studyInstanceUID, seriesInstanceUID);
    props.setSRInstance(data?.instances[0]);
  }, [seriesInstanceUID]);

  function extractContainerData(container, nodeIndexesTree = [0], containerNumberedTree = [1]) {
    const { ContinuityOfContent, ConceptNameCodeSequence, ContentSequence } = container;
    const { CodeMeaning } = ConceptNameCodeSequence ?? {};
    let childContainerIndex = 1;

    // Extract Report Name
    if (nodeIndexesTree.length === 1 && containerNumberedTree.length === 1) {
      const ReportName = CodeMeaning;
      setData(prev => {
        return {
          ...prev,
          report_name: ReportName,
        };
      });
    }

    const result = {
      codeMeaning: CodeMeaning,
      containerNumberedTree: containerNumberedTree.join('.'),
      continuityOfContent: ContinuityOfContent,
      contentItems: [],
    };

    if (ContentSequence && Array.isArray(ContentSequence)) {
      result.contentItems = ContentSequence.map((contentItem, i) => {
        const { ValueType } = contentItem;
        const childNodeLevel = [...nodeIndexesTree, i];

        if (ValueType === 'CONTAINER') {
          const childContainerNumberedTree = [...containerNumberedTree, childContainerIndex++];
          return extractContainerData(contentItem, childNodeLevel, childContainerNumberedTree);
        } else {
          return extractContentItemData(contentItem, childNodeLevel, ContinuityOfContent);
        }
      });
    }

    return result;
  }

  useEffect(() => {
    const SRData = props.SRInstance && <OHIFCornerstoneSRContainer container={props.SRInstance} />;
    setSRInstanceData(SRData);
  }, [props.SRInstance]);

  const convertToHtmlString = () => {
    // Convert the React component to an HTML string
    const html = ReactDOMServer.renderToString(SRInstanceData);
    setHtmlString(html);
  };

  useEffect(() => {
    if (SRInstanceData) {
      convertToHtmlString();
    }
  }, [SRInstanceData]);

  useEffect(() => {
    // Function to recursively extract and convert measurement data into HTML
    const handleFetchDemographics = async () => {
      const studyData = await fetchPatientDetails(studyInstanceUID);

      const demographics = extractPatientDemographics(studyData);
      setPatientDemographics(demographics);

      const seriesUID = extractSeriesInstanceUID(studyData);
      setSeriesInstanceUID(seriesUID);
    };
    handleFetchDemographics();
    setMeasurementsHTML(
      props.SRInstance ? convertMeasurementsToHTML(extractContainerData(props.SRInstance)) : null
    );
  }, [htmlString]);

  useEffect(() => {
    setData(prev => ({
      ...prev,
      ...patientDemographics,
      measurements: measurementsHTML,
    }));
  }, [patientDemographics]);

  const populateTemplate = (template, data) => {
    // Use a regular expression to replace all placeholders
    return template?.replace(/\{\{(.*?)\}\}/g, (_, key) => data[key.trim()] || '');
  };

  useEffect(() => {
    // Populate the template with data on component mount

    const typeofTemplate = typeof props?.reportTemplate?.templateHTML;

    const populatedContent =
      typeofTemplate === 'string'
        ? populateTemplate(props?.reportTemplate?.templateHTML, data)
        : props?.reportTemplate?.templateHTML;

    console.log('populatedContent', populatedContent);
    setContent(populatedContent);
    props?.setUpdatedEditorContent(populatedContent);
  }, [props?.reportTemplate?.templateHTML, data]);

  useEffect(() => {
    if (editor && content) {
      // Set content in the editor after it’s populated
      editor.commands.setContent(content);
      props?.setUpdatedEditorContent(content);
    }
    return () => {
      editor?.commands.clearContent();
    };
  }, [editor, content]);

  useEffect(() => {
    if (editor) {
      editor.on('update', () => {
        const updatedContent = editor.getJSON(); // Get content as JSON
        props?.setUpdatedEditorContent(updatedContent);
      });
    }
  }, [editor]);

  useEffect(() => {
    if (status === 'SUCCESS') {
      props.setIsSuccess(true);
    } else {
      // props.setIsSuccess(false);
    }
    props.setIsLoading(false);
    dispatch(clearState());
  }, [status]);

  const handleSave = async () => {
    props.setIsLoading(true);

    const currentUrl = window.location.href;
    const currUrl = new URL(currentUrl);
    const queryParams = new URLSearchParams(currUrl.search);
    const studyInstanceUID = queryParams.get('StudyInstanceUIDs');

    const now = Date.now();
    const currentDate = new Date(now);
    // Get the date in YYYY-MM-DD format
    const date = currentDate.toISOString().split('T')[0];

    // Get the time in HH:MM:SS format
    const time = currentDate.toTimeString().split(' ')[0];

    const hospitalId = getHospitalId();

    const payload = {
      templateHTML: editor.getJSON(),
      editorMode: '',
      reportName: props?.reportTemplate?.templateName,
      date: date,
      time: time,
      studyInstanceUID: studyInstanceUID,
      seriesInstanceUID: seriesInstanceUID,
      status: 'Awaiting Review',
      comment: 'comment',
      hospitalId: hospitalId,
      reportType: props?.reportTemplate?.templateName,
      ...patientDemographics,

      headerDetails: props.headerDetails,
    };

    dispatch(createReportApi(payload));
  };

  useImperativeHandle(ref, () => ({
    triggerChildFunction: handleSave,
  }));

  return <MenuBar editor={editor} />;
});

export default forwardRef((props, ref) => {
  const [width, setWidth] = useState(850); // Initial width
  const [SRInstance, setSRInstance] = useState(null);
  const [data, setData] = useState(props);
  const [updatedEditorContent, setUpdatedEditorContent] = useState({});
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const [displayMessage, setDisplayMessage] = useState('');

  const headerDetails = data?.hospitalHeader;
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const {
    status: updateReportStatus,
    msg: updateReportMsg,
    loading: updateReportLoading,
  } = useSelector((state: RootState) => state.updateReportResponse);

  const handleUpdate = () => {
    setIsConfirmationModalOpen(false);
    const payload = {
      reportId: props?.reportTemplate?._id,
      templateHTML: updatedEditorContent,
    };
    dispatch(updateReport(payload));
  };

  const handleMouseDown = e => {
    const startX = e.clientX;
    const startWidth = width;

    const onMouseMove = e => {
      const newWidth = startWidth - (e.clientX - startX);
      setWidth(newWidth > 300 ? newWidth : 300); // Minimum width to avoid collapsing completely
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  useEffect(() => {
    if (Object.keys(props?.reportTemplate).length > 0) {
      setData(props?.reportTemplate);
    }
  }, [props]);

  const childTwoRef = useRef(null);
  const [currentColor, setCurrentColor] = useState('#b7eb8f'); // Default color

  const changeColor = color => {
    setCurrentColor(color);
    if (childTwoRef.current) {
      childTwoRef.current.handleColorChange(color); // Call the method in RestBullsEyePlot
    }
  };

  useEffect(() => {
    const handleSuccess = message => {
      setDisplayMessage(message);
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        setIsSuccessModalOpen(false);
        navigate('/reports');
      }, 1500);
    };

    const handleFailure = message => {
      setDisplayMessage(message);
      setIsFailedModalOpen(true);
      setTimeout(() => {
        setIsFailedModalOpen(false);
        navigate('/reports');
      }, 1500);
    };
    if (updateReportStatus === 'SUCCESS') {
      handleSuccess(updateReportMsg);
    }

    if (updateReportStatus === 'FAILED') {
      handleFailure(updateReportMsg);
    }

    return () => {
      dispatch(clearUpdateState());
    };
  }, [updateReportStatus]);

  return (
    <div
      className="report-layout border-primary-light relative mt-[2px] box-border overflow-y-scroll rounded border-2 bg-white p-6"
      style={{ width: `${width}px`, maxWidth: '900px', minWidth: '300px' }}
    >
      <div
        className="resize-handle hover:bg-primary absolute top-0 left-0 z-10 h-full"
        onMouseDown={handleMouseDown}
      ></div>
      <div className="mb-4 flex items-center justify-between">
        <div
          className=""
          style={{ height: '124px', width: '124px', overflow: 'content' }}
        >
          <img
            src={data?.hospitalLogo}
            alt="Hospital Logo"
            className="h-full w-full object-contain"
          />
        </div>
        <div className="text-normal text-right text-black">
          <p>{headerDetails?.hospitalName}</p>
          <p>{headerDetails?.address}</p>
          <p>
            {headerDetails?.city}, {headerDetails?.zipCode}
          </p>
          <p>
            <strong>Tel: </strong>({headerDetails?.contactNumberCountryCode}){' '}
            {headerDetails?.contactNumber} <strong>Fax: </strong>
            {headerDetails?.fax}
          </p>
          <p>
            <strong>E: </strong> {headerDetails?.email}
          </p>
          <p>
            <strong>W: </strong>{' '}
            <a
              href={headerDetails?.website}
              className="text-primary"
              target="_blank"
            >
              {headerDetails?.website}
            </a>
          </p>
        </div>
      </div>
      <div>
        <EditorProvider
          editorProps={{
            attributes: { class: 'editor-container' },
          }}
          slotBefore={
            <ReportLayout
              reportTemplate={data?.item}
              headerDetails={headerDetails}
              ref={ref}
              setIsLoading={props?.setIsLoading}
              setIsSuccess={props?.setIsSuccess}
              setSRInstance={setSRInstance}
              SRInstance={SRInstance}
              setUpdatedEditorContent={setUpdatedEditorContent}
            />
          }
          extensions={extensions}
        />
      </div>
      {data?.item?.templateName === 'Stress Echo 2' && (
        <div className="report-diagrams">
          <div className="target">
            <RestBullsEyePlot
              ref={childTwoRef}
              currentColor={currentColor}
            />
            <StressBullsEyePlot />
            <div className="target-code">
              <div className="target-code-container">
                <div className="color-code color-green"></div>
                <div className="color-name">Normal (blank)</div>
              </div>
              <div className="target-code-container">
                <div className="color-code color-sky-blue"></div>
                <div className="color-name">Hyperkinetic (1)</div>
              </div>
              <div className="target-code-container">
                <div className="color-code color-light-yellow"></div>
                <div className="color-name">Mildly hypokinetic (2)</div>
              </div>
              <div className="target-code-container">
                <div className="color-code color-yellow"></div>
                <div className="color-name">Moderately hypokinetic (3)</div>
              </div>
              <div className="target-code-container">
                <div className="color-code color-orange"></div>
                <div className="color-name">Severely hypokinetic (4)</div>
              </div>
              <div className="target-code-container">
                <div className="color-code color-red"></div>
                <div className="color-name">Akinetic (5)</div>
              </div>
              <div className="target-code-container">
                <div className="color-code color-grey"></div>
                <div className="color-name">Dyskinetic (6)</div>
              </div>
              <div className="target-code-container">
                <div className="color-code color-purple"></div>
                <div className="color-name">Thinned and scarred (7)</div>
              </div>
            </div>
          </div>
          <div className="rest-heart-chambers">
            <p className="title title--h5 title--semibold">Rest</p>
            <Apical2Ch changeColor={changeColor} />
            <Apical3Ch />
            <Apical4Ch />
          </div>
          <div className="rest-heart-chambers">
            <p className="title title--h5 title--semibold">Stress</p>
            <Apical3Ch />
            <Apical4Ch />
          </div>
        </div>
      )}

      <div className="btn__group mt-6">
        <button
          className="btn btn__white btn__outlined"
          style={{ border: '1px solid black', color: 'black' }}
          type="button"
          onClick={props?.closeReportPanel}
        >
          Cancel
        </button>
        {props?.reportTemplate?.from ? (
          <button
            className="btn btn__primary"
            type="submit"
            onClick={() => setIsConfirmationModalOpen(true)}
          >
            Update
          </button>
        ) : (
          <button
            className="btn btn__primary"
            type="submit"
            onClick={props?.handleButtonClick}
          >
            Save
          </button>
        )}
      </div>

      <Loader isLoading={updateReportLoading} />

      <Success
        title={displayMessage}
        isSuccessModalOpen={isSuccessModalOpen}
        handleCloseModal={() => setIsSuccessModalOpen(false)}
      />

      <Failed
        title={displayMessage}
        isFailedModalOpen={isFailedModalOpen}
        handleCloseModal={() => setIsFailedModalOpen(false)}
      />

      <ConfirmationModal
        title="Update Report"
        description="Are you sure you want to update this report?"
        isConfirmationModalOpen={isConfirmationModalOpen}
        handleCloseModal={() => setIsConfirmationModalOpen(false)}
        handleConfirm={() => handleUpdate()}
        cancelActionText="Cancel"
        confirmActionText="Update"
      />
    </div>
  );
});
