import React, { useState, useEffect } from 'react';
import { Icon } from '@ohif/ui';
import '../styled/pages/dashboard.scss';
import { getCommonData } from '../rtk-apis/common/getCommonData';
import { AppDispatch, RootState } from '../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getHospitalDoctorsList } from '../rtk-apis/Reports/getHospitalDoctorsList';
import { setEnabledElement } from 'extensions/cornerstone/src';

const ReportFilter = ({ setReportTypeFilterValue, setReportStatusFilterValue, setEndDateFilerValue, setStartDateFilerValue, setReportAssignedToDoc, setFilterApplied, ReportAssignedToDoc,StartDateFilerValue,ReportTypeFilterValue, ReportStatusFilterValue , EndDateFilerValue,setClearFilter}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [OpenFilterModal, setOpenFilterModal] = useState(false);
  const [ReportStatus, setReportStatus] = useState([]);
  const [ReportType, setReportType] = useState([]);
  const [DoctorList, setDoctorList] = useState([]);

  useEffect(() => {
    getCommmonData();
    getDocList();

  }, [OpenFilterModal])

  // ***************** GET DROWNDOWN REPORT TYPE AND REPORT STATUS VALUES FROM COMMON DATA API *******************
  const {
    result: commonData,
  } = useSelector((state: RootState) => state.getCommonDataSliceResponse);

  const getCommmonData = () => {
    const payload = {}
    dispatch(getCommonData(payload))
  }

  useEffect(() => {
    setReportStatus(commonData?.reportStatus)
    setReportType(commonData?.reportType)
  }, [commonData])

  // ************************************* GET ASSIGNED TO DROPDOWN VALUES ************************************************

  const { data: DoctorsList } = useSelector(
    (state: RootState) => state.getHospitalDoctorsListResponse
  );

  const getDocList = () => {
    const payload = {}
    dispatch(getHospitalDoctorsList(payload));
  }

  useEffect(() => {
    if (DoctorsList?.length > 0) {
      setDoctorList(DoctorsList)
    }
  }, [DoctorsList])

  const handleApplyFilter=()=>{
    setFilterApplied(true)
    setOpenFilterModal(false)
  }

  const handleClearFilter=()=>{
    setFilterApplied(false)
    setClearFilter(true)
    setReportAssignedToDoc("")
    setStartDateFilerValue("")
    setEndDateFilerValue("")
    setReportStatusFilterValue("")
    setReportTypeFilterValue("")
  }



  return (
    <>
      <div className={`filter ${OpenFilterModal ? "filter--open" : ""}`}>
        <button className="btn p_0 border_0" onClick={() => setOpenFilterModal(!OpenFilterModal)}>
          <Icon name="filter-icon" />
        </button>
        <div className="filter__dropdown">
          <div className="filter__dropdown__body">
            <h3 className="title">
              <span className="icon">
                <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.375 5C14.375 5.16576 14.3092 5.32473 14.1919 5.44194C14.0747 5.55915 13.9158 5.625 13.75 5.625H3.75C3.58424 5.625 3.42527 5.55915 3.30806 5.44194C3.19085 5.32473 3.125 5.16576 3.125 5C3.125 4.83424 3.19085 4.67527 3.30806 4.55806C3.42527 4.44085 3.58424 4.375 3.75 4.375H13.75C13.9158 4.375 14.0747 4.44085 14.1919 4.55806C14.3092 4.67527 14.375 4.83424 14.375 5ZM16.875 0.625H0.625C0.45924 0.625 0.300268 0.690848 0.183058 0.808058C0.065848 0.925269 0 1.08424 0 1.25C0 1.41576 0.065848 1.57473 0.183058 1.69194C0.300268 1.80915 0.45924 1.875 0.625 1.875H16.875C17.0408 1.875 17.1997 1.80915 17.3169 1.69194C17.4342 1.57473 17.5 1.41576 17.5 1.25C17.5 1.08424 17.4342 0.925269 17.3169 0.808058C17.1997 0.690848 17.0408 0.625 16.875 0.625ZM10.625 8.125H6.875C6.70924 8.125 6.55027 8.19085 6.43306 8.30806C6.31585 8.42527 6.25 8.58424 6.25 8.75C6.25 8.91576 6.31585 9.07473 6.43306 9.19194C6.55027 9.30915 6.70924 9.375 6.875 9.375H10.625C10.7908 9.375 10.9497 9.30915 11.0669 9.19194C11.1842 9.07473 11.25 8.91576 11.25 8.75C11.25 8.58424 11.1842 8.42527 11.0669 8.30806C10.9497 8.19085 10.7908 8.125 10.625 8.125Z" fill="white" />
                </svg>
              </span>
              Filter By
            </h3>
            <div className="formRow">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="assignedTo" className="form__label">Assigned To</label>
                  <select id="assignedTo" className="form__select form__select--sm" onChange={(e) => setReportAssignedToDoc(e?.target?.value)} value={ReportAssignedToDoc}>
                    <option value="" selected >Select</option>
                    {DoctorList?.length > 0 && DoctorList?.map((doctor, index) => {
                      return (
                        <option value={doctor?.name} key={index}>{doctor?.name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="formRow formRow__2">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="startDate" className="form__label">Start Date</label>
                  <input type="date" id="startDate" value={StartDateFilerValue} className="form__date form__date--sm" placeholder="" onChange={(e) => setStartDateFilerValue(e?.target?.value)} />
                </div>
              </div>
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="endDate" className="form__label">End Date</label>
                  <input type="date" id="endDate" value={EndDateFilerValue} className="form__date form__date--sm" placeholder="" onChange={(e) => setEndDateFilerValue(e?.target?.value)} />
                </div>
              </div>
            </div>
            <div className="formRow">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="reportStatus" className="form__label">Report Status</label>
                  <select id="reportStatus" className="form__select form__select--sm" value={ReportStatusFilterValue} onChange={(e) => setReportStatusFilterValue(e?.target?.value)}>
                    <option value="" selected >Select</option>
                    {ReportStatus?.length > 0 && ReportStatus?.map((status, index) => {
                      return (
                        <option value={status}>{status}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="formRow">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="reportType" className="form__label">Report Type</label>
                  <select id="reportType" className="form__select form__select--sm" value={ReportTypeFilterValue} onChange={(e) => setReportTypeFilterValue(e?.target?.value)}>
                    <option value="" selected >Select</option>
                    {ReportType?.length > 0 && ReportType?.map((type, index) => {
                      return (
                        <option value={type}>{type}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="btn__group">
              <button className="btn btn__white btn__outlined" onClick={handleClearFilter}>Clear</button>
              <button className="btn btn__primary" onClick={handleApplyFilter}>Apply</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportFilter;
