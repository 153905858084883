import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLINICS } from '../../constant';

interface Payload {
  id: string;
  name?: string;
  emailAddress?: string;
  countryCode?: string;
  contactNumber?: string;
  Address?: string;
  ZipCode?: string;
  Country?: string;
  State?: string;
  City?: string;
}

export const editClinicApi = createAsyncThunk(
  'Edit Clinic',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
      };
      let res = await fetch(CLINICS.UPDATE_CLINIC_URL, requestOptions);

      // Check if the response is not okay (status is 400 or other errors)
      if (!res.ok) {
        const errorData = await res.json(); // Extract error message from the res
        return rejectWithValue(errorData.msg || 'Edit Clinic failed');
      }

      const data = await res.json();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
