import React, { useEffect, useState } from 'react';
import { formatedLabel } from '../pages/reports/helper';
import { extensions } from '../Utils/reportExtension';
import { BubbleMenu, EditorProvider, useCurrentEditor } from '@tiptap/react';
import '../pages/reports/styles.scss';

import { AppDispatch, RootState } from '../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { updateReport } from '../rtk-apis/Reports/updateReport';
import { Loader, Success, Failed } from '../components';
import { clearState } from '../store/slices/Reports/updateReport';

const ReportLayout = props => {
  const { editor } = useCurrentEditor();
  if (!editor) return null;

  const [measurementTableAbsent, setMeasurementTableAbsent] = useState(false);

  useEffect(() => {
    const loadContent = async () => {
      if (editor && props?.reportTemplate) {
        console.log('Initializing content...');

        try {
          const templateHTML = props?.reportTemplate;
          console.log('templateHTML:', templateHTML);

          // Parse the stored templateHTML
          const parsedContent =
            typeof templateHTML === 'string' ? JSON.parse(templateHTML) : templateHTML;

          if (!parsedContent || typeof parsedContent !== 'object') {
            console.warn('Parsed content is empty or invalid:', parsedContent);
            return;
          }

          // Create a deep copy to avoid mutating read-only state
          const mutableContent = JSON.parse(JSON.stringify(parsedContent));
          props.setTempJSON(mutableContent);

          // Extract and set the specific section in the editor
          const contentSection = mutableContent.content.find(
            element =>
              element.attrs?.additionalClass === 'measurement-table' ||
              element.attrs?.customClass === 'measurement-table'
          );
          console.log('contentSection:', contentSection);

          if (contentSection) {
            await editor.commands.setContent(contentSection);
            props.setEditorContent(contentSection);
            console.log('Content loaded into editor');
          } else {
            console.warn('Measurement table not found');
            setMeasurementTableAbsent(true);
            props.setDisableSaveButton(true);
            console.log('measurement table absent', measurementTableAbsent);
            // editor.commands.setContent(parsedContent); // Load full content if no specific section
            // write "No measurements Found" in the editor
            editor.commands.setContent({
              type: 'doc',
              content: [
                {
                  type: 'paragraph',
                  attrs: {
                    color: '#ffffff',
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'No measurements Found',
                      marks: [
                        {
                          type: 'bold',
                          attrs: {
                            bold: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            });
          }
        } catch (error) {
          console.error('Error parsing or setting editor content:', error);
          setMeasurementTableAbsent(true);
          props.setDisableSaveButton(true);
        }
      }
    };

    // Load content every time the reportTemplate changes
    loadContent();

    // Cleanup previous editor content when switching between patients
    return () => {
      editor?.commands.clearContent();
    };
  }, [editor, props?.reportTemplate]);

  // Capture changes in the editor and update the specific section
  useEffect(() => {
    if (editor) {
      const handleUpdate = ({ editor }) => {
        console.log('measurement table absent', measurementTableAbsent);
        if (measurementTableAbsent) {
          props.setDisableSaveButton(true);
          return;
        }
        props.setDisableSaveButton(false);
        const updatedContent = editor.getJSON();

        props.setTempJSON(prevJSON => {
          if (!prevJSON || typeof prevJSON !== 'object') return prevJSON;

          const updatedJSON = JSON.parse(JSON.stringify(prevJSON));
          if (Array.isArray(updatedJSON.content)) {
            // updatedJSON.content[20] = updatedContent?.content[0];
            // Find the section with 'measurement-table'
            const contentSection = updatedContent.content.find(
              element =>
                element.attrs?.additionalClass === 'measurement-table' ||
                element.attrs?.customClass === 'measurement-table'
            );

            const sectionIndex = updatedJSON.content.findIndex(
              element =>
                element.attrs?.additionalClass === 'measurement-table' ||
                element.attrs?.customClass === 'measurement-table'
            );

            // If the section is found, replace its content
            if (sectionIndex !== -1 && contentSection) {
              // Replace the entire object at the found index
              updatedJSON.content[sectionIndex] = {
                ...updatedJSON.content[sectionIndex],
                content: contentSection.content,
              };
              props.setDisableSaveButton(false);
              console.log('🚀 ~ handleUpdate ~ Updated JSON:', updatedJSON.content[sectionIndex]);
            } else {
              console.warn('Measurement table not found during update');
              props.setDisableSaveButton(true);
            }
          }
          props.setEditorContent(updatedJSON);
          return updatedJSON;
        });
      };
      editor.on('update', handleUpdate);
      // Cleanup listener on component unmount
      return () => {
        editor.off('update', handleUpdate);
      };
    }
  }, [editor]);

  return (
    <div className="control-group">
      <BubbleMenu editor={editor}>
        <div className="bubble-menu">
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'is-active' : ''}
          >
            <strong>B</strong>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'is-active' : ''}
          >
            <strong>
              <em>I</em>
            </strong>
          </button>
          <button
            onClick={() => editor.chain().focus().setParagraph().run()}
            className={editor.isActive('paragraph') ? 'is-active' : ''}
          >
            P
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
          >
            <strong>H1</strong>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
          >
            <strong>H2</strong>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
          >
            <strong>H3</strong>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
            className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
          >
            <strong>H4</strong>
          </button>
        </div>
      </BubbleMenu>
    </div>
  );
};

const MeasurementPage = ({ measData, setHitGetReportApi }) => {
  console.log('🚀 ~ MeasurementPage ~ measData:', measData);
  const [tempJSON, setTempJSON] = useState(null);
  const [editorContent, setEditorContent] = useState({});
  const [displayMessage, setDisplayMessage] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const { status, msg, loading } = useSelector((state: RootState) => state.updateReportResponse);

  const handleSave = () => {
    setDisableSaveButton(true);
    setLoader(true);
    const payload = {
      reportId: measData?._id,
      templateHTML: editorContent,
    };
    dispatch(updateReport(payload));
  };

  useEffect(() => {
    setLoader(false);
    const handleSuccess = message => {
      setDisplayMessage(message);
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        setIsSuccessModalOpen(false);
        setDisplayMessage('');
        setHitGetReportApi(true);
      }, 1500);
    };

    const handleFailure = message => {
      setDisplayMessage(message);
      setIsFailedModalOpen(true);
      setTimeout(() => {
        setIsFailedModalOpen(false);
        setDisplayMessage('');
      }, 1500);
    };
    if (status === 'SUCCESS') {
      handleSuccess(msg);
    }

    if (status === 'FAILED') {
      handleFailure(msg);
    }

    return () => {
      dispatch(clearState());
    };
  }, [status]);

  console.log('measData', measData);
  return (
    <>
      <div className="measurementTemplateBox">
        <h2 className="title title--h3 title--semibold text-center text-black">Measurements</h2>
        <EditorProvider
          editorProps={{
            attributes: { class: 'editor-container' },
          }}
          slotBefore={
            <ReportLayout
              reportTemplate={measData?.templateHTML}
              setEditorContent={setEditorContent}
              setTempJSON={setTempJSON}
              disableSaveButton={disableSaveButton}
              setDisableSaveButton={setDisableSaveButton}
            />
          }
          extensions={extensions}
        />
        <div className="button-group">
          {!disableSaveButton && (
            <button
              onClick={handleSave}
              disabled={disableSaveButton}
              className={`${disableSaveButton ? 'cursor-not-allowed bg-black text-white' : 'save-button text-black'}`}
            >
              Update Measurements
            </button>
          )}
        </div>
      </div>
      <Loader isLoading={loader} />
      <Success
        handleCloseModal={() => setIsSuccessModalOpen(false)}
        title={'Measurements updated successfully'}
        isSuccessModalOpen={isSuccessModalOpen}
      />
      <Failed
        handleCloseModal={() => setIsFailedModalOpen(false)}
        title={displayMessage}
        isFailedModalOpen={isFailedModalOpen}
      />
    </>
  );
};

export default MeasurementPage;
