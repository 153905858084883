import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { _pacsUrls } from '../../constant';

export const getPacsData = createAsyncThunk(
  'pacs/get',
  async (payload: string, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken(),
        },
        body: JSON.stringify({ hospitalId: payload }), // Ensure payload is structured appropriately
      };

      const url = `${_pacsUrls.GET_PACS}`;
      const res = await fetch(url, requestOptions);

      if (!res.ok) {
        const errorData = await res.json();
        return rejectWithValue(errorData.msg || 'Failed to fetch hospital template');
      }

      const data = await res.json();
      console.log('data', data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
