import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { SideMenu, Header, Loader, Success, Failed } from '../../components';
import { customStyles } from '../../Utils/utils';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { addUserApi } from '../../rtk-apis/users/addUser';
import { editUserApi } from '../../rtk-apis/users/editUser';
import { getClinicApi } from '../../rtk-apis/clinics/getClinic';
import { clearState } from '../../store/slices/users/addUser';
import { clearState as clearEditState } from '../../store/slices/users/editUser';
import { userFormValidationSchema } from '../auth/helper/Schema';
import { getCommonData } from "../../rtk-apis/common/getCommonData"

const AddUpdateUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { userDetails } = location?.state || {};
  const editMode = location?.pathname?.includes('updateuser');
  const hospitalName = sessionStorage?.getItem("hospitalName")
  const hospitalId = sessionStorage?.getItem("hospitalId")
  const Role = sessionStorage?.getItem("role")

  const [displayMessage, setDisplayMessage] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const [RolesList, setRolesList] = useState([]);
  const [clinicNames, setClinicNames] = useState([]);
  const [selectedHospitalId, setSelectedHospitalId] = useState('');

  const breadcrumbs = [
    { label: 'Manage Users', link: '/users', icon: 'sidebar-icon' }, // previous page
    { label: `${editMode ? 'Update' : 'Add'} Users` }, // current page
  ];

  // ******************** Redux States **************************************

  const { status, result, msg, loading } = useSelector((state: RootState) => state.addUserResponse);
  const {
    status: editStatus,
    result: editResult,
    msg: editMsg,
    loading: editLoading,
  } = useSelector((state: RootState) => state.editUserResponse);
  const {
    status: clinicStatus,
    result: clinicResult,
    msg: clinicMsg,
    loading: clinicLoading,
  } = useSelector((state: RootState) => state.getClinicResponse);

  const {
    result: commonData,
  } = useSelector((state: RootState) => state.getCommonDataSliceResponse);

  // *************************** Use-Effects ****************************

  useEffect(() => {
    if (Role === "Admin") {
      setSelectedHospitalId(hospitalId); // Prepopulate for admin
    }
  }, [Role, hospitalId]);

  useEffect(() => {
    if (commonData?.roleList?.length > 0) {
      setRolesList(commonData?.roleList)
    }
  }, [commonData])

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 100,
      search: '',
    };
    dispatch(getClinicApi(payload));
  }, []);

  useEffect(() => {
    const payload = {}
    dispatch(getCommonData(payload))
  }, [])

  useEffect(() => {
    if (clinicStatus === 'SUCCESS') {
      setClinicNames(clinicResult?.data);
    }
  }, [clinicStatus]);

  // ********************* Formik Initial Values ****************************
  const contactDetailsWithoutCountryCode = userDetails?.contactDetails?.replace(userDetails?.countryCode, '').trim() ?? '';

  const userFormInitialValues = {
    hospitalId: userDetails?.hospitalId ?? '',
    name: userDetails?.name ?? '',
    role: userDetails?.role ?? '',
    email: userDetails?.email ?? '',
    clinicName: Role === "Admin" ? hospitalName : userDetails?.clinicName ? userDetails?.clinicName : '',
    countryCode: userDetails?.countryCode ?? '+91',
    contactNumber: contactDetailsWithoutCountryCode,
  };

  // ********************* Functions *******************************************

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = {
      id: editMode ? userDetails?._id : undefined,
      hospitalId: selectedHospitalId,
      name: values.name,
      role: values.role,
      email: values.email.toLowerCase(),
      clinicName: values.clinicName,
      countryCode: values.countryCode,
      contactDetails: values.contactNumber,
    };

    if (editMode) {
      dispatch(editUserApi(payload));
    } else {
      dispatch(addUserApi(payload));
    }
    setSubmitting(false);
  };

  useEffect(() => {
    const handleSuccess = message => {
      setDisplayMessage(message);
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        setIsSuccessModalOpen(false);
        setDisplayMessage('');
        navigate('/users');
      }, 1500);
    };

    const handleFailure = message => {
      setDisplayMessage(message);
      setIsFailedModalOpen(true);
      setTimeout(() => {
        setIsFailedModalOpen(false);
        setDisplayMessage('');
      }, 1500);
    };
    if (status === 'SUCCESS') {
      handleSuccess(msg);
    } else if (editStatus === 'SUCCESS') {
      handleSuccess(editMsg);
    }

    if (status === 'FAILED') {
      handleFailure(msg);
    } else if (editStatus === 'FAILED') {
      handleFailure(editMsg);
    }

    return () => {
      dispatch(clearState());
      dispatch(clearEditState());
    };
  }, [status, editStatus, msg, editMsg]);

  return (
    <section
      className="layoutContainer"
      style={customStyles.layoutBackground}
    >
      <SideMenu />
      <div className="layoutGrid">
        <div className="page">
          <Header breadcrumbs={breadcrumbs} />
          <div className="page__body">
            <Formik
              initialValues={userFormInitialValues}
              validationSchema={userFormValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form className="form">
                  <div className="page__body__head">
                    <h1 className="title title--h6 title--normal">
                      {`${editMode ? 'Update' : 'Add'} User`}
                    </h1>
                    <div className="btn__group">
                      <button
                        className="btn btn__white btn__outlined"
                        type="button"
                        onClick={() => navigate('/users')}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn__primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {editMode ? 'Update' : 'Save'}
                      </button>
                    </div>
                  </div>
                  <section className="form__section">
                    <h1 className="form__section__title">User Details</h1>

                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="name"
                            className="form__label"
                          >
                            Name
                            <sup className="form__label__asterisk">*</sup>
                          </label>
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            className="form__input form__input--sm"
                            placeholder="Name"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>

                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="role"
                            className="form__label"
                          >
                            Role
                            <sup className="form__label__asterisk">*</sup>
                          </label>
                          <Field
                            as="select"
                            id="role"
                            name="role"
                            className="form__select form__select--sm"
                          >
                            <option value="">-Select-</option>
                            {RolesList?.map((role, index) => (
                              <option
                                key={index}
                                value={role}
                              >
                                {role}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="role"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="email"
                            className="form__label"
                          >
                            Email Address
                            <sup className="form__label__asterisk">*</sup>
                          </label>
                          <Field
                            type="text"
                            id="email"
                            name="email"
                            className="form__input form__input--sm"
                            placeholder="Email Address"
                            disabled={editMode}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>

                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="clinicName"
                            className="form__label"
                          >
                            Clinic Name
                            <sup className="form__label__asterisk">*</sup>
                          </label>
                          <Field
                            as="select"
                            id="clinicName"
                            name="clinicName"
                            className="form__select form__select--sm"
                            disabled={Role === "Admin"}
                            onChange={(e: any) => {
                              setFieldValue('clinicName', e.target.value);
                              const selectedIndex = e.target.selectedIndex;
                              const selectedClinicId =
                                e.target.options[selectedIndex].getAttribute('data-id');

                              setSelectedHospitalId(selectedClinicId);
                            }}
                          >
                            <option value="">-Select-</option>
                            {clinicNames?.map((clinic, index) => (
                              <option
                                key={index}
                                value={clinic?.name}
                                data-id={clinic?._id}
                              >
                                {clinic?.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="clinicName"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="contactNumber"
                            className="form__label"
                          >
                            Contact Number
                            <sup className="form__label__asterisk">*</sup>
                          </label>
                          <div className="form__groupField form__groupField--phonenumber">
                            <Field
                              as="select"
                              name="countryCode"
                              className="form__select form__select--sm"
                              id="countryCode"
                            >
                              <option value="+91">+91</option>
                              <option value="+61">+61</option>
                            </Field>
                            <Field
                              type="tel"
                              name="contactNumber"
                              id="contactNumber"
                              className="form__input form__input--sm"
                              placeholder="Contact Number"
                              maxLength={10}
                              onInput={e => (e.target.value = e.target.value.replace(/\D/, ''))}
                            />
                          </div>
                          <ErrorMessage
                            name="contactNumber"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Loader isLoading={loading || editLoading || clinicLoading} />
      <Success
        handleCloseModal={() => setIsSuccessModalOpen(false)}
        title={displayMessage}
        isSuccessModalOpen={isSuccessModalOpen}
      />
      <Failed
        handleCloseModal={() => setIsFailedModalOpen(false)}
        title={displayMessage}
        isFailedModalOpen={isFailedModalOpen}
      />
    </section>
  );
};

export default AddUpdateUser;
