import React from 'react';
import '../styled/pages/dashboard.scss';

const Loader = ({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading) return null;

  return (
    <div className="loader loader--show absolute">
      <div className="loader__container">
        <div className="spinner"></div>
      </div>
      <div className="loader__backdrop"></div>
    </div>
  );
};

export default Loader;
