import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLINICS } from '../../constant';

interface Payload {
  name: string;
  emailAddress: string;
  contactNumber: string;
  Address: string;
  ZipCode: string;
  Country: string;
  State: string;
  City: string;
}

export const addClinicApi = createAsyncThunk(
  'Add Clinic',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
      };
      let res = await fetch(CLINICS.ADD_CLINIC_URL, requestOptions);

      // Check if the response is not okay (status is 400 or other errors)
      if (!res.ok) {
        const errorData = await res.json(); // Extract error message from the res
        console.log('🚀 ~ errorData:', errorData);
        return rejectWithValue(errorData.msg || 'Add Clinic failed');
      }

      const data = await res.json();
      console.log('🚀 ~ data:', data);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
