import React, { useState, useEffect, useRef } from 'react';
import { Header, SideMenu } from '../../components';
import { customStyles } from '../../Utils/utils';
import ReportCard from '../../components/ReportCard';
import '../../styled/pages/dashboard.scss';
import PatientMeasurements from '../patients/components/patientMeasurements';
import PatientRelatedReports from '../patients/components/patientRelatedReports';
import PatientReportImages from '../patients/components/patientReportImages';
import PatientReportIndex from '../patients/components/patientReport';
import PatientDistribution from '../patients/components/patientDistribution';
import { useLocation } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getReportDataById } from '../../rtk-apis/Reports/getReportDataById';

const PatientReport = () => {
  const dispatch = useDispatch<AppDispatch>();
  const breadcrumbs = [{ label: 'Reports' }];
  const [SelectedTab, setSelectedTab] = useState(1);
  const location = useLocation();
  const [ReportId, setReportId] = useState(location?.state?.reportId);
  const [ReportData, setReportData] = useState({});
  const [HitGetReportApi, setHitGetReportApi] = useState(false);
  const HospitalId = sessionStorage?.getItem('hospitalId');

  const { status, data, msg, loading } = useSelector(
    (state: RootState) => state.getReportDataByIdResponse
  );

  useEffect(() => {
    getReportData();
  }, [HitGetReportApi]);

  const getReportData = () => {
    if (ReportId && HospitalId) {
      const payload = {
        reportId: ReportId,
        hospitalId: HospitalId,
      };
      dispatch(getReportDataById(payload));
      setHitGetReportApi(false);
    }
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setReportData(data);
    }
  }, [data]);

  const tabs = [
    { tabNo: 1, label: 'Report' },
    { tabNo: 2, label: 'Images' },
    { tabNo: 3, label: 'Related Reports' },
    { tabNo: 4, label: 'Measurements' },
    { tabNo: 5, label: 'Distribution' },
  ];

  const printRef = useRef(null);

  const printAsPdf = () => {
    if (!printRef.current) return;
    const printContents = printRef.current.innerHTML;

    // Create a new iframe for printing
    const printWindow = document.createElement('iframe');
    printWindow.style.position = 'absolute';
    printWindow.style.top = '-1000px';
    printWindow.style.left = '-1000px';
    document.body.appendChild(printWindow);

    const printDocument = printWindow.contentDocument || printWindow.contentWindow.document;

    // Copy the existing stylesheets into the iframe
    const styles = Array.from(document.styleSheets)
      .map(styleSheet => {
        try {
          if (styleSheet.cssRules) {
            return Array.from(styleSheet.cssRules)
              .map(rule => rule.cssText)
              .join('\n');
          }
        } catch (e) {
          console.warn('Could not load some stylesheets:', e);
        }
        return '';
      })
      .join('\n');

    printDocument.open();
    printDocument.write(`
  <html>
    <head>
      <style>
        ${styles}
        body {
          color: black !important;
          margin: 0;
          padding: 0;
        }
        p{
          font-size: 12px !important;
        }
      </style>
    </head>
    <body>${printContents}</body>
  </html>
`);
    printDocument.close();

    printWindow.onload = () => {
      printWindow.contentWindow.focus();
      printWindow.contentWindow.print();
      document.body.removeChild(printWindow);
    };
  };

  const [updateTemplateContent, setUpdatedTemplateContent] = useState({});

  return (
    <section
      className="layoutContainer"
      style={customStyles.layoutBackground}
    >
      <SideMenu />
      <div className="layoutGrid">
        <div className="page">
          <Header breadcrumbs={breadcrumbs} />
          <div className="page__body">
            <ReportCard
              ReportData={ReportData}
              setHitGetReportApi={setHitGetReportApi}
              printAsPdf={printAsPdf}
              selectedTab={SelectedTab}
              updateTemplateContent={updateTemplateContent}
            />
            <div className="tabsWrap">
              <ul className="tabs">
                {tabs.map(tab => (
                  <li
                    key={tab.tabNo}
                    className={`tabs__link ${
                      SelectedTab === tab.tabNo ? 'tabs__link--selected' : ''
                    }`}
                    onClick={() => setSelectedTab(tab?.tabNo)}
                  >
                    {tab.label}
                  </li>
                ))}
              </ul>
            </div>
            <div className="tabsContent">
              {SelectedTab === 1 ? (
                <PatientReportIndex
                  reportData={ReportData}
                  setHitGetReportApi={setHitGetReportApi}
                  printRef={printRef}
                  setUpdatedTemplateContent={setUpdatedTemplateContent}
                />
              ) : SelectedTab === 2 ? (
                <PatientReportImages ReportData={ReportData} />
              ) : SelectedTab === 3 ? (
                <PatientRelatedReports />
              ) : SelectedTab === 4 ? (
                <PatientMeasurements
                  ReportData={ReportData?.data}
                  setHitGetReportApi={setHitGetReportApi}
                />
              ) : SelectedTab === 5 ? (
                <PatientDistribution
                  patientName={ReportData?.name}
                  patientDob={ReportData?.dob}
                  reportType={ReportData?.reportType}
                  reportDate={ReportData?.date}
                  reportTime={ReportData?.time}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PatientReport;
