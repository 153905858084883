import { createSlice } from '@reduxjs/toolkit';
import { deleteUserApi } from '../../../rtk-apis/users/deleteUser';

const initialState = {
  status: '',
  result: '',
  msg: '',
  loading: false,
};

const deleteUserSlice = createSlice({
  name: 'deleteUser',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(deleteUserApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteUserApi.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.msg = action?.payload?.msg || 'User deleted successfully.';
        state.result = action?.payload;
      })
      .addCase(deleteUserApi.rejected, (state, action) => {
        state.loading = false;
        state.status = 'FAILED';
        state.result = (action?.payload as string) || 'Unable to delete user';
        state.msg = action.error.message || 'Unable to delete user';
      });
  },
});

export default deleteUserSlice.reducer;
export const { clearState } = deleteUserSlice.actions;
