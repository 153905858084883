import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { CustomTable, Header, Loader, SideMenu } from '../../components';
import { capitalizeFirstLetterOfEachWord, customStyles } from '../../Utils/utils';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@ohif/ui';
import SortComponent from '../../components/SortComponent';
import useDebounce from '../../components/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { getPacsData } from '../../rtk-apis/pacs/getPacs';
import { deletePacsApi } from '../../rtk-apis/pacs/deletePacs';
import ConfirmationModal from '../../components/ConfirmationModal';

interface PacsData {
  _id: string;
  aeTitle: string;
  ipAddress: string;
  port: string;
  hospitalId: string;
  type: string;
  createdAt: string;
}

const Pacs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { status, result, msg, loading } = useSelector((state: RootState) => state.getPacsDataResponse);

  const hospitalId = localStorage.getItem('hospitalId') || sessionStorage.getItem('hospitalId');
  const [searchTerm, setSearchTerm] = useState('');
  const [tableData, setTableData] = useState<PacsData[]>([]);
  const [filteredData, setFilteredData] = useState<PacsData[]>([]);
  const searchQuery = useDebounce(searchTerm, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const breadcrumbs = [{ label: 'PACS', icon: 'sidebar-icon' }];

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedPacs, setSelectedPacs] = useState<PacsData | null>(null);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

  useEffect(() => {
    dispatch(getPacsData(hospitalId));
  }, [dispatch, hospitalId]);

  useEffect(() => {
    if (status === 'SUCCESS' && result && result.data) {
      const pacsData = result.data;
      setTableData(pacsData);
      setFilteredData(pacsData);
    }
  }, [status, result]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = tableData.filter(item =>
        item.aeTitle.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
      setCurrentPage(1);
    } else {
      setFilteredData(tableData);
    }
  }, [searchQuery, tableData]);

  const handleDeletePacs = () => {
    if (selectedPacs) {
      dispatch(deletePacsApi({ id: selectedPacs._id }))
        .unwrap()
        .then(() => {
          dispatch(getPacsData(hospitalId));
          setIsConfirmationModalOpen(false);
        })
        .catch(err => {
          console.error('Error deleting PACS:', err);
        });
    }
  };

  useEffect(() => {
    if (status === 'SUCCESS' && result && result.data) {
      const pacsData = result.data.map((item: PacsData) => ({
        ...item,
        aeTitle: capitalizeFirstLetterOfEachWord(item.aeTitle),
      }));
      setTableData(pacsData);
      setFilteredData(pacsData);
    }
  }, [status, result]);

  const columns = [
    { key: 'aeTitle', title: 'AE Title' },
    { key: 'ipAddress', title: 'IP Address' },
    { key: 'port', title: 'Port' },
  ];

  const actions = [
    {
      label: 'Edit',
      iconName: 'edit-icon',
      onClick: (item: PacsData) => {
        navigate(`/addpacs`, { state: { userDetails: item, id: item._id } });
      },
    },
    {
      label: 'Delete',
      iconName: 'delete-icon',
      onClick: (item: PacsData) => {
        setSelectedPacs(item);
        setIsConfirmationModalOpen(true);
      },
    },
  ];

  return (
    <>
      <section
        className="layoutContainer"
        style={customStyles.layoutBackground}
      >
        <SideMenu />
        <div className="layoutGrid">
          <div className="page page--fullBody">
            <Header breadcrumbs={breadcrumbs} />
            <div className="page__body">
              <div className="page__content">
                <div className="page__body__head">
                  <h1 className="title title--h6 title--normal">PACS</h1>
                  <div className="btn__group">
                    <button
                      type="button"
                      className="btn btn__primary"
                      onClick={() => {
                        navigate('/addpacs');
                      }}
                    >
                      <Icon name="add-icon" />
                      Add PACS
                    </button>
                  </div>
                </div>
                <div className="tableWrap">
                  <div className="tableWrap__head">
                    <div className="formRow">
                      <div className="formCol relative">
                        <input
                          className="form__input form__input--search"
                          type="text"
                          placeholder="Search by AE Title"
                          value={searchTerm}
                          onChange={e => setSearchTerm(e?.target?.value)}
                        />
                        <span className="absolute right-4 bottom-3 cursor-pointer">
                          <Icon name="search-icon" />
                        </span>
                      </div>
                    </div>
                    <div className="btn__group">
                      <SortComponent
                        searchQuery={searchQuery}
                        sortKey="aeTitle"
                        data={tableData}
                        setFilteredData={setFilteredData}
                      />
                    </div>
                  </div>
                  <CustomTable
                    actions={actions}
                    columns={columns}
                    tableData={currentItems}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConfirmationModal
        title="Delete PACS"
        description="Are you sure you want to delete this PACS?"
        isConfirmationModalOpen={isConfirmationModalOpen}
        handleCloseModal={() => setIsConfirmationModalOpen(false)}
        handleConfirm={handleDeletePacs}
        cancelActionText="Cancel"
        confirmActionText="Yes"
      />
      <Loader isLoading={loading} />
    </>
  );
};

export default Pacs;
