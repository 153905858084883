import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { USERS } from '../../constant';

interface Payload {
  id?: string;
  hospitalId?: string;
  name: string;
  role: string;
  email: string;
  clinicName: string;
  countryCode: string;
  contactDetails: string;
}

export const addUserApi = createAsyncThunk(
  'addUser',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await fetch(USERS.ADD_USER_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.log('errorData', errorData);
        return rejectWithValue(errorData?.error || errorData?.msg);
      }

      const result = await response.json();

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
