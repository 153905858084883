import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { USERS } from '../../constant';

type Payload = {
  search: any;
  limit: any;
  page: any;
  id?: string;
};

export const getUserApi = createAsyncThunk(
  'getUser',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await fetch(`${USERS.GET_USER_URL}?page=${payload?.page}&limit=${payload?.limit}&search=${payload?.search}&hospitalId=${payload?.hospitalId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.msg || 'Get User failed');
      }

      const result = await response.json();

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
