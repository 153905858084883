import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@ohif/ui';
import { customStyles, maskEmail } from '../../Utils/utils';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { otpVerificationApi } from '../../rtk-apis/auth/otpVerification';
import { otpSchema } from './helper/Schema';
import '../../styled/pages/login.scss';
import { clearState } from '../../store/slices/Auth/otpVerification';
import { Failed, Loader, Success } from '../../components';

const OtpVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const { status, result, msg, loading } = useSelector(
    (state: RootState) => state.otpVerificationResponse
  );

  const [timeLeft, setTimeLeft] = useState(59);
  const [maskedEmail, setMaskedEmail] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const otpInputRefs = useRef([]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (!location.state) {
      navigate('/login');
    }
    setMaskedEmail(maskEmail(location.state.email));
  }, []);

  const resendOtp = () => {
    setTimeLeft(59);
  };

  const handleOtpChange = (value, index, setFieldValue, values) => {
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...values.otp];
      newOtp[index] = value;
      setFieldValue('otp', newOtp);

      // Focus next input if it exists
      if (index < 5 && value !== '') {
        otpInputRefs.current[index + 1].focus();
      }
    }
  };

  const handleOtpKeyDown = (e, index, setFieldValue, values) => {
    if (e.key === 'Backspace') {
      e.preventDefault();

      const newOtp = [...values.otp];
      if (newOtp[index] === '') {
        if (index > 0) {
          otpInputRefs.current[index - 1].focus();
        }
      } else {
        newOtp[index] = '';
        setFieldValue('otp', newOtp);
      }
    }
  };

  const handleSubmit = values => {
    // Add OTP submission logic here
    const enteredOtp = +values.otp.join('');

    const payload = {
      email: location.state.email.toLowerCase(),
      enteredOtp: enteredOtp,
    };

    dispatch(otpVerificationApi(payload));
  };

  useEffect(() => {
    if (status === 'SUCCESS') {
      setIsSuccessModalOpen(true);
      navigate('/resetPassword', {
        state: {
          email: location.state.email,
        },
      });
    }

    if (status === 'ERROR') {
      setIsFailedModalOpen(true);
    }
    dispatch(clearState());
  }, [status]);

  return (
    <>
      <section
        className="login"
        style={customStyles.loginBackground}
      >
        <div className="login__container">
          <Formik
            initialValues={{ otp: new Array(6).fill('') }}
            validationSchema={otpSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue, errors }) => (
              <Form>
                <div className="login__wrap">
                  <div className="branding">
                    <div className="branding__logo">
                      <Icon name="two-step-icon" />
                    </div>
                    <div className="branding__info">
                      <h1 className="title title--h2 title--semibold">Two Step Verification</h1>
                      <p className="text">
                        Enter the verification code we sent to <br />
                        {maskedEmail}
                      </p>
                      <h4 className="title title--h5 title--medium">
                        Type your 6 digit security code
                      </h4>
                    </div>
                  </div>

                  <div className="login__form">
                    <div className="form__group">
                      <div className="form__group__otp">
                        {values.otp.map((digit, index) => (
                          <Field
                            key={index}
                            name={`otp[${index}]`}
                            type="text"
                            maxLength={1}
                            className="form__input"
                            value={digit}
                            innerRef={el => (otpInputRefs.current[index] = el)}
                            onChange={e =>
                              handleOtpChange(e.target.value, index, setFieldValue, values)
                            }
                            onKeyDown={e => handleOtpKeyDown(e, index, setFieldValue, values)}
                          />
                        ))}
                      </div>
                      {/* {errors.otp && errors?.otp?.length > 0 && (
                        <div className="error">{errors.otp[0] && <span>{errors.otp[0]}</span>}</div>
                      )} */}
                    </div>

                    <div className="form__button textCenter">
                      <button
                        className="btn btn__primary btn--full"
                        type="submit"
                      >
                        Submit
                      </button>

                      <div className="form__optInfo">
                        <p className="para">
                          Didn’t get the code?{' '}
                          <button
                            type="button"
                            disabled={timeLeft > 0}
                            className={`link ${timeLeft > 0 ? 'link--disabled cursor-not-allowed' : 'link'}`}
                            onClick={resendOtp}
                          >
                            Resend Code
                          </button>
                        </p>
                        {timeLeft > 0 && (
                          <p className="para">
                            <Icon name="timer" />
                            <span className="link">
                              {`${Math.floor(timeLeft / 60)}:${String(timeLeft % 60).padStart(2, '0')}`}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Loader isLoading={loading} />
        <Success
          isSuccessModalOpen={isSuccessModalOpen}
          handleCloseModal={() => setIsSuccessModalOpen(false)}
          title="Verification Completed Successfully !"
        />
        <Failed
          isFailedModalOpen={isFailedModalOpen}
          handleCloseModal={() => setIsFailedModalOpen(false)}
          title="Verification Failed !"
        />
      </section>
    </>
  );
};

export default OtpVerification;
