import React, { useState, useEffect } from 'react';
import '../../../../../custom/styled/pages/dashboard.scss';
import { getHospitalDoctorsList } from '../../../../rtk-apis/Reports/getHospitalDoctorsList';
import { AppDispatch, RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { assignReportToDoctor } from '../../../../rtk-apis/Reports/assignReportToDoctor';
import { Loader, Success, Failed } from '../../../../components';
import { fetchDoctorsToAssign } from '../../../../rtk-apis/Reports/fetchDoctorsToAssign';
import { capitalizeFirstLetterOfEachWord } from '../../../../Utils/utils';

type Props = {
  onClose?: () => void;
  reportId: string;
  AssignReportStatus: string;
  setOnAssigningReport: React.Dispatch<any>;
  isAssignDoctorModalOpen: boolean;
};

const AssignDoctorModal = ({
  onClose,
  reportId,
  setOnAssigningReport,
  AssignReportStatus,
  isAssignDoctorModalOpen
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const [OpenSuccessfullModal, setOpenSuccessfullModal] = useState(false);
  const [OpenFailureModal, setOpenFailureModal] = useState(false);
  const [SelectedDoctor, setSelectedDoctor] = useState('');
  const [SuccessMessage, setSuccessMessage] = useState('');
  const [DoctorsList, setDoctorsList] = useState([]);

  const hospitalId = sessionStorage?.getItem('hospitalId');

  useEffect(() => {
    if (AssignReportStatus === 'Assign') {
      const payload = {};
      dispatch(getHospitalDoctorsList(payload));
    } else if (AssignReportStatus === 'Reassign') {
      const payload = {
        hospitalId: hospitalId,
        reportId: reportId,
      };
      dispatch(fetchDoctorsToAssign(payload));
    }
  }, [isAssignDoctorModalOpen]);

  const { data } = useSelector((state: RootState) => state.getHospitalDoctorsListResponse);

  const { result } = useSelector((state: RootState) => state.fetchDoctorsToAssignResponse);

  useEffect(() => {
    if (AssignReportStatus === 'Assign' && data?.length > 0) {
      setDoctorsList(data);
    } else if (AssignReportStatus === 'Reassign' && result?.data?.length > 0) {
      setDoctorsList(result?.data);
    }
  }, [result, data]);

  const { status, msg, loading } = useSelector(
    (state: RootState) => state.assignReportToDoctorResponse
  );

  const handleConfirmAssign = () => {
    onClose();
    const payload = {
      userId: SelectedDoctor,
      reportIds: [reportId],
    };
    dispatch(assignReportToDoctor(payload));
  };

  useEffect(() => {
    if (status === 'SUCCESS') {
      setOnAssigningReport(true);
      setOpenSuccessfullModal(true);
      setSuccessMessage(msg);
      setTimeout(() => {
        setOpenSuccessfullModal(false);
        setSuccessMessage('');
      }, 1500);
    } else if (status === 'FAILED') {
      setOpenFailureModal(true);
      setSuccessMessage(msg);
      setTimeout(() => {
        setOpenFailureModal(false);
        setSuccessMessage('');
      }, 1500);
    }
  }, [status]);

  return (
    <>
      <div className={`modal modal--assignDoctor ${isAssignDoctorModalOpen ? 'modal--open' : ''} `}>
        <div className="modal__dialog">
          <div className="modal__content modal__content--lg">
            <div className="modal__header">
              <h1 className="modal__header--title">Assign a Doctor</h1>
              <p className="para">Select one Doctor from below option to assign a report</p>
            </div>
            <div className="modal__body modal-body-left">
              <div className="formRow mb_0">
                <div className="formCol">
                  <div className="form__group">
                    <label
                      htmlFor="assignedTo"
                      className="form__label"
                    >
                      Assigned To
                    </label>
                    <select
                      id="assignedTo"
                      className="form__select form__select--sm"
                      onChange={e => {
                        setSelectedDoctor(e?.target?.value);
                      }}
                    >
                      <option value="">-Select-</option>
                      {DoctorsList?.length > 0 &&
                        DoctorsList?.map(doctor => {
                          return (
                            <option value={doctor?._id}>
                              {' '}
                              {capitalizeFirstLetterOfEachWord(doctor?.name)}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button
                  className="btn btn__white btn__outlined"
                  onClick={onClose}
                >
                  No, cancel
                </button>
                <button
                  className="btn btn__primary"
                  onClick={handleConfirmAssign}
                >
                  Yes, Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal__backdrop"
          onClick={onClose}
        ></div>
      </div>
      {OpenSuccessfullModal && (
        <Success
          title={SuccessMessage}
          isSuccessModalOpen={OpenSuccessfullModal}
          handleCloseModal={() => setOpenSuccessfullModal(false)}
        />
      )}
      {OpenFailureModal && (
        <Failed
          isFailedModalOpen={OpenFailureModal}
          handleCloseModal={() => {
            setOpenFailureModal(false);
          }}
          title={SuccessMessage}
        />
      )}
    </>
  );
};

export default AssignDoctorModal;
