import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { Header, Loader, SideMenu } from '../../components';
import { capitalizeFirstLetterOfEachWord, customStyles } from '../../Utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import { getDashboardData } from '../../rtk-apis/dashboard/getDashboardData';
import PieChartData from '../../components/PieChart';
import SplineChart from '../../components/LineChart';
import ReportsBarChartComponent from '../../components/BarChart';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { status, result, msg, loading } = useSelector(
    (state: RootState) => state.getDashboardDataResponse
  );
  const role = localStorage.getItem('role') || sessionStorage.getItem('role');
  const [usersPerHospitalData, setUsersPerHospitalData] = useState([]);
  const [usersPerRoleData, setUsersPerRoleData] = useState([]);
  const [monthlyHospitalChartData, setMonthlyHospitalChartData] = useState({ categories: [], series: [] });
  const [reportsByStatusData, setReportsByStatusData] = useState({});

  useEffect(() => {
    const fetchDashboardData = async () => {
      const response = await dispatch(getDashboardData()).unwrap();
      if (response.status === "SUCCESS") {
        const { monthlyHospitals, usersPerHospital, usersPerRole, reportsByHospitalAndStatus } = response.data;

        const categories = Object.keys(monthlyHospitals);
        const seriesData = categories.map(
          (month) => monthlyHospitals[month]?.length || 0
        );

        setMonthlyHospitalChartData({ categories, series: [{ name: "Hospitals", data: seriesData }] });

        const usersPerRoleChart = usersPerRole.map((roleData, index) => ({
          value: roleData.count,
          label: roleData.role,
          color: roleData.color,
        }));

        const usersPerHospitalChart = usersPerHospital.map((hospitalData, index) => ({
          value: hospitalData.count,
          label: hospitalData.name,
          color: hospitalData.color,
        }));

        const firstHospital = Object.keys(reportsByHospitalAndStatus)[0];
        const hospitalReportsData = reportsByHospitalAndStatus[firstHospital];

        setUsersPerRoleData(usersPerRoleChart);
        setUsersPerHospitalData(usersPerHospitalChart);
        setReportsByStatusData(hospitalReportsData);
      }
    };

    fetchDashboardData();
  }, [dispatch]);

  const breadcrumbs = [{ label: 'Dashboard', icon: 'sidebar-icon' }];

  return (
    <>
      <section className="layoutContainer" style={customStyles.layoutBackground}>
        <SideMenu />
        <div className="layoutGrid">
          <div className="page page--fullBody">
            <Header breadcrumbs={breadcrumbs} />
            <div className="page__body">
              <div className="dashboardWrap">
                <div className="dashboardWrap__body">
                  <div className="cardRow cardRow__2">
                    {role === 'SUPER_ADMIN' ? (
                      <div className="col">
                        <div className="card">
                          <div className="card__header">
                            <h3 className="title">Users Per Hospital</h3>
                          </div>
                          <div className="card__body">
                            <div className="graphInfo">
                              <div className="graphInfo__graph">
                                <PieChartData chartData={usersPerHospitalData} />
                              </div>
                              <ul className="graphInfo__info">
                                {usersPerHospitalData.map((roleData, index) => (
                                  <li key={index}>
                                    <div
                                      className="inline-flex items-center px-4 py-2 rounded-full w-full"
                                      style={{ backgroundColor: roleData.color }}
                                    >
                                      <span className="text-white font-semibold mr-2">
                                        {capitalizeFirstLetterOfEachWord(roleData?.label) + ' - '}
                                      </span>
                                      <span className="text-white">
                                        {roleData.value} {roleData.value === 1 ? 'User' : 'Users'}
                                      </span>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {(role === 'SUPER_ADMIN' || role === 'Admin') && (
                      <div className="col">
                        <div className="card">
                          <div className="card__header">
                            <h3 className="title">Users Per Role</h3>
                          </div>
                          <div className="card__body">
                            <div className="graphInfo">
                              <div className="graphInfo__graph">
                                <PieChartData chartData={usersPerRoleData} />
                              </div>
                              <ul className="graphInfo__info">
                                {usersPerRoleData.map((roleData, index) => (
                                  <li key={index}>
                                    <div
                                      className="inline-flex items-center px-4 py-2 rounded-full w-full"
                                      style={{ backgroundColor: roleData.color }}
                                    >
                                      <span className="text-white font-semibold mr-2">
                                        {capitalizeFirstLetterOfEachWord(roleData?.label) + ' - '}
                                      </span>
                                      <span className="text-white">
                                        {roleData.value} {roleData.value === 1 ? 'User' : 'Users'}
                                      </span>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {role === 'SUPER_ADMIN' ? (
                    <div className="cardRow">
                      <div className="col">
                        <div className="card">
                          <div className="card__header">
                            <h3 className="title">Hospitals Per Month</h3>
                          </div>
                          <div className="card__body">
                            <SplineChart
                              categories={monthlyHospitalChartData.categories}
                              series={monthlyHospitalChartData.series}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col">
                      <div className="card">
                        <div className="card__header">
                          <h3 className="title">Reports</h3>
                        </div>
                        <div className="card__body">
                          <ReportsBarChartComponent reportsData={reportsByStatusData} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Loader isLoading={loading} />
    </>
  );
};

export default Dashboard;
