import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { _pacsUrls } from '../../constant';

interface Payload {
  id: string;
}

export const deletePacsApi = createAsyncThunk(
  'Delete Pacs',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
      };
      let res = await fetch(_pacsUrls.DELETE_PACS, requestOptions);

      if (!res.ok) {
        const errorData = await res.json();
        return rejectWithValue(errorData.msg || 'Delete pacs failed.');
      }

      const data = await res.json();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
