import React from 'react';

export const StressBullsEyePlot = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 118.83 141.19"
      className="target-bulls-eye-plot"
    >
      <defs>
        <style>
          {`.cls-1 { fill: #b7eb8f; }
            .cls-2, .cls-3 { fill: none; stroke: #000; }
            .cls-2 { strokeWidth: 0.6px; }
            .cls-3 { strokeWidth: 0.6px; }`}
        </style>
      </defs>
      <g
        id="Layer_2"
        data-name="Layer 2"
      >
        <g
          id="Layer_1-2"
          data-name="Layer 1"
        >
          <path d="M53.82,2.09c0,.82-.42.94-1,.94H51.06V1.2H52.8C53.41,1.2,53.82,1.32,53.82,2.09ZM53.59,5.8A2.08,2.08,0,0,0,53.81,7h1.55V6.74a.55.55,0,0,1-.31-.57C55,4.1,55,4,54.08,3.62a1.7,1.7,0,0,0,1.18-1.73A1.75,1.75,0,0,0,53.38,0H49.62V7h1.44V4.23h1.66c.62,0,.89.25.89.83C53.61,5.36,53.59,5.28,53.59,5.8Z" />
          <path d="M59.6,3.94H57.54c.08-.79.42-1.18,1-1.18S59.55,3.18,59.6,3.94Zm-1-2.21c-1.49,0-2.41,1.08-2.41,2.81s.91,2.68,2.38,2.68A2.4,2.4,0,0,0,61,5.54H59.62a1,1,0,0,1-1,.66c-.9,0-1-.67-1.07-1.37H61a2.11,2.11,0,0,0,0-.23C61,2.31,59.61,1.73,58.58,1.73Z" />
          <path d="M63,5.49H61.68c0,1.14.87,1.73,2.45,1.73,1.81,0,2.26-1,2.26-1.75a1.25,1.25,0,0,0-1-1.31l-1.7-.49c-.38-.12-.47-.19-.47-.4s.3-.48.77-.48c.65,0,1,.23,1,.7h1.3c0-1.1-.86-1.76-2.26-1.76s-2.13.66-2.13,1.73A1.29,1.29,0,0,0,63,4.74l1.61.5c.33.1.45.21.45.42s-.37.51-1,.51C63.21,6.17,63.07,5.85,63,5.49Z" />
          <path d="M69.69,1.92h-.75V.53H67.6V1.92h-.67v.89h.67V6c0,.82.43,1.22,1.31,1.22a2.38,2.38,0,0,0,.78-.1V6.18l-.31,0c-.35,0-.44-.11-.44-.57V2.81h.75Z" />
          <path
            className="cls-1 stress-thirteen"
            d="M38.51,59.67A30.79,30.79,0,0,1,43,55.94a30,30,0,0,1,5.11-2.77,28.69,28.69,0,0,1,2.73-1c.93-.28,1.86-.52,2.81-.71a29.29,29.29,0,0,1,11.54,0c.95.19,1.88.43,2.81.71a28.69,28.69,0,0,1,2.73,1,25.22,25.22,0,0,1,2.62,1.26,26.18,26.18,0,0,1,2.49,1.51,30.79,30.79,0,0,1,4.48,3.73L69.86,70.29a14.81,14.81,0,0,0-2.24-1.87,14.38,14.38,0,0,0-5.33-2.24A14.59,14.59,0,0,0,51.2,68.42,14.81,14.81,0,0,0,49,70.29Z"
          />
          <path
            className="cls-2"
            d="M38.51,59.67A30.79,30.79,0,0,1,43,55.94a30,30,0,0,1,5.11-2.77,28.69,28.69,0,0,1,2.73-1c.93-.28,1.86-.52,2.81-.71a29.29,29.29,0,0,1,11.54,0c.95.19,1.88.43,2.81.71a28.69,28.69,0,0,1,2.73,1,25.22,25.22,0,0,1,2.62,1.26,26.18,26.18,0,0,1,2.49,1.51,30.79,30.79,0,0,1,4.48,3.73L69.86,70.29a14.81,14.81,0,0,0-2.24-1.87,14.38,14.38,0,0,0-5.33-2.24A14.59,14.59,0,0,0,51.2,68.42,14.81,14.81,0,0,0,49,70.29Z"
          />
          <path
            className="cls-1 stress-sixteen"
            d="M80.31,59.68A29.54,29.54,0,0,1,84,64.23a30.58,30.58,0,0,1,2.73,5.19c.37.91.7,1.83,1,2.77a28.38,28.38,0,0,1,.7,2.86c.19,1,.33,1.94.43,2.91s.14,2,.14,3,0,2-.14,2.94-.24,2-.43,2.91a27.74,27.74,0,0,1-.7,2.86,31.06,31.06,0,0,1-2.22,5.44A27.78,27.78,0,0,1,84,97.58,28.72,28.72,0,0,1,82.26,100a26.85,26.85,0,0,1-2,2.18L69.86,91.52a14.93,14.93,0,0,0,1.84-2.28,14.56,14.56,0,0,0,1.37-2.59,15.25,15.25,0,0,0,.84-2.82,15.42,15.42,0,0,0,0-5.85,15.25,15.25,0,0,0-.84-2.82,15.25,15.25,0,0,0-3.21-4.87Z"
          />
          <path
            className="cls-2"
            d="M80.31,59.68A29.54,29.54,0,0,1,84,64.23a30.58,30.58,0,0,1,2.73,5.19c.37.91.7,1.83,1,2.77a28.38,28.38,0,0,1,.7,2.86c.19,1,.33,1.94.43,2.91s.14,2,.14,3,0,2-.14,2.94-.24,2-.43,2.91a27.74,27.74,0,0,1-.7,2.86,31.06,31.06,0,0,1-2.22,5.44A27.78,27.78,0,0,1,84,97.58,28.72,28.72,0,0,1,82.26,100a26.85,26.85,0,0,1-2,2.18L69.86,91.52a15.35,15.35,0,0,0,1.84-2.27,15,15,0,0,0,1.37-2.6,15.25,15.25,0,0,0,.84-2.82,15.42,15.42,0,0,0,0-5.85,15.25,15.25,0,0,0-.84-2.82,15.25,15.25,0,0,0-3.21-4.87Z"
          />
          <path
            className="cls-1 stress-fifteen"
            d="M80.32,102.13a26.82,26.82,0,0,1-2.15,2,29,29,0,0,1-2.33,1.76c-.8.54-1.63,1-2.48,1.51s-1.73.88-2.63,1.26a26.13,26.13,0,0,1-2.73,1,26.91,26.91,0,0,1-2.81.72c-1,.19-1.91.33-2.87.43a30.11,30.11,0,0,1-5.8,0c-1-.1-1.91-.24-2.86-.43a29.7,29.7,0,0,1-5.55-1.71c-.89-.38-1.77-.8-2.62-1.26s-1.68-1-2.49-1.51-1.58-1.14-2.33-1.76a28.64,28.64,0,0,1-2.15-2L49,91.52a14.5,14.5,0,0,0,4.8,3.25,14.11,14.11,0,0,0,2.77.86,14.65,14.65,0,0,0,2.88.29,14.83,14.83,0,0,0,2.89-.29,14.32,14.32,0,0,0,2.77-.86,14.61,14.61,0,0,0,2.55-1.38,14.34,14.34,0,0,0,2.24-1.87Z"
          />
          <path
            className="cls-2"
            d="M80.32,102.13a26.82,26.82,0,0,1-2.15,2,29,29,0,0,1-2.33,1.76c-.8.54-1.63,1-2.48,1.51s-1.73.88-2.63,1.26a26.13,26.13,0,0,1-2.73,1,26.91,26.91,0,0,1-2.81.72c-1,.19-1.91.33-2.87.43a30.11,30.11,0,0,1-5.8,0c-1-.1-1.91-.24-2.86-.43a29.7,29.7,0,0,1-5.55-1.71c-.89-.38-1.77-.8-2.62-1.26s-1.68-1-2.49-1.51-1.58-1.14-2.33-1.76a28.64,28.64,0,0,1-2.15-2L49,91.52a14.5,14.5,0,0,0,4.8,3.25,14.11,14.11,0,0,0,2.77.86,14.65,14.65,0,0,0,2.88.29,14.83,14.83,0,0,0,2.89-.29,14.32,14.32,0,0,0,2.77-.86,14.61,14.61,0,0,0,2.55-1.38,14.34,14.34,0,0,0,2.24-1.87Z"
          />
          <path
            className="cls-1 stress-fourteen"
            d="M38.51,102.12a28.74,28.74,0,0,1-1.94-2.18c-.62-.76-1.19-1.55-1.73-2.36a27.94,27.94,0,0,1-1.49-2.53c-.46-.87-.87-1.76-1.24-2.66s-.7-1.84-1-2.78-.52-1.89-.71-2.85a30.94,30.94,0,0,1,0-11.72c.19-1,.43-1.91.71-2.85s.61-1.87,1-2.78a29.17,29.17,0,0,1,1.24-2.66,27.94,27.94,0,0,1,1.49-2.53,28.56,28.56,0,0,1,1.73-2.36c.61-.76,1.26-1.49,1.94-2.19L49,70.29a14.32,14.32,0,0,0-1.83,2.27,14.65,14.65,0,0,0-1.37,2.6,15.08,15.08,0,0,0-.84,8.67,15.39,15.39,0,0,0,.84,2.81,15,15,0,0,0,1.37,2.6A14.32,14.32,0,0,0,49,91.51Z"
          />
          <path
            className="cls-2"
            d="M38.51,102.12a28.74,28.74,0,0,1-1.94-2.18c-.62-.76-1.19-1.55-1.73-2.36a27.94,27.94,0,0,1-1.49-2.53c-.46-.87-.87-1.76-1.24-2.66s-.7-1.84-1-2.78-.52-1.89-.71-2.85a30.94,30.94,0,0,1,0-11.72c.19-1,.43-1.91.71-2.85s.61-1.87,1-2.78a29.17,29.17,0,0,1,1.24-2.66,27.94,27.94,0,0,1,1.49-2.53,28.56,28.56,0,0,1,1.73-2.36c.61-.76,1.26-1.49,1.94-2.19L49,70.29a14.32,14.32,0,0,0-1.83,2.27,14.65,14.65,0,0,0-1.37,2.6,15.08,15.08,0,0,0-.84,8.67,15.39,15.39,0,0,0,.84,2.81,15,15,0,0,0,1.37,2.6A14.32,14.32,0,0,0,49,91.51Z"
          />
          <path
            className="cls-1 stress-seven"
            d="M37.25,41.87a44,44,0,0,1,10.69-4.49,42.48,42.48,0,0,1,5.69-1.15,43.1,43.1,0,0,1,11.57,0,42.48,42.48,0,0,1,5.69,1.15,44.31,44.31,0,0,1,10.7,4.49l-7.39,13a30.32,30.32,0,0,0-3.47-1.73,28.39,28.39,0,0,0-7.46-2,28.71,28.71,0,0,0-7.71,0,28.57,28.57,0,0,0-3.79.76,27.78,27.78,0,0,0-3.66,1.27,29.52,29.52,0,0,0-3.47,1.73Z"
          />
          <path
            className="cls-2"
            d="M37.25,41.87a44,44,0,0,1,10.69-4.49,42.48,42.48,0,0,1,5.69-1.15,43.1,43.1,0,0,1,11.57,0,42.48,42.48,0,0,1,5.69,1.15,44.31,44.31,0,0,1,10.7,4.49l-7.39,13a30.32,30.32,0,0,0-3.47-1.73,28.39,28.39,0,0,0-7.46-2,28.71,28.71,0,0,0-7.71,0,28.57,28.57,0,0,0-3.79.76,27.78,27.78,0,0,0-3.66,1.27,29.52,29.52,0,0,0-3.47,1.73Z"
          />
          <path
            className="cls-1 stress-twelve"
            d="M81.58,41.85a46,46,0,0,1,4.82,3.28,44.3,44.3,0,0,1,8.18,8.31,43.09,43.09,0,0,1,3.22,4.9,44.37,44.37,0,0,1,2.57,5.28,45.85,45.85,0,0,1,3,11.35,44.43,44.43,0,0,1,.38,5.88H89a30.23,30.23,0,0,0-.25-3.92A31.53,31.53,0,0,0,88,73.08a30.09,30.09,0,0,0-1.25-3.72,30.38,30.38,0,0,0-3.85-6.78,30.1,30.1,0,0,0-2.55-3A31.11,31.11,0,0,0,77.4,57a31.77,31.77,0,0,0-3.21-2.18Z"
          />
          <path
            className="cls-2"
            d="M81.58,41.85a46,46,0,0,1,4.82,3.28,44.3,44.3,0,0,1,8.18,8.31,43.09,43.09,0,0,1,3.22,4.9,44.37,44.37,0,0,1,2.57,5.28,45.85,45.85,0,0,1,3,11.35,44.43,44.43,0,0,1,.38,5.88H89a30.23,30.23,0,0,0-.25-3.92A31.53,31.53,0,0,0,88,73.08a30.09,30.09,0,0,0-1.25-3.72,30.38,30.38,0,0,0-3.85-6.78A29.49,29.49,0,0,0,77.4,57a31.77,31.77,0,0,0-3.21-2.18Z"
          />
          <path
            className="cls-1 stress-eleven"
            d="M103.74,80.85a44.43,44.43,0,0,1-.38,5.88,45.85,45.85,0,0,1-3,11.35,44.37,44.37,0,0,1-2.57,5.28,43.09,43.09,0,0,1-3.22,4.9,44.79,44.79,0,0,1-3.82,4.43,45.78,45.78,0,0,1-4.36,3.88,44.39,44.39,0,0,1-4.82,3.27l-7.39-13a30.29,30.29,0,0,0,3.21-2.19,29.64,29.64,0,0,0,2.91-2.58,31.3,31.3,0,0,0,2.55-3A30.49,30.49,0,0,0,85,95.86a29.29,29.29,0,0,0,1.71-3.52A30.09,30.09,0,0,0,88,88.62a31.53,31.53,0,0,0,.75-3.85A30.23,30.23,0,0,0,89,80.85Z"
          />
          <path
            className="cls-2"
            d="M103.74,80.85a44.43,44.43,0,0,1-.38,5.88,45.85,45.85,0,0,1-3,11.35,44.37,44.37,0,0,1-2.57,5.28,43.09,43.09,0,0,1-3.22,4.9,44.79,44.79,0,0,1-3.82,4.43,45.78,45.78,0,0,1-4.36,3.88,44.39,44.39,0,0,1-4.82,3.27l-7.39-13a30.29,30.29,0,0,0,3.21-2.19,29.64,29.64,0,0,0,2.91-2.58,31.3,31.3,0,0,0,2.55-3A30.49,30.49,0,0,0,85,95.86a29.29,29.29,0,0,0,1.71-3.52A30.09,30.09,0,0,0,88,88.62a31.53,31.53,0,0,0,.75-3.85A30.23,30.23,0,0,0,89,80.85Z"
          />
          <path
            className="cls-1 stress-eight"
            d="M15.08,80.86A46.2,46.2,0,0,1,15.46,75a45.4,45.4,0,0,1,1.13-5.78,43.37,43.37,0,0,1,1.87-5.57,44.6,44.6,0,0,1,5.78-10.18A43.77,43.77,0,0,1,28.07,49a44.69,44.69,0,0,1,4.36-3.89,45.33,45.33,0,0,1,4.82-3.27l7.39,13A30.32,30.32,0,0,0,41.42,57a29.63,29.63,0,0,0-2.9,2.59A28.53,28.53,0,0,0,36,62.58a29.73,29.73,0,0,0-2.15,3.27,28.57,28.57,0,0,0-1.71,3.52,30,30,0,0,0-1.24,3.72,29.45,29.45,0,0,0-.76,3.85,30.12,30.12,0,0,0-.25,3.92Z"
          />
          <path
            className="cls-2"
            d="M15.08,80.86A46.2,46.2,0,0,1,15.46,75a45.4,45.4,0,0,1,1.13-5.78,43.37,43.37,0,0,1,1.87-5.57,44.6,44.6,0,0,1,5.78-10.18A43.77,43.77,0,0,1,28.07,49a44.69,44.69,0,0,1,4.36-3.89,45.33,45.33,0,0,1,4.82-3.27l7.39,13A30.32,30.32,0,0,0,41.42,57a29.63,29.63,0,0,0-2.9,2.59A28.53,28.53,0,0,0,36,62.58a29.73,29.73,0,0,0-2.15,3.27,28.57,28.57,0,0,0-1.71,3.52,30,30,0,0,0-1.24,3.72,29.45,29.45,0,0,0-.76,3.85,30.12,30.12,0,0,0-.25,3.92Z"
          />
          <path
            className="cls-1 stress-ten"
            d="M81.58,119.84a42.82,42.82,0,0,1-5.2,2.61,44,44,0,0,1-11.18,3,43.1,43.1,0,0,1-11.57,0,43.77,43.77,0,0,1-5.69-1.15,44.47,44.47,0,0,1-5.49-1.89,43,43,0,0,1-5.21-2.61l7.39-13a28.83,28.83,0,0,0,3.47,1.74,29.84,29.84,0,0,0,3.66,1.26,28.92,28.92,0,0,0,3.79.77,28.78,28.78,0,0,0,7.72,0,28.59,28.59,0,0,0,3.79-.77,29.27,29.27,0,0,0,3.66-1.26,28.11,28.11,0,0,0,3.47-1.74Z"
          />
          <path
            className="cls-2"
            d="M81.58,119.84a42.82,42.82,0,0,1-5.2,2.61,44,44,0,0,1-11.18,3,43.1,43.1,0,0,1-11.57,0,43.77,43.77,0,0,1-5.69-1.15,44.47,44.47,0,0,1-5.49-1.89,43,43,0,0,1-5.21-2.61l7.39-13a28.83,28.83,0,0,0,3.47,1.74,29.84,29.84,0,0,0,3.66,1.26,28.92,28.92,0,0,0,3.79.77,28.78,28.78,0,0,0,7.72,0,28.59,28.59,0,0,0,3.79-.77,29.27,29.27,0,0,0,3.66-1.26,28.11,28.11,0,0,0,3.47-1.74Z"
          />
          <path
            className="cls-1 stress-nine"
            d="M37.25,119.85a42.52,42.52,0,0,1-4.82-3.27,44.58,44.58,0,0,1-4.36-3.88,46.07,46.07,0,0,1-3.83-4.43,45.45,45.45,0,0,1-3.22-4.9,45.91,45.91,0,0,1-4.43-10.86,45.4,45.4,0,0,1-1.13-5.78,46.16,46.16,0,0,1-.38-5.87H29.86a30.08,30.08,0,0,0,.25,3.91,29.59,29.59,0,0,0,.76,3.86,30.46,30.46,0,0,0,1.24,3.71,28.7,28.7,0,0,0,1.71,3.53A30.58,30.58,0,0,0,36,99.13a27.52,27.52,0,0,0,2.55,3,29.63,29.63,0,0,0,2.9,2.59,28.89,28.89,0,0,0,3.22,2.18Z"
          />
          <path
            className="cls-2"
            d="M37.25,119.85a42.52,42.52,0,0,1-4.82-3.27,44.58,44.58,0,0,1-4.36-3.88,46.07,46.07,0,0,1-3.83-4.43,45.45,45.45,0,0,1-3.22-4.9,45.91,45.91,0,0,1-4.43-10.86,45.4,45.4,0,0,1-1.13-5.78,46.16,46.16,0,0,1-.38-5.87H29.86a30.08,30.08,0,0,0,.25,3.91,29.59,29.59,0,0,0,.76,3.86,30.46,30.46,0,0,0,1.24,3.71,28.7,28.7,0,0,0,1.71,3.53A30.58,30.58,0,0,0,36,99.13a27.52,27.52,0,0,0,2.55,3,29.63,29.63,0,0,0,2.9,2.59,28.89,28.89,0,0,0,3.22,2.18Z"
          />
          <path
            className="cls-1 stress-one"
            d="M29.86,28.86a57.53,57.53,0,0,1,6.93-3.48,59.68,59.68,0,0,1,7.32-2.52,58,58,0,0,1,7.59-1.53,57.49,57.49,0,0,1,15.43,0,57.49,57.49,0,0,1,7.58,1.53,57.8,57.8,0,0,1,14.26,6l-7.39,13a44,44,0,0,0-10.69-4.49,42.48,42.48,0,0,0-5.69-1.15,43.1,43.1,0,0,0-11.57,0,42.48,42.48,0,0,0-5.69,1.15,44.47,44.47,0,0,0-5.49,1.89,43.48,43.48,0,0,0-5.2,2.6Z"
          />
          <path
            className="cls-2"
            d="M29.86,28.86a57.53,57.53,0,0,1,6.93-3.48,59.68,59.68,0,0,1,7.32-2.52,58,58,0,0,1,7.59-1.53,57.49,57.49,0,0,1,15.43,0,57.49,57.49,0,0,1,7.58,1.53,57.8,57.8,0,0,1,14.26,6l-7.39,13a44,44,0,0,0-10.69-4.49,42.48,42.48,0,0,0-5.69-1.15,43.1,43.1,0,0,0-11.57,0,42.48,42.48,0,0,0-5.69,1.15,44.47,44.47,0,0,0-5.49,1.89,43.48,43.48,0,0,0-5.2,2.6Z"
          />
          <path
            className="cls-1 stress-six"
            d="M89,28.86a58.1,58.1,0,0,1,6.43,4.36,59.49,59.49,0,0,1,5.82,5.18,61.13,61.13,0,0,1,5.1,5.9,60.2,60.2,0,0,1,4.29,6.53A60.7,60.7,0,0,1,118,73a62.29,62.29,0,0,1,.5,7.84H103.75a44.43,44.43,0,0,0-.38-5.88,45.85,45.85,0,0,0-3-11.35,44.37,44.37,0,0,0-2.57-5.28,43.09,43.09,0,0,0-3.22-4.9,44.3,44.3,0,0,0-8.18-8.31,46,46,0,0,0-4.82-3.28Z"
          />
          <path
            className="cls-2"
            d="M89,28.86a58.1,58.1,0,0,1,6.43,4.36,59.49,59.49,0,0,1,5.82,5.18,61.13,61.13,0,0,1,5.1,5.9,60.2,60.2,0,0,1,4.29,6.53A60.7,60.7,0,0,1,118,73a62.29,62.29,0,0,1,.5,7.84H103.75a44.43,44.43,0,0,0-.38-5.88,45.85,45.85,0,0,0-3-11.35,44.37,44.37,0,0,0-2.57-5.28,43.09,43.09,0,0,0-3.22-4.9,44.3,44.3,0,0,0-8.18-8.31,46,46,0,0,0-4.82-3.28Z"
          />
          <path
            className="cls-1 stress-five"
            d="M118.53,80.85a60.59,60.59,0,0,1-.51,7.84,59.52,59.52,0,0,1-1.51,7.7,58.86,58.86,0,0,1-2.48,7.43,60.3,60.3,0,0,1-3.42,7.05,59.89,59.89,0,0,1-9.4,12.43A58.83,58.83,0,0,1,89,132.84l-7.39-13a42.52,42.52,0,0,0,4.82-3.27,44.65,44.65,0,0,0,11.41-13.21,44.81,44.81,0,0,0,4.43-10.86,45.25,45.25,0,0,0,1.13-5.77,46.2,46.2,0,0,0,.38-5.88Z"
          />
          <path
            className="cls-2"
            d="M118.53,80.85a60.59,60.59,0,0,1-.51,7.84,59.52,59.52,0,0,1-1.51,7.7,58.86,58.86,0,0,1-2.48,7.43,60.3,60.3,0,0,1-3.42,7.05,59.89,59.89,0,0,1-9.4,12.43A58.83,58.83,0,0,1,89,132.84l-7.39-13a42.52,42.52,0,0,0,4.82-3.27,44.65,44.65,0,0,0,11.41-13.21,44.81,44.81,0,0,0,4.43-10.86,45.25,45.25,0,0,0,1.13-5.77,46.2,46.2,0,0,0,.38-5.88Z"
          />
          <path
            className="cls-1 stress-two"
            d="M.3,80.86a60.65,60.65,0,0,1,7.92-30,58.84,58.84,0,0,1,4.3-6.53,61,61,0,0,1,5.09-5.91,59.39,59.39,0,0,1,5.82-5.17,58.19,58.19,0,0,1,6.43-4.37l7.39,13a45.33,45.33,0,0,0-4.82,3.27A44.69,44.69,0,0,0,28.07,49a43.77,43.77,0,0,0-3.83,4.43A45.32,45.32,0,0,0,21,58.34a46.92,46.92,0,0,0-2.57,5.29,44.92,44.92,0,0,0-1.86,5.57A45.4,45.4,0,0,0,15.46,75a46.2,46.2,0,0,0-.38,5.88Z"
          />
          <path
            className="cls-2"
            d="M.3,80.86a60.65,60.65,0,0,1,7.92-30,58.84,58.84,0,0,1,4.3-6.53,61,61,0,0,1,5.09-5.91,59.39,59.39,0,0,1,5.82-5.17,58.19,58.19,0,0,1,6.43-4.37l7.39,13a45.33,45.33,0,0,0-4.82,3.27A44.69,44.69,0,0,0,28.07,49a43.77,43.77,0,0,0-3.83,4.43A45.32,45.32,0,0,0,21,58.34a46.92,46.92,0,0,0-2.57,5.29,44.92,44.92,0,0,0-1.86,5.57A45.4,45.4,0,0,0,15.46,75a46.2,46.2,0,0,0-.38,5.88Z"
          />
          <path
            className="cls-1 stress-four"
            d="M89,132.85a59,59,0,0,1-6.93,3.47,58.19,58.19,0,0,1-14.91,4.06,58.14,58.14,0,0,1-30.34-4.06,57.46,57.46,0,0,1-6.93-3.47l7.38-13a43.92,43.92,0,0,0,16.39,5.65,44.22,44.22,0,0,0,11.57,0,43.67,43.67,0,0,0,16.38-5.65Z"
          />
          <path
            className="cls-2"
            d="M89,132.85a59,59,0,0,1-6.93,3.47,58.19,58.19,0,0,1-14.91,4.06,58.14,58.14,0,0,1-30.34-4.06,57.46,57.46,0,0,1-6.93-3.47l7.38-13a43.92,43.92,0,0,0,16.39,5.65,44.22,44.22,0,0,0,11.57,0,43.67,43.67,0,0,0,16.38-5.65Z"
          />
          <path
            className="cls-1 stress-three"
            d="M29.86,132.85a59.52,59.52,0,0,1-6.43-4.36,60.83,60.83,0,0,1-5.82-5.18,59.79,59.79,0,0,1-5.09-5.91,57.92,57.92,0,0,1-4.3-6.53A60.82,60.82,0,0,1,2.31,96.4a61.38,61.38,0,0,1-1.5-7.71A60.32,60.32,0,0,1,.3,80.86H15.08a46.16,46.16,0,0,0,.38,5.87,45.77,45.77,0,0,0,3,11.36A46,46,0,0,0,21,103.37a45.45,45.45,0,0,0,3.22,4.9,46.07,46.07,0,0,0,3.83,4.43,44.58,44.58,0,0,0,4.36,3.88,42.52,42.52,0,0,0,4.82,3.27Z"
          />
          <path
            className="cls-2"
            d="M29.86,132.85a59.52,59.52,0,0,1-6.43-4.36,60.83,60.83,0,0,1-5.82-5.18,59.79,59.79,0,0,1-5.09-5.91,57.92,57.92,0,0,1-4.3-6.53A60.82,60.82,0,0,1,2.31,96.4a61.38,61.38,0,0,1-1.5-7.71A60.32,60.32,0,0,1,.3,80.86H15.08a46.16,46.16,0,0,0,.38,5.87,45.77,45.77,0,0,0,3,11.36A46,46,0,0,0,21,103.37a45.45,45.45,0,0,0,3.22,4.9,46.07,46.07,0,0,0,3.83,4.43,44.58,44.58,0,0,0,4.36,3.88,42.52,42.52,0,0,0,4.82,3.27Z"
          />
          <path
            className="cls-1 stress-seventeen"
            d="M74.69,81a15.68,15.68,0,0,1-.29,3,16.09,16.09,0,0,1-.87,2.87,16.73,16.73,0,0,1-1.41,2.64,15.56,15.56,0,0,1-1.9,2.31,15.14,15.14,0,0,1-2.32,1.9,14.54,14.54,0,0,1-2.64,1.41A15.74,15.74,0,0,1,62.4,96a15.46,15.46,0,0,1-6,0,16.09,16.09,0,0,1-2.87-.87,15.23,15.23,0,0,1-2.64-1.41,15.43,15.43,0,0,1-4.21-4.21,15.23,15.23,0,0,1-1.41-2.64A16.09,16.09,0,0,1,44.44,84a15.46,15.46,0,0,1,0-6,15.74,15.74,0,0,1,.87-2.86,14.54,14.54,0,0,1,1.41-2.64,15.14,15.14,0,0,1,1.9-2.32,15.56,15.56,0,0,1,2.31-1.9,16.73,16.73,0,0,1,2.64-1.41A16.09,16.09,0,0,1,56.44,66a15.23,15.23,0,0,1,8.82.87,15.9,15.9,0,0,1,2.64,1.41,15.14,15.14,0,0,1,4.22,4.22A15.19,15.19,0,0,1,74.69,81Z"
          />
          <path
            className="cls-3"
            d="M74.69,81a15.68,15.68,0,0,1-.29,3,16.09,16.09,0,0,1-.87,2.87,16.73,16.73,0,0,1-1.41,2.64,15.56,15.56,0,0,1-1.9,2.31,15.14,15.14,0,0,1-2.32,1.9,14.54,14.54,0,0,1-2.64,1.41A15.74,15.74,0,0,1,62.4,96a15.46,15.46,0,0,1-6,0,16.09,16.09,0,0,1-2.87-.87,15.23,15.23,0,0,1-2.64-1.41,15.43,15.43,0,0,1-4.21-4.21,15.23,15.23,0,0,1-1.41-2.64A16.09,16.09,0,0,1,44.44,84a15.46,15.46,0,0,1,0-6,15.74,15.74,0,0,1,.87-2.86,14.54,14.54,0,0,1,1.41-2.64,15.14,15.14,0,0,1,1.9-2.32,15.56,15.56,0,0,1,2.31-1.9,16.73,16.73,0,0,1,2.64-1.41A16.09,16.09,0,0,1,56.44,66a15.23,15.23,0,0,1,8.82.87,15.9,15.9,0,0,1,2.64,1.41,15.14,15.14,0,0,1,4.22,4.22A15.19,15.19,0,0,1,74.69,81Z"
          />
          <polygon points="60.71 25.91 60.71 30.87 60.08 30.87 60.08 26.69 58.81 27.16 58.81 26.59 60.61 25.91 60.71 25.91" />
          <path d="M16.37,55.1v.52H13.14v-.46l1.71-1.89a5.07,5.07,0,0,0,.52-.7,1.15,1.15,0,0,0,.15-.56.85.85,0,0,0-.24-.63.79.79,0,0,0-.62-.26,1,1,0,0,0-.73.27,1,1,0,0,0-.26.74H13a1.47,1.47,0,0,1,.44-1.1,1.64,1.64,0,0,1,1.18-.42,1.58,1.58,0,0,1,1.09.36,1.25,1.25,0,0,1,.4,1,2.82,2.82,0,0,1-.93,1.74L13.9,55.1Z" />
          <path d="M104.43,52.9a1.07,1.07,0,0,0-.6.19,1,1,0,0,0-.37.49v.23a1.63,1.63,0,0,0,.27,1,.83.83,0,0,0,.69.37.78.78,0,0,0,.66-.31,1.23,1.23,0,0,0,.25-.81,1.34,1.34,0,0,0-.25-.83A.8.8,0,0,0,104.43,52.9Zm.71-2.22h.11v.53h-.11a1.66,1.66,0,0,0-1.17.43,1.76,1.76,0,0,0-.5,1.18,1.35,1.35,0,0,1,1.06-.44,1.28,1.28,0,0,1,1,.45,2,2,0,0,1,0,2.4,1.52,1.52,0,0,1-2.26-.09,2.12,2.12,0,0,1-.44-1.4V53.5a3.25,3.25,0,0,1,.58-2.09A2.12,2.12,0,0,1,105.14,50.68Z" />
          <path d="M62.81,78.08v.35l-2,4.59h-.66l2-4.42H59.48v-.52Zm-5,0v5h-.63V78.84l-1.27.46v-.57l1.8-.67Z" />
          <path d="M95.49,63.13v.52H92.25v-.46L94,61.3a5.32,5.32,0,0,0,.53-.7,1.3,1.3,0,0,0,.14-.57.88.88,0,0,0-.24-.63.82.82,0,0,0-.62-.25,1,1,0,0,0-.73.27,1,1,0,0,0-.26.74h-.62a1.43,1.43,0,0,1,.44-1.1,1.61,1.61,0,0,1,1.17-.42,1.56,1.56,0,0,1,1.09.36,1.22,1.22,0,0,1,.4,1,2.82,2.82,0,0,1-.93,1.74L93,63.13Zm-5.07-4.45v5h-.63V59.46l-1.27.47v-.57l1.8-.68Z" />
          <polygon points="61.33 41.17 61.33 41.52 59.29 46.11 58.63 46.11 60.67 41.69 58 41.69 58 41.17 61.33 41.17" />
          <path d="M27.9,59.15a.81.81,0,0,0-.6.23.85.85,0,0,0-.23.62.86.86,0,0,0,.23.62.9.9,0,0,0,1.2,0,.86.86,0,0,0,.23-.62.82.82,0,0,0-.24-.61A.78.78,0,0,0,27.9,59.15Zm.94,3.15a.91.91,0,0,0-.95-.94.88.88,0,0,0-.93.94.86.86,0,0,0,.25.66,1,1,0,0,0,.69.24,1,1,0,0,0,.69-.24A.86.86,0,0,0,28.84,62.3ZM29.35,60a1.14,1.14,0,0,1-.19.66,1.27,1.27,0,0,1-.52.45,1.54,1.54,0,0,1,.61.49,1.2,1.2,0,0,1,.22.72,1.27,1.27,0,0,1-.43,1,1.64,1.64,0,0,1-1.14.38,1.6,1.6,0,0,1-1.14-.38,1.27,1.27,0,0,1-.43-1,1.2,1.2,0,0,1,.22-.72,1.38,1.38,0,0,1,.6-.49,1.31,1.31,0,0,1-.51-.45,1.14,1.14,0,0,1-.19-.66,1.28,1.28,0,0,1,.4-1,1.49,1.49,0,0,1,1-.36A1.55,1.55,0,0,1,29,59,1.31,1.31,0,0,1,29.35,60Z" />
          <path d="M60.54,59v-.51H61a1.07,1.07,0,0,0,.7-.24.86.86,0,0,0-.61-1.48.88.88,0,0,0-.65.24.81.81,0,0,0-.24.61h-.63a1.26,1.26,0,0,1,.43-1,1.57,1.57,0,0,1,1.09-.39,1.5,1.5,0,0,1,1.09.37,1.29,1.29,0,0,1,.4,1,1.14,1.14,0,0,1-.2.63,1.28,1.28,0,0,1-.57.45,1.3,1.3,0,0,1,.63.42,1.26,1.26,0,0,1,.22.73,1.35,1.35,0,0,1-.44,1.06,1.61,1.61,0,0,1-1.12.39A1.64,1.64,0,0,1,60,60.94a1.24,1.24,0,0,1-.43-1h.63a.79.79,0,0,0,.25.62,1,1,0,0,0,.69.24.84.84,0,0,0,.93-.92.84.84,0,0,0-.26-.66A1.18,1.18,0,0,0,61,59Zm-2.75-2.7v5h-.63V57.07l-1.26.46V57l1.8-.68Z" />
          <path d="M39.43,79.08,38,81.36H39.5V78.94Zm.7-1v3.27h.69v.51h-.69V83H39.5V81.87H37.25V81.5l2.21-3.41Zm-4.48,0v5H35V78.84l-1.27.46v-.56l1.8-.68Z" />
          <path d="M27.8,98.63a1,1,0,0,0,.58-.2,1.19,1.19,0,0,0,.39-.47V97.7a1.71,1.71,0,0,0-.27-1,.8.8,0,0,0-.68-.38.81.81,0,0,0-.67.31,1.35,1.35,0,0,0-.25.84,1.32,1.32,0,0,0,.25.83A.77.77,0,0,0,27.8,98.63Zm1,0a1.6,1.6,0,0,1-.47.38A1.38,1.38,0,0,1,27,98.94a1.43,1.43,0,0,1-.49-.59,2.15,2.15,0,0,1-.17-.85,2,2,0,0,1,.19-.89A1.38,1.38,0,0,1,27,96a1.53,1.53,0,0,1,.81-.21,1.39,1.39,0,0,1,1.15.55,2.38,2.38,0,0,1,.42,1.49V98a3.18,3.18,0,0,1-.57,2.1,2.18,2.18,0,0,1-1.71.68H27v-.53h.14a1.69,1.69,0,0,0,1.19-.4A1.74,1.74,0,0,0,28.76,98.63Z" />
          <path d="M12.5,106.57v-.51H13a1.09,1.09,0,0,0,.7-.24.78.78,0,0,0,.25-.61.77.77,0,0,0-.86-.87.9.9,0,0,0-.65.23.83.83,0,0,0-.24.62h-.63a1.25,1.25,0,0,1,.43-1,1.55,1.55,0,0,1,1.09-.39,1.56,1.56,0,0,1,1.09.37,1.33,1.33,0,0,1,.4,1,1.08,1.08,0,0,1-.21.62,1.37,1.37,0,0,1-.57.46,1.12,1.12,0,0,1,.85,1.15,1.36,1.36,0,0,1-.43,1.06,1.62,1.62,0,0,1-1.13.39,1.68,1.68,0,0,1-1.13-.38,1.24,1.24,0,0,1-.43-1h.63a.8.8,0,0,0,.25.62,1.11,1.11,0,0,0,1.38,0,.93.93,0,0,0,.24-.69.84.84,0,0,0-.27-.66,1.15,1.15,0,0,0-.76-.23Z" />
          <path d="M60.42,102.86l-.5-.14.25-2.45H62.7v.57h-2l-.15,1.35a1.63,1.63,0,0,1,.83-.22,1.36,1.36,0,0,1,1.07.45,1.78,1.78,0,0,1,.39,1.21,1.65,1.65,0,0,1-.41,1.2,1.49,1.49,0,0,1-1.15.44,1.59,1.59,0,0,1-1.07-.36,1.5,1.5,0,0,1-.47-1h.6a.93.93,0,0,0,.3.64.89.89,0,0,0,.64.22.86.86,0,0,0,.69-.3,1.22,1.22,0,0,0,.25-.82,1.14,1.14,0,0,0-.27-.8.92.92,0,0,0-.72-.3,1.07,1.07,0,0,0-.65.18Zm-2.63-2.62v5h-.63V101l-1.26.46v-.56l1.8-.68Z" />
          <path d="M62.1,118.6v-1a2.27,2.27,0,0,0-.23-1.16.78.78,0,0,0-.71-.36.75.75,0,0,0-.7.36,2.29,2.29,0,0,0-.23,1.12v1a2.38,2.38,0,0,0,.23,1.18.77.77,0,0,0,.71.39.76.76,0,0,0,.69-.36A2.3,2.3,0,0,0,62.1,118.6Zm.62-1v.81a2.88,2.88,0,0,1-.38,1.64,1.33,1.33,0,0,1-1.17.54,1.34,1.34,0,0,1-1.17-.53,2.67,2.67,0,0,1-.4-1.56v-.84a2.85,2.85,0,0,1,.38-1.62,1.59,1.59,0,0,1,2.35,0A2.75,2.75,0,0,1,62.72,117.62Zm-4.93-2v5h-.63v-4.18l-1.27.46v-.57l1.8-.67Z" />
          <path d="M60.09,131.12l-1.47,2.29h1.54V131Zm.7-1v3.28h.69v.51h-.69v1.15h-.63v-1.15H57.91v-.37l2.21-3.42Z" />
          <path d="M103.58,106.48l-.51-.13.26-2.46h2.52v.58h-2l-.15,1.35a1.5,1.5,0,0,1,.82-.22,1.35,1.35,0,0,1,1.07.45,2,2,0,0,1,0,2.41,1.73,1.73,0,0,1-2.22.07,1.42,1.42,0,0,1-.47-1h.59a1,1,0,0,0,.3.64,1,1,0,0,0,.65.22.83.83,0,0,0,.68-.3,1.25,1.25,0,0,0,.26-.83,1.12,1.12,0,0,0-.28-.8.94.94,0,0,0-.71-.29,1,1,0,0,0-.65.18Z" />
          <path d="M95.35,95.52v5h-.63V96.3l-1.27.46v-.57l1.8-.67Zm-3.9,0v5h-.63V96.3l-1.27.46v-.57l1.8-.67Z" />
          <path d="M82.88,80.3a1,1,0,0,0-.59.2,1,1,0,0,0-.38.48v.23a1.59,1.59,0,0,0,.28,1,.84.84,0,0,0,1.35.06,1.31,1.31,0,0,0,.24-.82,1.25,1.25,0,0,0-.25-.82A.78.78,0,0,0,82.88,80.3Zm.71-2.22h.11v.53h-.11a1.66,1.66,0,0,0-1.17.43,1.84,1.84,0,0,0-.5,1.19A1.35,1.35,0,0,1,83,79.78a1.24,1.24,0,0,1,1,.46,1.72,1.72,0,0,1,.39,1.17A1.78,1.78,0,0,1,84,82.63a1.43,1.43,0,0,1-1.12.46,1.39,1.39,0,0,1-1.15-.55,2.17,2.17,0,0,1-.44-1.4V80.9a3.22,3.22,0,0,1,.58-2.08A2.11,2.11,0,0,1,83.59,78.08Zm-4.18,0v5h-.63V78.84l-1.27.46v-.57l1.8-.67Z" />
        </g>
      </g>
    </svg>
  );
};
