import { createSlice } from '@reduxjs/toolkit';
import { changeReportStatus } from '../../../rtk-apis/Reports/changeReportStatus';

const initialState = {
  status: '',
  data: '',
  msg: '',
  loading: false,
};

const changeReportStatusSlice = createSlice({
  name: 'changeReportStatusSlice',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.data = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(changeReportStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(changeReportStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.data = action?.payload?.data;
        state.msg = action?.payload?.message;
      })
      .addCase(changeReportStatus.rejected, (state, action) => {
        state.loading = false;
        state.status = 'FAILED';
        state.data = (action?.payload as string) || 'Something went wrong';
      });
  },
});

export const { clearState } = changeReportStatusSlice.actions;
export default changeReportStatusSlice.reducer;
