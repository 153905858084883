import { customStyles } from '../../Utils/utils';
import { Icon } from '@ohif/ui';
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import '../../styled/pages/login.scss';
import { resetPasswordInitialValues, resetPasswordValidationSchema } from './helper/Schema';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordApi } from '../../rtk-apis/auth/resetPassword';
import { Failed, Loader, Success } from '../../components';
import { clearState } from '../../store/slices/Auth/resetPassword';

const ResetPasswordForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const { status, result, msg, loading } = useSelector(
    (state: RootState) => state.resetPasswordResponse
  );

  // State to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);

  // Submit handler
  const handleSubmit = values => {
    values.email = location.state.email;
    console.log(values);
    // Perform your reset password action here
    dispatch(resetPasswordApi(values));
  };

  useEffect(() => {
    if (location.state.email) {
      console.log(location.state);
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (status === 'SUCCESS') {
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    }
    if (status === 'ERROR') {
      setIsFailedModalOpen(true);
    }
    dispatch(clearState());
  }, [status]);

  return (
    <section
      className="login"
      style={customStyles.loginBackground}
    >
      <div className="login__container">
        <div className="login__wrap">
          <div className="branding">
            <div className="branding__logo">
              <Icon name="lock-key-icon" />
            </div>
            <div className="branding__info">
              <h1 className="title title--h2 title--semibold">Reset Password</h1>
              <p className="text">Set a new password</p>
            </div>
          </div>

          {/* Formik form */}
          <Formik
            initialValues={resetPasswordInitialValues}
            validationSchema={resetPasswordValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className="login__form">
                {/* Password Field */}
                <div className="form__group">
                  <div className="form__groupField">
                    <span className="icon">
                      <Icon name="lock-icon" />
                    </span>
                    <Field
                      type={showPassword ? 'text' : 'password'}
                      id="newPassword"
                      name="newPassword"
                      className={`form__input ${touched.newPassword && errors.newPassword ? 'input-error' : ''}`}
                      placeholder="New Password"
                    />
                    <button
                      type="button"
                      className="btn"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Icon name={showPassword ? 'eye-open' : 'eye-close'} />
                    </button>
                  </div>
                  {/* Error message for password */}
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="form__error"
                  />
                </div>

                {/* Confirm Password Field */}
                <div className="form__group">
                  <div className="form__groupField">
                    <span className="icon">
                      <Icon name="lock-icon" />
                    </span>
                    <Field
                      type={showConfirmPassword ? 'text' : 'password'}
                      id="confirmPassword"
                      name="confirmPassword"
                      className={`form__input ${touched.confirmPassword && errors.confirmPassword ? 'input-error' : ''}`}
                      placeholder="Confirm Password"
                    />
                    <button
                      type="button"
                      className="btn"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      <Icon name={showConfirmPassword ? 'eye-open' : 'eye-close'} />
                    </button>
                  </div>
                  {/* Error message for confirm password */}
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="form__error"
                  />
                </div>

                {/* Submit Button */}
                <div className="form__button">
                  <button
                    className="btn btn__primary btn--full"
                    type="submit"
                  >
                    Reset Password
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Loader isLoading={loading} />
      <Success
        isSuccessModalOpen={isSuccessModalOpen}
        handleCloseModal={() => setIsSuccessModalOpen(false)}
        title="Password Reset Successfully!"
      />
      <Failed
        isFailedModalOpen={isFailedModalOpen}
        handleCloseModal={() => setIsFailedModalOpen(false)}
        title="Password Reset Failed!"
      />
    </section>
  );
};

export default ResetPasswordForm;
