import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { capitalizeFirstLetterOfEachWord } from "../Utils/utils";

const DonutChartRace = ({ chartData }) => {
  const options = {
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      margin: [0, 0, 0, 0],
      height: "100%",
    },
    title: {
      text: null,
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.point.name}</b>: ${this.point.y}`;
      },
    },
    plotOptions: {
      pie: {
        innerSize: "60%",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        data: chartData.map(({ label, value, color }) => ({
          name: capitalizeFirstLetterOfEachWord(label),
          y: value,
          color,
        })),
        colorByPoint: true,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <div style={{ width: "100%", height: 300 }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default DonutChartRace;
