import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { REPORTS } from "../../constant"

type Payload = {
  reportId: string;
  hospitalId: string;
};

export const getReportDataById = createAsyncThunk(
  'getReportDataById',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await fetch(`${REPORTS?.GET_REPORT_DATA_BY_ID}?reportId=${payload?.reportId}&hospitalId=${payload?.hospitalId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.msg || 'Get User failed');
      }

      const result = await response.json();

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
