import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Icon, ICONS } from '@ohif/ui'; // Assuming you are using this for icons
import '../../styled/pages/login.scss'; // Make sure you have the required CSS
import { customStyles } from '../../Utils/utils';
import { forgotPasswordInitialValues, forgotPasswordValidationSchema } from './helper/Schema';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordApi } from '../../rtk-apis/auth/forgotPassword';
import { clearState } from '../../store/slices/Auth/forgotPassword';
import { Failed, Loader, Success } from '../../components';

const EmailForm = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { status, result, msg, loading } = useSelector(
    (state: RootState) => state.forgotPasswordResponse
  );
  const [showForgotSuccessModal, setShowForgotSuccessModal] = useState(false);
  const [showForgotFailedModal, setShowForgotFailedModal] = useState(false);

  // Submit handler
  const handleSubmit = values => {
    // Perform your submit action here
    dispatch(forgotPasswordApi(values));
  };

  useEffect(() => {
    if (status === 'SUCCESS') {
      setShowForgotSuccessModal(true);
      setTimeout(() => {
        setShowForgotSuccessModal(false);
        navigate('/otpVerification', {
          state: {
            email: result?.result?.email,
          },
        });
      }, 1500);
    }
    if (status === 'ERROR') {
      setShowForgotFailedModal(true);
    }
    dispatch(clearState());
  }, [status]);

  const handleBack = () => {
    navigate('/login');
  }

  return (
    <>
      <section
        className="login"
        style={customStyles.loginBackground}
      >
        <div className="login__container">
          <div className="login__wrap">
            <div className="branding">
              <div className="branding__logo">
                <img className='w-12 h-12 mb-2' src={`${ICONS.LockIconQuestionMark}`} alt="Reset Icon" />
              </div>
              <div className="branding__info">
                <h1 className="title title--h2 title--semibold">Forget Password</h1>
                <p className="text">
                  Enter your email address where we can <br />
                  send your verification code.
                </p>
              </div>
            </div>

            {/* Formik form */}
            <Formik
              initialValues={forgotPasswordInitialValues}
              validationSchema={forgotPasswordValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form className="login__form">
                  <div className="form__group">
                    <div className="form__groupField">
                      <Icon name="envelope-icon" />
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        className={`form__input ${touched.email && errors.email ? 'input-error' : ''}`}
                        placeholder="Email Address"
                      />
                      {/* Error message */}
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="form__error"
                    />
                  </div>

                  <div className="form__button textCenter">
                    <button
                      className="btn btn__primary btn--full"
                      type="submit"
                    >
                      Submit
                    </button>
                    <div className='flex justify-center items-center text-[#49475A] mt-4 pb-0'>
                      <Icon name='chevron-left' className="text-gray-600" />
                      <button onClick={handleBack}>
                        Back to Login
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Success
          handleCloseModal={() => setShowForgotSuccessModal(false)}
          title="Verification code sent successfully."
          isSuccessModalOpen={showForgotSuccessModal}
        />
        <Failed
          handleCloseModal={() => setShowForgotFailedModal(false)}
          title="Email Id not found"
          isFailedModalOpen={showForgotFailedModal}
        />
      </section>
      <Loader isLoading={loading} />
    </>
  );
};

export default EmailForm;
