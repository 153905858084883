import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { Icon } from '@ohif/ui';
import {
  CustomTable,
  Header,
  Pagination,
  SideMenu,
  Search,
  ReportTemplatePreview,
} from '../../components';
import { customStyles } from '../../Utils/utils';
import { useNavigate } from 'react-router-dom';
import useDebounce from "../../components/useDebounce"
import SortComponent from "../../components/SortComponent";
import Filter from "../../components/Filter";

const PatientList = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const searchQuery = useDebounce(searchTerm, 1000);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectReportTemplateModal, setSelectReportTemplateModal] = useState(false);

  // Fetch data (for demonstration purposes, assume data is an array of objects)
  useEffect(() => {
    // Simulated fetch call
    const fetchData = [
      {
        _id: '1',
        name: 'Parul Madaan',
        dob: '25-09-2023',
        registrationDate: '25-09-2023',
        gender: 'Male',
        patientId: '123456789',
      },
      {
        _id: '2',
        name: 'Kajal Singh',
        dob: '25-09-2023',
        registrationDate: '25-09-2023',
        gender: 'Male',
        patientId: '123456789',
      },
      {
        _id: '3',
        name: 'Naman Kakkar',
        dob: '25-09-2023',
        registrationDate: '25-09-2023',
        gender: 'Male',
        patientId: '123456789',
      },
    ];
    setTableData(fetchData);
    setFilteredData(fetchData);
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredData(tableData);
    }
    // Filter logic
    const filtered = tableData?.filter(item =>
      item?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchTerm, tableData]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

  const columns = [
    { key: 'name', title: 'Patient Name' },
    { key: 'dob', title: 'Date of Birth' },
    { key: 'registrationDate', title: 'Registration Date' },
    { key: 'gender', title: 'Gender' },
    { key: 'patientId', title: 'Patient ID' },
  ];
  const actions = [
    {
      label: 'more',
      iconName: 'more-icon',
      dropdownItems: [
        {
          label: 'Create Template',
          iconName: 'create-template-icon',
          onClick: (item: any) => {
            console.log(item);
          },
        },
      ],
      onClick: (item: any) => {
        setSelectReportTemplateModal(true);
      },
    },
  ];

  const breadcrumbs = [{ label: 'Patients', icon: 'sidebar-icon' }];

  return (
    <>
      <section
        className="layoutContainer"
        style={customStyles.layoutBackground}
      >
        <SideMenu />
        <div className="layoutGrid">
          <div className="page page--fullBody">
            <Header breadcrumbs={breadcrumbs} />
            <div className="page__body">
              <div className="page__content">
                <div className="page__body__head">
                  <h1 className="title title--h6 title--normal">Paitent List</h1>
                </div>
                <div className="tableWrap">
                  <div className="tableWrap__head">
                  {/* Search */}
                  <div className="formRow">
                    <div className="formCol relative">
                      <input
                        className="form__input form__input--search"
                        type="text"
                        placeholder="Search by Name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e?.target?.value)}
                      />
                      <span className="absolute right-4 bottom-3 cursor-pointer">
                        <Icon name="search-icon" />
                      </span>
                    </div>
                  </div>
                  <div className="btn__group">
                    {/* <Filter /> */}
                    <SortComponent
                      searchQuery={searchQuery}
                      sortKey="name"
                      data={tableData}
                      setFilteredData={setFilteredData}
                    />
                  </div>
                </div>
                  <CustomTable
                    actions={actions}
                    columns={columns}
                    tableData={currentItems}
                  />
                </div>
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              itemsPerPage={itemsPerPage}
              totalItems={filteredData?.length}
              onPageChange={setCurrentPage}
              onItemsPerPageChange={setItemsPerPage}
            />
          </div>
        </div>
      </section>
      <ReportTemplatePreview
        isOpen={selectReportTemplateModal}
        closeModal={() => {
          setSelectReportTemplateModal(false);
        }}
      />
    </>
  );
};

export default PatientList;
