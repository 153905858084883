import { createSlice } from '@reduxjs/toolkit';
import { addClinicApi } from '../../../rtk-apis/clinics/addClinic';

const initialState = {
  status: '',
  result: '',
  msg: '',
  loading: false,
};

const addClinicSlice = createSlice({
  name: 'addClinic',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(addClinicApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addClinicApi.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
        state.msg = action?.payload?.msg || 'Clinic added successfully.';
      })
      .addCase(addClinicApi.rejected, (state, { payload }) => {
        console.log('payload', payload);
        state.loading = false;
        state.status = 'FAILED';
        state.msg = (payload as string) ?? 'Failed';
      });
  },
});

export const { clearState } = addClinicSlice.actions;
export default addClinicSlice.reducer;
