import React, { useEffect, useState } from 'react';
import { SideMenuFooterItems, sideMenuBasedOnRole } from '../Utils/utils';
import '../styled/pages/dashboard.scss';
import { Icon } from '@ohif/ui';
import { useNavigate } from 'react-router-dom';
import { getRole } from '../Utils/authUtils';

const SideMenu = () => {
  const navigate = useNavigate();
  const [sideMenuItems, setSideMenuItems] = useState([]);
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string>('');

  useEffect(() => {
    const role = getRole();
    setUserRole(role);
    setSideMenuItems(sideMenuBasedOnRole(role));
  }, []);

  const Logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    localStorage.removeItem('hospitalId');
    localStorage.removeItem('hospitalName');

    sessionStorage.removeItem('token');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('hospitalId');
    sessionStorage.removeItem('hospitalName');
    navigate('/login');
  };

  const toggleSubMenu = (title: string) => {
    setOpenSubMenu(openSubMenu === title ? null : title);
  };

  return (
    <div className="layoutSidemenu">
      <div className="layoutSidemenu__logo">
        <div className="layoutSidemenu__logo__img">
          <Icon name="sidemenu-logo" />
        </div>
        <button
          className="btnCollapse"
          id="sidebarCollapse"
        >
          <Icon name="sidebar-chevron-left" />
        </button>
      </div>
      <div className="layoutSidemenu__fullHeight">
        <ul className="layoutSidemenu__list">
          {sideMenuItems?.map((item, index) => (
            <li
              key={index}
              className={`layoutSidemenu__list--item ${window.location.pathname === item.path ? 'layoutSidemenu__selected' : ''}`}
            >
              <button
                className="layoutSidemenu__list--link"
                onClick={() => {
                  navigate(item.path);
                }}
              >
                <span className="icon">
                  <Icon name={item.icon} />
                </span>
                <span>{item.title}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="layoutSidemenu__footer">
        <ul className="layoutSidemenu__list">
          {SideMenuFooterItems.map((item, index) => (
            <React.Fragment key={index}>
              {!(item.title === 'Settings' && userRole === 'SUPER_ADMIN') && (
                <li
                  className={`layoutSidemenu__list--item ${window.location.pathname.includes(item.path || '') ? 'layoutSidemenu__selected' : ''}`}
                >
                  <button
                    className="layoutSidemenu__list--link"
                    onClick={() => {
                      if (item.title === 'Logout Account') {
                        Logout();
                      } else if (item.title === 'Settings') {
                        if (userRole === 'Admin') {
                          toggleSubMenu(item.title);
                        } else {
                          navigate(item.path || '');
                        }
                      } else if (item.subMenu) {
                        toggleSubMenu(item.title);
                      } else {
                        navigate(item.path || '');
                      }
                    }}
                  >
                    <span className="icon">
                      <Icon name={item.icon} />
                    </span>
                    <span>{item.title}</span>
                    {item.subMenu && item.title === 'Settings' && userRole === 'Admin' && (
                      <span className={`submenu-indicator ${openSubMenu === item.title ? 'open' : ''}`}>
                        <Icon name="chevron-down" />
                      </span>
                    )}
                  </button>
                </li>
              )}
              {item.title === 'Settings' && item.subMenu && openSubMenu === item.title && userRole === 'Admin' && (
                <ul className="layoutSidemenu__submenu">
                  {item.subMenu.map((subItem, subIndex) => (
                    <li
                      key={subIndex}
                      className={`layoutSidemenu__submenu--item ${window.location.pathname === subItem.redirectTo ? 'layoutSidemenu__selected' : ''}`}
                    >
                      <button
                        className="layoutSidemenu__submenu--link"
                        onClick={() => navigate(subItem.redirectTo)}
                      >
                        <span className="submenu-bullet"></span>
                        <span style={{ color: '#ffffff80', fontFamily: 'sans-serif', marginBottom: '2px', fontSize: '14px' }}>{subItem.routeName}</span>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
