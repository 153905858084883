import { createSlice } from '@reduxjs/toolkit';
import { getCurrentReportApi } from '../../../rtk-apis/reports/getCurrentReport';

const initialState = {
  status: '',
  result: '',
  msg: '',
  loading: false,
};

const getCurrentReportSlice = createSlice({
  name: 'getCurrentReport',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCurrentReportApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCurrentReportApi.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
        state.msg = action?.payload?.msg;
      })
      .addCase(getCurrentReportApi.rejected, (state, action) => {
        state.loading = false;
        state.status = 'ERROR';
        state.result = action?.payload || '';
        state.msg = action?.error?.message as string;
      });
  },
});

export const { clearState } = getCurrentReportSlice.actions;
export default getCurrentReportSlice.reducer;
