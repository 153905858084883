import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { REPORTS } from '../../constant';

export const fetchDoctorsToAssign = createAsyncThunk(
  "fetchDoctorsToAssign",
  async (payload: any, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await fetch(`${REPORTS.FETCH_DOCTORS_TO_ASSIGN}?hospitalId=${payload?.hospitalId}&reportId=${payload?.reportId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.msg || 'Get fetchDoctorsToassign failed');
      }

      const result = await response.json();

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
