import { createSlice } from '@reduxjs/toolkit';
import { deletePacsApi } from '../../../rtk-apis/pacs/deletePacs';

const initialState = {
  status: '',
  result: null,
  msg: '',
  loading: false,
  error: '',
};

const deletePacsApiSlice = createSlice({
  name: 'deletePacsApiSlice',
  initialState,
  reducers: {
    closeModal(state: any) {
      state.status = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(deletePacsApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deletePacsApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = payload.status;
        state.result = payload.result;
        state.msg = payload.msg;
      })
      .addCase(deletePacsApi.rejected, (state, { payload }) => {
        state.result = payload;
        state.loading = false;
      });
  },
});

export default deletePacsApiSlice.reducer;
export const { closeModal } = deletePacsApiSlice.actions;
