import { createSlice } from '@reduxjs/toolkit';
import { fetchDoctorsToAssign } from '../../../rtk-apis/Reports/fetchDoctorsToAssign';

const initialState = {
  status: '',
  result: '',
  msg: '',
  loading: false,
};

const fetchDoctorsToAssignSlice = createSlice({
  name: 'fetchDoctorsToAssignSlice',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDoctorsToAssign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchDoctorsToAssign.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
        state.msg = action?.payload?.msg;
      })
      .addCase(fetchDoctorsToAssign.rejected, (state, action) => {
        state.loading = false;
        state.status = 'ERROR';
        state.result = action?.payload || '';
        state.msg = action?.error?.message as string;
      });
  },
});

export const { clearState } = fetchDoctorsToAssignSlice.actions;
export default fetchDoctorsToAssignSlice.reducer;
