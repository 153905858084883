import { PACS_URL } from '../../constant';
import { calculateAge } from '../../Utils/utils';
import { DicomMetadataStore } from '@ohif/core';
import formatPN from '../../../../platform/core/src/utils/formatPN';
import formatContentItemValue from '../../../../extensions/cornerstone-dicom-sr/src/utils/formatContentItem';

export const fetchPatientDetails = async (studyInstanceUID: string) => {
  try {
    const patientStudyDetails = await fetch(
      `${PACS_URL}/dcm4chee-arc/aets/DCM4CHEE/rs/studies/${studyInstanceUID}/series?limit=21&includefield=all&offset=0&orderby=SeriesNumber`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!patientStudyDetails.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await patientStudyDetails.json();

    return data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const extractSeriesInstanceUID = data => {
  if (!data) return null;

  const result = data?.filter(item => item['00080060']?.Value?.[0] === 'SR');
  return result.length > 0 ? result[0]['0020000E']?.Value?.[0] : null; // 0020000E is the SeriesInstanceUID tag
};

export const extractPatientDemographics = data => {
  if (!data) return {};

  const patientName = data?.map(item => item['00100010']?.Value?.[0])[0]?.Alphabetic || '';
  const patientGender = data?.map(item => item['00100040']?.Value?.[0])[0] || '';
  const patientID = data?.map(item => item['00100020']?.Value?.[0])[0] || '';
  const patientDOB = data?.map(item => item['00100030']?.Value?.[0])[0] || '';
  const formattedDOB = patientDOB
    ? `${patientDOB.substring(6, 8)}/${patientDOB.substring(4, 6)}/${patientDOB.substring(0, 4)}`
    : '';
  const patientAge = patientDOB ? calculateAge(patientDOB) : '';
  const SNNumber = data?.map(item => item['00080050']?.Value?.[0])[0] || '';
  const weight = data?.map(item => item['00101030']?.Value?.[0])[0] || '';
  const height = data?.map(item => item['00101020']?.Value?.[0])[0] || '';
  const referringPhysicianName =
    data?.map(item => item['00080090']?.Value?.[0])[0]?.Alphabetic || '';
  const gender = patientGender === 'M' ? 'Male' : patientGender === 'F' ? 'Female' : 'Other';

  const currentDate = formatDate(new Date());
  const fullName = formatPN(patientName);
  const [lastName, firstName] = fullName.split(',').map(part =>
    part
      .trim()
      .toLowerCase()
      .replace(/^\w/, c => c.toUpperCase())
  );

  return {
    patientName: fullName,
    firstName,
    lastName,
    gender,
    patientID,
    weight,
    height,
    sex: gender,
    date: currentDate,
    referred_by: referringPhysicianName,
    dob: formattedDOB,
    age: patientAge,
    snNumber: SNNumber,
  };
};

export function extractContentItemData(contentItem, nodeIndexesTree, continuityOfContent) {
  const { ConceptNameCodeSequence } = contentItem;
  const { CodeValue, CodeMeaning } = ConceptNameCodeSequence ?? {};
  const formattedValue = formatContentItemValue(contentItem) || '[empty]';

  return {
    codeValue: CodeValue,
    codeMeaning: CodeMeaning,
    value: formattedValue,
    nodeIndexesTree: nodeIndexesTree.join('.'),
    isContinuous: continuityOfContent === 'CONTINUOUS',
  };
}

export function convertMeasurementsToHTML(container) {
  if (!container || !container.contentItems) return '';

  const { contentItems } = container;

  // Extract findings based on "Finding Site" and measurements
  const findings = extractFindings(contentItems);

  let html = '<table data-class="measurement-table">';

  const headers = Object.keys(findings);
  const numHeaders = headers.length;

  // Loop through headers and render them with corresponding data
  for (let i = 0; i < numHeaders; i += 4) {
    // Render headers (4 at a time)
    html += '<thead><tr>';
    for (let j = i; j < i + 4; j++) {
      // If there are fewer than 4 headers left, add empty columns
      html += `<th class="section-title"><strong>${headers[j] || ''}</strong></th>`;
    }
    html += '</tr></thead>';

    // Render the corresponding data below each header in the same row
    html += '<tbody><tr>';
    for (let j = i; j < i + 4; j++) {
      const headerKey = headers[j];
      const measurements = findings[headerKey];

      // Ensure data is under the correct header
      if (measurements) {
        html += `<td class="sub-section">${renderMeasurements(measurements)}</td>`;
      } else {
        html += '<td class="sub-section"></td>'; // Add empty cell if no data
      }
    }
    html += '</tr></tbody>';
  }

  html += '</table>';

  return html;
}

export function getSeriesMetadata(StudyInstanceUID, SeriesInstanceUID) {
  // Retrieve the series metadata using StudyInstanceUID and SeriesInstanceUID
  const seriesMetadata = DicomMetadataStore.getSeries(StudyInstanceUID, SeriesInstanceUID);
  if (!seriesMetadata) {
    console.warn(
      `No series metadata found for StudyInstanceUID: ${StudyInstanceUID} and SeriesInstanceUID: ${SeriesInstanceUID}`
    );
    return null;
  }
  return seriesMetadata;
}

// Helper function to extract findings and group them by "Finding Site"
function extractFindings(contentItems) {
  console.log('🚀 ~ extractFindings ~ contentItems:', contentItems);
  const findings = {};

  contentItems.forEach(item => {
    const { codeMeaning, contentItems: nestedItems } = item;

    // Look for the "Finding Site" and its related measurements
    if (codeMeaning === 'Findings' && Array.isArray(nestedItems)) {
      let currentSite = '';

      nestedItems.forEach(subItem => {
        // Identify the Finding Site header
        if (subItem.codeMeaning === 'Finding Site') {
          currentSite = subItem.value;
          findings[currentSite] = [];
        }

        // Extract measurements from Measurement Groups
        if (subItem.codeMeaning === 'Measurement Group' && subItem.contentItems) {
          const measurements = extractMeasurements(subItem.contentItems);
          if (currentSite && measurements.length > 0) {
            findings[currentSite].push(...measurements);
          }
        }
      });
    }
  });

  return findings;
}

// Helper function to extract unique key-value pairs from Measurement Groups
function extractMeasurements(contentItems) {
  console.log('🚀 ~ extractMeasurements ~ contentItems:', contentItems);
  const uniqueMeasurements = new Map();

  contentItems.forEach(item => {
    const { codeMeaning, codeValue, value } = item;
    if (codeMeaning && value) {
      const key = `${codeMeaning} (${codeValue})`;
      const uniqueKey = `${key}:${value}`; // Combine key and value to check uniqueness

      if (!uniqueMeasurements.has(uniqueKey)) {
        uniqueMeasurements.set(uniqueKey, { key, value });
      }
    }
  });

  // Convert the unique measurements back to an array
  return Array.from(uniqueMeasurements.values());
}

// Helper function to render measurements in key-value format
function renderMeasurements(measurements) {
  if (!measurements || measurements.length === 0) {
    return '<p>[No measurements available]</p>';
  }

  let html = '';
  measurements.forEach(({ key, value }) => {
    if (!key.includes('Image Mode'))
      html += `<strong>${formatedLabel(key)}:</strong> ${formatValue(value)}<br>`;
  });

  return html;
}

export const formatedLabel = key => {
  const result = key
    .replace(/\s*\(.*$/, '') // Remove everything after the '('
    .split(' ') // Split the string into words
    .map(word => word.charAt(0)) // Extract the first letter of each word
    .join(''); // Join the first letters together

  return result;
};

const formatValue = valueWithUnit => {
  // Match the value and the unit using a regular expression
  const match = valueWithUnit.match(/^(\d+(\.\d+)?)\s*(.*)$/);

  if (match) {
    // Format the numeric part to 2 decimal places
    const value = parseFloat(match[1]).toFixed(2);
    const unit = match[3].trim(); // Capture the full unit, including any special characters

    // Return the formatted value with the unit intact
    return `${value} ${unit}`;
  }

  // If no match, return the original string
  return valueWithUnit;
};

const formatDate = date => {
  const day = String(date.getDate()).padStart(2, '0'); // Get day with leading zero
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed, so +1) with leading zero
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

// Example usage:
// console.log(formatValue("106.77472506295 ml")); // "106.77 ml"
// console.log(formatValue("123.4567 g"));        // "123.46 g"
// console.log(formatValue("45.3 cm2"));          // "45.30 cm2"
// console.log(formatValue("789 c/m"));           // "789.00 c/m"
