import React, { useState, useEffect } from 'react';
import { Icon } from '@ohif/ui';
import '../styled/pages/dashboard.scss';

const SortComponent = ({
  data,
  setFilteredData,
  sortKey,
  searchQuery
}) => {

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [localData, setLocalData] = useState([...data]);

  // Update localData whenever the original data changes
  useEffect(() => {
    setLocalData([...data]);
    handleFilteringAndSorting();
  }, [data]);

  // Filter and sort data whenever searchQuery or sortOrder changes
  useEffect(() => {
    handleFilteringAndSorting();
  }, [searchQuery, sortOrder]);

  // Filter and sort logic combined
  const handleFilteringAndSorting = () => {
    let filteredData = localData.filter(
      item =>
        item?.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        item?.email?.toLowerCase().includes(searchQuery?.toLowerCase())
    );

    filteredData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) return sortOrder === 'asc' ? -1 : 1;
      if (a[sortKey] > b[sortKey]) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    // Update the parent component with the filtered and sorted data
    setFilteredData(filteredData);
  };

  // Toggle sorting order when sort button is clicked
  const toggleSortOrder = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };
  return (
    <>
      <button
        className="btn p_0 border_0"
        onClick={toggleSortOrder}
      >
        <Icon name="sort-icon" />
      </button>
    </>

  )
}

export default SortComponent
