import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH } from '../../constant';

interface Payload {
  email: string;
}

export const forgotPasswordApi = createAsyncThunk(
  'Forgot Password',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      };
      let res = await fetch(AUTH.FORGOT_PASSWORD_URL, requestOptions);

      // Check if the response is not okay (status is 400 or other errors)
      if (!res.ok) {
        const errorData = await res.json(); // Extract error message from the res
        return rejectWithValue(errorData.message || 'Forgot Password failed');
      }

      const data = await res.json();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
