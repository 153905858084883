import { createSlice } from '@reduxjs/toolkit';
import { getDashboardData } from '../../../rtk-apis/dashboard/getDashboardData';

const initialState = {
  status: '',
  result: null,
  msg: '',
  loading: false,
  error: '',
};

const getDashboardDataSlice = createSlice({
  name: 'getDashboardData',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = null;
      state.msg = '';
      state.loading = false;
      state.error = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getDashboardData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
        state.msg = action?.payload?.msg;
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.status = 'ERROR';
        state.result = null;
        state.msg = (action?.payload as string) || 'Something went wrong!';
      });
  },
});

export default getDashboardDataSlice.reducer;
export const { clearState } = getDashboardDataSlice.actions;
