import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { Icon } from '@ohif/ui';
import {
  CustomTable,
  Header,
  Pagination,
  SideMenu,
  ReportTemplatePreview,
  Loader,
  Success,
  Failed,
} from '../../components';
import { customStyles } from '../../Utils/utils';
import { useNavigate } from 'react-router-dom';
import { getHospitalReportsList } from '../../rtk-apis/Reports/getHopsitalReportsList';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../components/useDebounce';
import ReportFilter from '../../components/ReportFilter';
import SortComponent from '../../components/SortComponent';
import { changeReportStatus } from '../../rtk-apis/Reports/changeReportStatus';
import { clearState } from '../../store/slices/Reports/changeReportStatus';

const ReportList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { status, data, loading } = useSelector(
    (state: RootState) => state.getHospitalReportsListResponse
  );

  const [tableData, setTableData] = useState([]);
  const [headerDetails, setHeaderDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [totalPages, setTotalPages] = useState(null);
  const [selectReportTemplateModal, setSelectReportTemplateModal] = useState(false);
  const [onAssigningReport, setOnAssigningReport] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const hospitalid = sessionStorage.getItem('hospitalId');
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const searchQuery = useDebounce(searchTerm, 1000);
  const breadcrumbs = [{ label: 'Reports', icon: 'sidebar-icon' }];

  const [ReportTypeFilterValue, setReportTypeFilterValue] = useState('');
  const [ReportStatusFilterValue, setReportStatusFilterValue] = useState('');
  const [EndDateFilerValue, setEndDateFilerValue] = useState('');
  const [StartDateFilerValue, setStartDateFilerValue] = useState('');
  const [ReportAssignedToDoc, setReportAssignedToDoc] = useState('');
  const [FilterApplied, setFilterApplied] = useState(false);
  const [ClearFilter, setClearFilter] = useState(false);

  const [displayMessage, setDisplayMessage] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);

  const {
    status: changeReportStatusStatus,
    data: changeReportStatusData,
    msg,
    loading: changeReportStatusLoading,
  } = useSelector((state: RootState) => state.changeReportStatusResponse);

  const columns = [
    { key: 'name', title: 'Patient Name' },
    { key: 'assignTo', title: 'Assign To' },
    { key: 'date', title: 'Date' },
    { key: 'reportName', title: 'Report Type' },
    { key: 'status', title: 'Report Status' },
  ];

  const finalizeReport = (reportId, reportStatus) => {
    const payload = {
      reportId,
      reportStatus,
    };
    dispatch(changeReportStatus(payload));
  };

  useEffect(() => {
    const handleSuccess = message => {
      setDisplayMessage(message);
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        setIsSuccessModalOpen(false);
        setDisplayMessage('');
        getReportsList();
      }, 1500);
    };

    const handleFailure = message => {
      setDisplayMessage(message);
      setIsFailedModalOpen(true);
      setTimeout(() => {
        setIsFailedModalOpen(false);
        setDisplayMessage('');
      }, 1500);
    };
    if (changeReportStatusStatus === 'SUCCESS') {
      console.log('SUCCESS');
      handleSuccess(msg);
    }

    if (changeReportStatusStatus === 'FAILED') {
      handleFailure(msg);
    }

    return () => {
      dispatch(clearState());
    };
  }, [changeReportStatusStatus]);

  const actions = [
    {
      label: 'more',
      iconName: 'more-icon',
      dropdownItems: [
        {
          id: 'Awaiting Review',
          label: 'Awaiting Review',
          iconName: 'report-assign',
          onClick: item => {
            finalizeReport(item._id, 'Awaiting Review');
            console.log('Awaiting Review:', item);
          },
        },
        {
          id: 'Unreported',
          label: 'Unreported',
          iconName: 'report-assign',
          onClick: item => {
            finalizeReport(item._id, 'Unreported');
          },
        },
        {
          id: 'Finalized',
          label: 'Finalize Report',
          iconName: 'report-assign',
          onClick: item => {
            finalizeReport(item._id, 'Finalized');
            console.log('Finalize Report:', item);
          },
        },
        {
          id: 'Pending',
          label: 'Pending',
          iconName: 'report-assign',
          onClick: item => {
            finalizeReport(item._id, 'Pending');
          },
        },
        {
          id: 'view-report',
          label: 'View Report',
          iconName: 'report-preview',
          onClick: item => {
            navigate('/patientReports', {
              state: {
                reportId: item?._id,
              },
            });
          },
        },
      ],
      onClick: () => {
        setSelectReportTemplateModal(true);
      },
    },
  ];

  useEffect(() => {
    getReportsList();
  }, [currentPage, searchTerm, onAssigningReport, FilterApplied, ClearFilter]);

  useEffect(() => {
    if (status === 'SUCCESS' && data?.data?.length > 0) {
      setHeaderDetails({
        hospitalHeader: data?.hospitalHeader,
        hospitalLogo: data?.hospitalLogo,
      });
      setTableData(data?.data);
      setFilteredData(data?.data);
    } else {
      setTableData([]);
      setFilteredData([]);
    }
  }, [status, data]);

  const getReportsList = async () => {
    if (hospitalid) {
      const payload = {
        page: currentPage,
        limit: itemsPerPage,
        search: searchTerm,
        hospitalId: hospitalid,
        reportAssignedTo: ReportAssignedToDoc,
        startDate: StartDateFilerValue,
        endDate: EndDateFilerValue,
        reportStatus: ReportStatusFilterValue,
        reportType: ReportTypeFilterValue,
      };
      await dispatch(getHospitalReportsList(payload));
      setFilterApplied(false);
    }
  };

  useEffect(() => {
    const filtered = searchTerm
      ? tableData.filter(item => item?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
      : tableData;
    setFilteredData(filtered);
  }, [searchTerm, tableData]);

  useEffect(() => {
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    setTotalPages(totalPages);
    setCurrentItems(filteredData.slice(indexOfFirstItem, indexOfLastItem));
  }, [filteredData, currentPage, itemsPerPage]);

  return (
    <>
      <section
        className="layoutContainer"
        style={customStyles.layoutBackground}
      >
        <SideMenu />
        <div className="layoutGrid">
          <div className="page page--fullBody">
            <Header breadcrumbs={breadcrumbs} />
            <div className="page__body">
              <div className="page__content">
                <div className="page__body__head">
                  <h1 className="title title--h6 title--normal">Report List</h1>
                </div>
                <div className="tableWrap">
                  <div className="tableWrap__head">
                    {/* Search */}
                    <div className="formRow">
                      <div className="formCol relative">
                        <input
                          className="form__input form__input--search"
                          type="text"
                          placeholder="Search by Name"
                          value={searchTerm}
                          onChange={e => setSearchTerm(e?.target?.value)}
                        />
                        <span className="absolute right-4 bottom-3 cursor-pointer">
                          <Icon name="search-icon" />
                        </span>
                      </div>
                    </div>
                    <div className="btn__group">
                      {/* FILTER */}
                      <ReportFilter
                        setReportTypeFilterValue={setReportTypeFilterValue}
                        setReportStatusFilterValue={setReportStatusFilterValue}
                        setEndDateFilerValue={setEndDateFilerValue}
                        setStartDateFilerValue={setStartDateFilerValue}
                        setReportAssignedToDoc={setReportAssignedToDoc}
                        setFilterApplied={setFilterApplied}
                        ReportAssignedToDoc={ReportAssignedToDoc}
                        StartDateFilerValue={StartDateFilerValue}
                        ReportTypeFilterValue={ReportTypeFilterValue}
                        ReportStatusFilterValue={ReportStatusFilterValue}
                        EndDateFilerValue={EndDateFilerValue}
                        setClearFilter={setClearFilter}
                      />
                      {/* SORTING */}
                      <SortComponent
                        data={tableData}
                        setFilteredData={setFilteredData}
                        sortKey="name"
                        searchQuery={searchQuery}
                      />
                    </div>
                  </div>
                  <CustomTable
                    actions={actions}
                    columns={columns}
                    tableData={currentItems}
                    setOnAssigningReport={setOnAssigningReport}
                    from="reports"
                  />
                </div>
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              itemsPerPage={itemsPerPage}
              totalItems={filteredData.length}
              onPageChange={setCurrentPage}
              onItemsPerPageChange={setItemsPerPage}
            />
          </div>
        </div>
      </section>
      <ReportTemplatePreview
        isOpen={selectReportTemplateModal}
        closeModal={() => {
          setSelectReportTemplateModal(false);
        }}
      />
      <Success
        isSuccessModalOpen={isSuccessModalOpen}
        title={displayMessage}
        handleCloseModal={() => setIsSuccessModalOpen(false)}
      />
      <Failed
        isFailedModalOpen={isFailedModalOpen}
        title={displayMessage}
        handleCloseModal={() => setIsFailedModalOpen(false)}
      />
      <Loader isLoading={loading || changeReportStatusLoading} />
    </>
  );
};

export default ReportList;
