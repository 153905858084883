import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { Icon } from '@ohif/ui';
import {
  CustomTable,
  Header,
  Pagination,
  SideMenu,
  Search,
  Loader,
  ConfirmationModal,
  Success,
  Failed,
} from '../../components';
import { capitalizeFirstLetterOfEachWord, customStyles } from '../../Utils/utils';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getUserApi } from '../../rtk-apis/users/getUser';
import { deleteUserApi } from '../../rtk-apis/users/deleteUser';
import { clearState } from '../../store/slices/users/getUser';
import { clearState as clearDeleteState } from '../../store/slices/users/deleteUser';
import useDebounce from '../../components/useDebounce';
import { getHospitalId, getRole } from '../../Utils/authUtils';
import SortComponent from '../../components/SortComponent';
import Filter from '../../components/Filter';

const UserList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const role = getRole();
  const { status, result, msg, loading } = useSelector((state: RootState) => state.getUserResponse);
  const deleteUserResponse = useSelector((state: RootState) => state.deleteUserResponse);

  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const searchQuery = useDebounce(searchTerm, 1000);
  const [selectedRow, setSelectedRow] = useState(null);
  const [displayMessage, setDisplayMessage] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [columns, setColumns] = useState([
    { key: 'name', title: 'Name' },
    { key: 'role', title: 'Role' },
    { key: 'clinicName', title: 'Clinic Name' },
    { key: 'email', title: 'Email Address' },
    { key: 'contactDetails', title: 'Contact Details' },
  ]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

  const breadcrumbs = [{ label: 'Manage Users', icon: 'sidebar-icon' }];
  const hospitalid = role !== 'SUPER_ADMIN' ? getHospitalId() : '';

  const actions = [
    {
      label: 'Edit',
      iconName: 'edit-icon',
      onClick: (item: any) => {
        navigate(`/updateuser/${item?._id}`, { state: { userDetails: item } });
      },
    },
    {
      label: 'Delete',
      iconName: 'delete-icon',
      onClick: (item: any) => {
        setSelectedRow(item);
        setIsConfirmationModalOpen(true);
      },
    },
  ];

  // ************************ Use-Effects ****************************************
  useEffect(() => {
    const payload = {
      page: currentPage,
      limit: 30,
      search: searchQuery,
      hospitalId: hospitalid,
    };
    dispatch(getUserApi(payload));
  }, [searchQuery, currentPage, deleteUserResponse?.status]);

  useEffect(() => {
    if (role === 'Admin') {
      setColumns([
        { key: 'name', title: 'Name' },
        { key: 'role', title: 'Role' },
        { key: 'email', title: 'Email Address' },
        { key: 'contactDetails', title: 'Contact Details' },
      ]);
    }
    if (status === 'SUCCESS') {
      const modifiedResponse = result?.data?.map(item => {
        const clinicName = capitalizeFirstLetterOfEachWord(item?.clinicName);
        const contactDetails = item?.countryCode + ' ' + item?.contactDetails;
        return {
          ...item,
          contactDetails,
          clinicName,
        };
      });
      setTableData(modifiedResponse);
      setFilteredData(modifiedResponse);
    }
    if (status === 'FAILED') {
      console.log(msg);
      setTableData([]);
      setFilteredData([]);
    }
    dispatch(clearState());
  }, [status]);

  useEffect(() => {
    if (deleteUserResponse?.status === 'SUCCESS') {
      setDisplayMessage(deleteUserResponse?.msg);
      setIsSuccessModalOpen(true);
    }
    if (deleteUserResponse?.status === 'ERROR') {
      setDisplayMessage(deleteUserResponse?.msg);
      setIsFailedModalOpen(true);
    }
    dispatch(clearDeleteState());
  }, [deleteUserResponse.status]);

  useEffect(() => {
    const filtered = tableData?.filter(item =>
      item?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchTerm, tableData]);

  const handleDeleteClinic = () => {
    const payload = {
      _id: selectedRow?._id,
      _rev: selectedRow?._rev,
    };
    dispatch(deleteUserApi(payload));
    setSelectedRow(null);
  };

  return (
    <section
      className="layoutContainer"
      style={customStyles.layoutBackground}
    >
      <SideMenu />
      <div className="layoutGrid">
        <div className="page page--fullBody">
          <Header breadcrumbs={breadcrumbs} />
          <div className="page__body">
            <div className="page__content">
              <div className="page__body__head">
                <h1 className="title title--h6 title--normal">User List</h1>
                <div className="btn__group">
                  <button
                    type="button"
                    className="btn btn__primary"
                    onClick={() => {
                      navigate('/adduser');
                    }}
                  >
                    <Icon name="add-icon" />
                    Add User
                  </button>
                </div>
              </div>
              <div className="tableWrap">
                <div className="tableWrap__head">
                  {/* Search */}
                  <div className="formRow">
                    <div className="formCol relative">
                      <input
                        className="form__input form__input--search"
                        type="text"
                        placeholder="Search by Name"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e?.target?.value)}
                      />
                      <span className="absolute right-4 bottom-3 cursor-pointer">
                        <Icon name="search-icon" />
                      </span>
                    </div>
                  </div>
                  <div className="btn__group">
                    {/* <Filter /> */}
                    <SortComponent
                      searchQuery={searchQuery}
                      sortKey="name"
                      data={tableData}
                      setFilteredData={setFilteredData}
                    />
                  </div>
                </div>
                <CustomTable
                  actions={actions}
                  columns={columns}
                  tableData={currentItems}
                />
              </div>
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            itemsPerPage={itemsPerPage}
            totalItems={filteredData?.length}
            onPageChange={setCurrentPage}
            onItemsPerPageChange={setItemsPerPage}
          />
        </div>
      </div>
      <Loader isLoading={loading || deleteUserResponse.loading} />
      <ConfirmationModal
        title="Delete User"
        description="Are you sure you want to delete this user?"
        isConfirmationModalOpen={isConfirmationModalOpen}
        handleCloseModal={() => setIsConfirmationModalOpen(false)}
        handleConfirm={() => {
          setIsConfirmationModalOpen(false);
          handleDeleteClinic();
        }}
        cancelActionText="Cancel"
        confirmActionText="Yes"
      />
      <Success
        title={displayMessage}
        isSuccessModalOpen={isSuccessModalOpen}
        handleCloseModal={() => setIsSuccessModalOpen(false)}
      />
      <Failed
        title={displayMessage}
        isFailedModalOpen={isFailedModalOpen}
        handleCloseModal={() => setIsFailedModalOpen(false)}
      />
    </section>
  );
};

export default UserList;
