import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@ohif/ui';

const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    if (
      [
        '/patientReports',
        '/patientReportImages',
        '/patientRelatedReports',
        '/patientReference',
        '/patientMeasurements',
      ].includes(location.pathname)
    ) {
      navigate('/reports');
    } else {
      navigate(-1);
    }
  };

  const showBackButton = [
    '/patientReports',
    '/patientReportImages',
    '/patientRelatedReports',
    '/patientReference',
    '/patientMeasurements',
  ].includes(location.pathname);

  return (
    showBackButton && (
      <button
        className="backBtn"
        onClick={handleBack}
      >
        <Icon name="back-icon" />
      </button>
    )
  );
};

export default BackButton;
