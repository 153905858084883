import { CustomTable, Search } from '../../../../components';
import React, { useEffect, useState } from 'react';

const PatientRelatedReports = () => {
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  const columns = [
    { key: 'name', title: 'Patient Name' },
    { key: 'owner', title: 'Owner' },
    { key: 'reportDate', title: 'Date' },
    { key: 'file', title: 'File' },
  ];

  const actions = [
    {
      label: 'more',
      iconName: 'more-icon',
      dropdownItems: [
        {
          label: 'View PDF',
          iconName: 'report-preview',
          onClick: item => {
            // navigate('/patientReports', {
            //   state: {
            //     reportData: item,
            //   },
            // });
            console.log('item', item);
          },
        },
      ],
      onClick: () => {
        // setSelectReportTemplateModal(true);
        console.log('12345');
      },
    },
  ];

  const sampleData = [
    // {
    //   name: 'John Doe',
    //   owner: 'Dr. Shashi',
    //   reportDate: '25-09-2023, 01:23 pm',
    //   file: '--',
    // },
    // {
    //   name: 'Emily Johnson',
    //   owner: 'Dr. Shashi',
    //   reportDate: '25-09-2023, 01:23 pm',
    //   file: '--',
    // },
  ];

  useEffect(() => {
    setTableData(sampleData);
    setFilteredData(sampleData);
  }, []);

  return (
    <div>
      <div className="tableWrap">
        <Search
          data={tableData}
          setFilteredData={setFilteredData}
          sortKey="name"
          placeholder="Search by Name"
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
        />
        {/* {filteredData?.length > 0 ? ( */}
        <CustomTable
          actions={actions}
          columns={columns}
          tableData={tableData}
        />
        {/* ) : (
          <div className="no-data-message">No users found.</div>
        )} */}
      </div>
    </div>
  );
};
export default PatientRelatedReports;
