import { createSlice } from '@reduxjs/toolkit';
import { deleteClinicApi } from '../../../rtk-apis/clinics/deleteClinic';

const initialState = {
  status: '',
  result: null,
  msg: '',
  loading: false,
  error: '',
};

const deleteClinicApiSlice = createSlice({
  name: 'deleteClinicApiSlice',
  initialState,
  reducers: {
    closeModal(state: any) {
      state.status = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(deleteClinicApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteClinicApi.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = payload.status;
        state.result = payload.result;
        state.msg = payload.msg;
      })
      .addCase(deleteClinicApi.rejected, (state, { payload }) => {
        state.result = payload;
        state.loading = false;
      });
  },
});

export default deleteClinicApiSlice.reducer;
export const { closeModal } = deleteClinicApiSlice.actions;
