import React from 'react';
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';

if (typeof Highcharts3D === 'function') {
  Highcharts3D(Highcharts);
}

const ColumnChart3D = ({ categories, series }) => {
  const options = {
    chart: {
      type: 'column',
      options3d: {
        enabled: true,
        alpha: 10,
        beta: 15,
        depth: 50,
        viewDistance: 25,
      },
      backgroundColor: 'transparent',
    },
    title: {
      text: null,
    },
    xAxis: {
      categories,
      labels: {
        style: {
          color: '#ffffff',
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      title: {
        text: 'Number of Hospitals',
        style: {
          color: '#ffffff',
          fontSize: '14px',
        },
        x: -18,
      },
      labels: {
        style: {
          color: '#ffffff',
        },
      },
    },
    plotOptions: {
      column: {
        depth: 25,
        colorByPoint: true,
      },
    },
    series,
    credits: {
      enabled: false,
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ColumnChart3D;
