import { createSlice } from '@reduxjs/toolkit';
import { editPacs } from '../../../rtk-apis/pacs/editPacs';

const initialState = {
  status: '',
  result: null,
  msg: '',
  loading: false,
  error: '',
};

const editPacsDataSlice = createSlice({
  name: 'editPacsData',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = null;
      state.msg = '';
      state.loading = false;
      state.error = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(editPacs.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editPacs.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
        state.msg = action?.payload?.msg;
      })
      .addCase(editPacs.rejected, (state, action) => {
        state.loading = false;
        state.status = 'ERROR';
        state.result = null;
        state.msg = (action?.payload as string) || 'Something went wrong!';
      });
  },
});

export default editPacsDataSlice.reducer;
export const { clearState } = editPacsDataSlice.actions;
