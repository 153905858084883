import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { SideMenu, Header, Loader, Success, Failed } from '../../components';
import { customStyles } from '../../Utils/utils';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { editPacs } from '../../rtk-apis/pacs/editPacs';
import { PacsValidationSchema } from '../auth/helper/Schema';
import { clearState as clearEditState } from '../../store/slices/users/editUser';
import { addPacs } from '../../rtk-apis/pacs/addPacs';

const AddUpdatePacs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { userDetails } = location?.state || {};
  const hospitalId = sessionStorage?.getItem("hospitalId")

  const [displayMessage, setDisplayMessage] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitTriggered, setIsSubmitTriggered] = useState(false);

  const breadcrumbs = [
    { label: 'Pacs', link: '/pacs', icon: 'sidebar-icon' },
    { label: `${userDetails ? 'Update' : 'Add'} Pacs` },
  ];

  const { status: editStatus, result: editResult, msg: editMsg, loading: editLoading } = useSelector((state: RootState) => state.editPacsDataResponse);
  const { status: addStatus, result: addResult, msg: addMsg, loading: addLoading } = useSelector((state: RootState) => state.addPacsResponse);

  const initialValues = {
    _id: userDetails?._id ?? '',
    aeTitle: userDetails?.aeTitle ?? '',
    ipAddress: userDetails?.ipAddress ?? '',
    port: userDetails?.port ?? '',
    hospitalId: hospitalId ?? '',
  };

  // Clear previous states when component mounts or userDetails change
  useEffect(() => {
    // Reset all relevant states
    setDisplayMessage('');
    setIsSuccessModalOpen(false);
    setIsFailedModalOpen(false);
    setIsSubmitTriggered(false);

    // Determine edit mode
    if (userDetails) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }

    // Clear previous API states
    dispatch({ type: 'editPacsDataResponse/clearState' });
    dispatch({ type: 'addPacsResponse/clearState' });
  }, [userDetails, dispatch]);

  const handleSubmit = (values, { setSubmitting }) => {
    setIsSubmitTriggered(true);
    if (isEditMode) {
      dispatch(editPacs({
        aeTitle: values.aeTitle,
        ipAddress: values.ipAddress,
        port: values.port,
        id: userDetails?._id,
      }));
    } else {
      dispatch(addPacs({
        aeTitle: values.aeTitle,
        ipAddress: values.ipAddress,
        port: values.port,
        hospitalId: hospitalId
      }));
    }
    setSubmitting(false);
  };

  const handleSuccess = (message) => {
    setDisplayMessage(message);
    setIsSuccessModalOpen(true);
    setTimeout(() => {
      setIsSuccessModalOpen(false);
      setDisplayMessage('');
      navigate('/pacs');
    }, 1500);
  };

  useEffect(() => {
    if (isSubmitTriggered) {
      if (isEditMode && editStatus === 'SUCCESS') {
        handleSuccess(editMsg);
        setIsSubmitTriggered(false);
      } else if (!isEditMode && addStatus === 'SUCCESS') {
        handleSuccess(addMsg);
        setIsSubmitTriggered(false);
      }
    }
  }, [
    isSubmitTriggered,
    isEditMode,
    editStatus,
    addStatus,
    editMsg,
    addMsg
  ]);

  return (
    <section
      className="layoutContainer"
      style={customStyles.layoutBackground}
    >
      <SideMenu />
      <div className="layoutGrid">
        <div className="page">
          <Header breadcrumbs={breadcrumbs} />
          <div className="page__body">
            <Formik
              key={isEditMode ? 'edit-mode' : 'add-mode'} // Force re-render when mode changes
              initialValues={initialValues}
              validationSchema={PacsValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="form">
                  <div className="page__body__head">
                    <h1 className="title title--h6 title--normal">
                      {isEditMode ? 'Update Pacs' : 'Add Pacs'}
                    </h1>
                    <div className="btn__group">
                      <button
                        className="btn btn__white btn__outlined"
                        type="button"
                        onClick={() => navigate('/pacs')}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn__primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isEditMode ? 'Update' : 'Save'}
                      </button>
                    </div>
                  </div>
                  <section className="form__section">
                    <h1 className="form__section__title">Pacs Details</h1>
                    <Field type="hidden" name="_id" />
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="form__group">
                          <label htmlFor="aeTitle" className="form__label">
                            AE Title
                            <sup className="form__label__asterisk">*</sup>
                          </label>
                          <Field
                            type="text"
                            id="aeTitle"
                            name="aeTitle"
                            className="form__input form__input--sm"
                            placeholder="AE Title"
                          />
                          <ErrorMessage
                            name="aeTitle"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                      <div className="formCol">
                        <div className="form__group">
                          <label htmlFor="ipAddress" className="form__label">
                            IP Address
                            <sup className="form__label__asterisk">*</sup>
                          </label>
                          <Field
                            type="text"
                            id="ipAddress"
                            name="ipAddress"
                            className="form__input form__input--sm"
                            placeholder="IP Address"
                          />
                          <ErrorMessage
                            name="ipAddress"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="form__group">
                          <label htmlFor="port" className="form__label">
                            Port
                            <sup className="form__label__asterisk">*</sup>
                          </label>
                          <Field
                            type="text"
                            id="port"
                            name="port"
                            className="form__input form__input--sm"
                            placeholder="Port"
                          />
                          <ErrorMessage
                            name="port"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Loader isLoading={editLoading || addLoading} />
      <Success
        handleCloseModal={() => setIsSuccessModalOpen(false)}
        title={displayMessage}
        isSuccessModalOpen={isSuccessModalOpen}
      />
      <Failed
        handleCloseModal={() => setIsFailedModalOpen(false)}
        title={displayMessage}
        isFailedModalOpen={isFailedModalOpen}
      />
    </section>
  );
};

export default AddUpdatePacs;
