import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { SideMenu, Header, Success, Failed } from '../../components';
import { customStyles } from '../../Utils/utils';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Icon } from '@ohif/ui';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'platform/custom/store/store';
import { changePasswordApi } from '../../rtk-apis/auth/changePassword';
import { clearState } from '../../store/slices/Auth/changePassword';

const ChangePassword = () => {
  const dispatch = useDispatch<AppDispatch>();
  const changePasswordResponse = useSelector((state: RootState) => state.changePasswordResponse);
  const userEmail = sessionStorage.getItem('email');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState('');
  const [apiRejectedResponse, setApiRejectedResponse] = useState('');

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    number: false,
  });

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current Password is required'),
    newPassword: Yup.string()
      .required('New Password is required')
      .test('passwordCriteria', 'Password does not meet criteria', value => {
        const criteria = {
          length: value?.length >= 8 && value?.length <= 20,
          uppercase: /[A-Z]/.test(value),
          number: /\d/.test(value),
        };
        setPasswordCriteria(criteria);
        return Object.values(criteria).every(Boolean);
      }),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const togglePasswordVisibility = (field: string) => {
    setShowPassword(prev => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const breadcrumbs = [{ label: 'Change Password', icon: 'sidebar-icon' }];

  useEffect(() => {
    if (changePasswordResponse.status === 'SUCCESS') {
      setDisplayMessage('Password updated successfully.');
      setShowSuccessModal(true);
      dispatch(clearState());
    } else if (changePasswordResponse.status === 'ERROR') {
      setApiRejectedResponse(changePasswordResponse.msg || 'Failed to change password.');
      setShowFailedModal(true);
      dispatch(clearState());
    }
  }, [changePasswordResponse, dispatch]);

  const handleFormSubmit = (values: any, { setSubmitting }: any) => {
    if (userEmail) {
      dispatch(
        changePasswordApi({
          username: userEmail,
          oldPassword: values.currentPassword,
          newPassword: values.newPassword,
        })
      )
        .then(response => {
          if (
            changePasswordResponse.status === 'SUCCESS' ||
            changePasswordResponse.status === 'ERROR'
          ) {
            setDisplayMessage(changePasswordResponse.msg);
            setShowSuccessModal(true);
            dispatch(clearState());
          }
        })
        .catch(error => {
          setApiRejectedResponse(error.message || 'An unexpected error occurred');
          setShowFailedModal(true);
        });
    }
    setSubmitting(false);
  };

  return (
    <section
      className="layoutContainer"
      style={customStyles.layoutBackground}
    >
      <SideMenu />
      <div className="layoutGrid">
        <div className="page">
          <Header breadcrumbs={breadcrumbs} />
          <div className="page__body">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              {({ isValid, dirty, resetForm, isSubmitting }) => (
                <Form>
                  <div className="page__body__head">
                    <h1 className="title title--h6 title--normal">Update Password</h1>
                    <div className="btn__group">
                      <button
                        className="btn btn__white btn__outlined"
                        type="button"
                        onClick={() => resetForm()}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn__primary"
                        type="submit"
                        disabled={
                          !isValid ||
                          !dirty ||
                          isSubmitting ||
                          !Object.values(passwordCriteria).every(Boolean)
                        }
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <section className="form__section">
                    <h1 className="form__section__title">Password Details</h1>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="currentPassword"
                            className="form__label"
                          >
                            Current Password
                            <sup className="form__label__asterisk">*</sup>
                          </label>
                          <div className="form__groupField">
                            <Field
                              type={showPassword.currentPassword ? 'text' : 'password'}
                              id="currentPassword"
                              name="currentPassword"
                              className="form__input"
                              placeholder="Current Password"
                            />
                            <button
                              type="button"
                              className="btn"
                              onClick={() => togglePasswordVisibility('currentPassword')}
                            >
                              <Icon
                                name={showPassword.currentPassword ? 'eye-open' : 'eye-close'}
                              />
                            </button>
                          </div>
                          <ErrorMessage
                            name="currentPassword"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="newPassword"
                            className="form__label"
                          >
                            New Password
                            <sup className="form__label__asterisk">*</sup>
                          </label>
                          <div className="form__groupField">
                            <Field
                              type={showPassword.newPassword ? 'text' : 'password'}
                              id="newPassword"
                              name="newPassword"
                              className="form__input"
                              placeholder="New Password"
                            />
                            <button
                              type="button"
                              className="btn"
                              onClick={() => togglePasswordVisibility('newPassword')}
                            >
                              <Icon name={showPassword.newPassword ? 'eye-open' : 'eye-close'} />
                            </button>
                          </div>
                          <ul className="form__passwordInfo">
                            <li className="item">Your Password Must Contain:</li>
                            <li
                              className={`item ${passwordCriteria.length ? 'item--success' : 'item--error'}`}
                            >
                              Between 8 and 20 characters
                            </li>
                            <li
                              className={`item ${passwordCriteria.uppercase ? 'item--success' : 'item--error'}`}
                            >
                              1 upper case letter
                            </li>
                            <li
                              className={`item ${passwordCriteria.number ? 'item--success' : 'item--error'}`}
                            >
                              1 or more numbers
                            </li>
                          </ul>
                          <ErrorMessage
                            name="newPassword"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="form__group">
                          <label
                            htmlFor="confirmPassword"
                            className="form__label"
                          >
                            Confirm Password
                            <sup className="form__label__asterisk">*</sup>
                          </label>
                          <div className="form__groupField">
                            <Field
                              type={showPassword.confirmPassword ? 'text' : 'password'}
                              id="confirmPassword"
                              name="confirmPassword"
                              className="form__input"
                              placeholder="Confirm Password"
                            />
                            <button
                              type="button"
                              className="btn"
                              onClick={() => togglePasswordVisibility('confirmPassword')}
                            >
                              <Icon
                                name={showPassword.confirmPassword ? 'eye-open' : 'eye-close'}
                              />
                            </button>
                          </div>
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="form__error"
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Success
        handleCloseModal={() => setShowSuccessModal(false)}
        title={displayMessage}
        isSuccessModalOpen={showSuccessModal}
      />
      <Failed
        handleCloseModal={() => setShowFailedModal(false)}
        title={apiRejectedResponse}
        isFailedModalOpen={showFailedModal}
      />
    </section>
  );
};

export default ChangePassword;
