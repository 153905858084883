import { getToken } from "../../Utils/authUtils"
import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMMON_DATA } from "../../constant"

type Payload = {
};

export const getCommonData = createAsyncThunk(
  'getCommonData',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await fetch(`${COMMON_DATA?.GET_COMMON_DATA}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.msg || 'Get data failed');
      }

      const result = await response.json();

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
