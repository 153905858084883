import React, { useState, useEffect } from 'react';
import { Icon } from '@ohif/ui';
import '../../custom/styled/pages/dashboard.scss';
import { formatDateToDDMMYYYY, formatTimeInAMPM } from '../Utils/utils';
import { capitalizeFirstLetterOfEachWord } from '../Utils/utils';
import { AppDispatch, RootState } from '../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getCommonData } from '../rtk-apis/common/getCommonData';
import { changeReportStatus } from '../rtk-apis/Reports/changeReportStatus';
import { Loader, Success, Failed } from '../components';
import { clearState } from '../store/slices/Reports/changeReportStatus';

const ReportCard = ({
  ReportData,
  setHitGetReportApi,
  printAsPdf,
  selectedTab,
  updateTemplateContent,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [ReportStatusOptions, setReportStatusOptions] = useState([]);
  const [SelectedReportStatus, setSelectedReportStatus] = useState('');
  const [InitialReportStatus, setInitialReportStatus] = useState('');
  const [displayMessage, setDisplayMessage] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);

  const { result: commonData } = useSelector(
    (state: RootState) => state.getCommonDataSliceResponse
  );
  const { status, data, msg, loading } = useSelector(
    (state: RootState) => state.changeReportStatusResponse
  );

  useEffect(() => {
    const payload = {};
    dispatch(getCommonData(payload));
  }, []);

  useEffect(() => {
    if (ReportData?.data?.status) {
      setInitialReportStatus(ReportData?.data?.status);
    }
  }, [ReportData?.data?.status]);

  useEffect(() => {
    if (commonData?.reportStatus?.length > 0) {
      setReportStatusOptions(commonData?.reportStatus);
    }
  }, [commonData]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (SelectedReportStatus && ReportData?.data?._id) {
      const payload = {
        reportId: ReportData?.data?._id,
        reportStatus: SelectedReportStatus,
        templateHTML: updateTemplateContent,
      };
      dispatch(changeReportStatus(payload));
    }
  }, [SelectedReportStatus]);

  useEffect(() => {
    const handleSuccess = message => {
      setDisplayMessage(message);
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        setIsSuccessModalOpen(false);
        setDisplayMessage('');
        setHitGetReportApi(true);
      }, 1500);
    };

    const handleFailure = message => {
      setDisplayMessage(message);
      setIsFailedModalOpen(true);
      setTimeout(() => {
        setIsFailedModalOpen(false);
        setDisplayMessage('');
      }, 1500);
    };
    if (status === 'SUCCESS') {
      handleSuccess(msg);
    }

    if (status === 'FAILED') {
      handleFailure(msg);
    }

    return () => {
      dispatch(clearState());
    };
  }, [status]);

  return (
    <>
      <div className="reportCard">
        <div className="reportCard__head">
          <div className="profile">
            <Icon name="username-icon" />
            {`${capitalizeFirstLetterOfEachWord(ReportData?.data?.name)}`}
          </div>
          <div className="btn__group">
            <button
              className="btn btn__white btn__outlined"
              onClick={() => printAsPdf()}
              disabled={selectedTab !== 1}
            >
              View PDF
            </button>
            <div className="customSelect">
              <button
                className="customSelect__caption"
                onClick={toggleDropdown}
                role="button"
                tabIndex={0}
                disabled={InitialReportStatus === 'Finalized'}
                style={{
                  pointerEvents: InitialReportStatus === 'Finalized' ? 'none' : 'auto',
                  cursor: InitialReportStatus === 'Finalized' ? 'not-allowed' : 'pointer',
                  opacity: InitialReportStatus === 'Finalized' ? 0.7 : 1,
                }}
              >
                Status: {SelectedReportStatus ? SelectedReportStatus : InitialReportStatus}
                <Icon name="arrow-down" />
              </button>
              {isOpen && (
                <ul className="customSelect__dropdown">
                  {ReportStatusOptions?.map((status, index) => {
                    return (
                      <li
                        className="item"
                        key={index}
                        value={status}
                        onClick={() => {
                          setSelectedReportStatus(status);
                          setIsOpen(false);
                        }}
                      >
                        {status}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <ul className="dropdown">
              <li className="dropdown__item">
                <button className="btn p_0 border_0">{/* SVG Icon */}</button>
                <ul className="dropdownMenu">
                  <li className="dropdown__item">Share Report</li>
                  <li className="dropdown__item">Export Report</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <ul className="reportCard__info">
          <li>
            <button className="btn btn__success">Type: {ReportData?.data?.reportName}</button>
          </li>
          <li>DOB: {ReportData?.data?.dob}</li>
          <li>Sex: {ReportData?.data?.sex}</li>
          <li>Age: {ReportData?.data?.age}</li>
          <li>Date: {ReportData?.data?.date ? ReportData?.data?.date : '--'}</li>
          <li>Time: {ReportData?.data?.time ? formatTimeInAMPM(ReportData?.data?.time) : '--'}</li>
        </ul>
      </div>
      <Loader isLoading={loading} />
      <Success
        handleCloseModal={() => setIsSuccessModalOpen(false)}
        title={displayMessage}
        isSuccessModalOpen={isSuccessModalOpen}
      />
      <Failed
        handleCloseModal={() => setIsFailedModalOpen(false)}
        title={displayMessage}
        isFailedModalOpen={isFailedModalOpen}
      />
    </>
  );
};

export default ReportCard;
