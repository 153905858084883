import React from 'react';
import '../../styled/pages/dashboard.scss';
import { Header, SideMenu } from '../../components';
import { customStyles } from '../../Utils/utils';

const Settings = () => {
  const breadcrumbs = [{ label: 'Settings', icon: 'sidebar-icon' }];

  return (
    <section
      className="layoutContainer"
      style={customStyles.layoutBackground}
    >
      <SideMenu />
      <div className="layoutGrid">
        <div className="page page--fullBody">
          <Header breadcrumbs={breadcrumbs} />
          <div className="page__body">
            <div className="page__content">
              <div className="page__body__head">
                <h1 className="title title--h1 title--normal">coming soon!</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Settings;
