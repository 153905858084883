import { createSlice } from '@reduxjs/toolkit';
import { getReportTemplatesApi } from '../../../rtk-apis/reports/getReportTemplates';

const initialState = {
  status: '',
  result: '',
  msg: '',
  loading: false,
};

const getReportTemplatesSlice = createSlice({
  name: 'getReportTemplates',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getReportTemplatesApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getReportTemplatesApi.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.msg = action?.payload?.msg;
        state.result = action?.payload;
      })
      .addCase(getReportTemplatesApi.rejected, (state, action) => {
        state.loading = false;
        state.status = 'FAILED';
        state.msg = action?.payload as string;
        state.result = (action?.payload as string) || '';
      });
  },
});

export const { clearState } = getReportTemplatesSlice.actions;
export default getReportTemplatesSlice.reducer;
