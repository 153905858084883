import React, { useEffect } from 'react';
import '../styled/pages/dashboard.scss';
import { Icon } from '@ohif/ui';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetterOfEachWord, getInitials } from '../Utils/utils';
import { AppDispatch, RootState } from '../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDetails } from '../rtk-apis/profile/getProfileDetails';
import BackButton from '../components/BackButton';
import { getRole } from '../Utils/authUtils';

const Header = ({ breadcrumbs }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const payload = {};
    dispatch(getProfileDetails(payload));
  }, []);

  const { data: loggedInUserData } = useSelector(
    (state: RootState) => state.getProfileDetailsResponse
  );

  return (
    <div className="page__head">
      <div className="page__head__title">
        <BackButton />
        <ul className="breadcrumb">
          {breadcrumbs?.map((crumb, index) => (
            <li
              key={index}
              className="breadcrumb__item"
            >
              {crumb.link ? (
                <a
                  className="breadcrumb__link"
                  onClick={() => navigate(crumb.link)}
                >
                  {crumb.icon && (
                    <span className="icon icon--user opacity-150">
                      {crumb.icon && <Icon name={crumb.icon} />}
                    </span>
                  )}
                  <span className="cursor-pointer opacity-50 hover:opacity-100">{crumb.label}</span>
                </a>
              ) : (
                <span className="breadcrumb__link">
                  {crumb.icon && <Icon name={crumb.icon} />}
                  <span>{crumb.label}</span>
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="page__head__actions">
        <div className="userProfile">
          <button className="userProfile__link">
            <span className="picIcon">
              <div className="profile">
                <div className="profile__initials">{getInitials(loggedInUserData?.name)}</div>
              </div>
            </span>
            <span className="Userinfo">
              <h4>
                {getRole() === 'Admin' ? (
                  capitalizeFirstLetterOfEachWord(loggedInUserData?.adminDetails?.[0]?.name)
                ) : (
                  capitalizeFirstLetterOfEachWord(loggedInUserData?.name)
                )}
              </h4>
              <p>{loggedInUserData?.role ? loggedInUserData?.role : ''}</p>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
