import { createSlice } from '@reduxjs/toolkit';
import { editClinicApi } from '../../../rtk-apis/clinics/editClinic';
import { hospitalTemplateApi } from '../../../rtk-apis/clinics/reportTemplate';

const initialState = {
  status: '',
  result: null,
  msg: '',
  loading: false,
  error: '',
};

const reportTemplateApiSlice = createSlice({
  name: 'reportTemplate',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = null;
      state.msg = '';
      state.loading = false;
      state.error = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(hospitalTemplateApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(hospitalTemplateApi.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
        state.msg = action?.payload?.msg;
      })
      .addCase(hospitalTemplateApi.rejected, (state, action) => {
        state.loading = false;
        state.status = 'ERROR';
        state.result = null;
        state.msg = (action?.payload as string) || 'Something went wrong!';
      });
  },
});

export default reportTemplateApiSlice.reducer;
export const { clearState } = reportTemplateApiSlice.actions;
