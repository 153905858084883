import React from 'react';
import classnames from 'classnames';

import './ViewportOverlay.css';
import Icon from '../Icon';
import { servicesManager } from '../../../../../platform/app/src/App';

// The overlay-top and overlay-bottom classes are explicitly needed to offset
// the overlays (i.e. via absolute positioning) such the ViewportActionCorners
// have space for its child components.
// ToDo: offset the ViewportOverlay automatically via css to account for the
// space needed for ViewportActionCorners.
const classes = {
  topLeft: 'overlay-top left-viewport',
  topRight: 'overlay-top right-viewport-scrollbar',
  bottomRight: 'overlay-bottom right-viewport-scrollbar',
  bottomLeft: 'overlay-bottom left-viewport',
};

export type ViewportOverlayProps = {
  topLeft: React.ReactNode;
  topRight: React.ReactNode;
  bottomRight: React.ReactNode;
  bottomLeft: React.ReactNode;
  color?: string;
  element?: any;
};

const ViewportOverlay = ({
  topLeft,
  topRight,
  bottomRight,
  bottomLeft,
  element,
  color = 'text-primary-light',
}: ViewportOverlayProps) => {
  const overlay = 'absolute pointer-events-none viewport-overlay';

  const { uiNotificationService } = servicesManager.services;

  const handleFullScreen = () => {
    if (element) {
      // Check if the browser supports Fullscreen API
      const requestFullscreen =
        element.requestFullscreen ||
        element.webkitRequestFullscreen ||
        element.msRequestFullscreen ||
        element.mozRequestFullScreen;

      if (requestFullscreen) {
        requestFullscreen
          .call(element)
          .then(() => {
            console.log('Entered fullscreen mode successfully');
          })
          .catch(err => {
            console.error('Failed to enter fullscreen mode:', err);
            uiNotificationService.show({
              title: 'Fullscreen Error',
              message: 'Unable to enter full screen mode. Please try again.',
              type: 'error',
            });
          });
      } else {
        console.error('Fullscreen API is not supported in this browser.');
        uiNotificationService.show({
          title: 'Fullscreen Error',
          message: 'Fullscreen mode is not supported on your device/browser.',
          type: 'error',
        });
      }
    } else {
      console.error('No element to enter fullscreen mode.');
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    }
  };

  const toggleFullScreen = e => {
    console.log('togllllllllwwee', element);
    if (!document.fullscreenElement) {
      handleFullScreen();
    } else {
      exitFullScreen();
    }
  };
  return (
    <div
      className={classnames(
        color ? color : 'text-aqua-pale',
        'text-[13px]',
        'leading-5',
        'overlay-text'
      )}
    >
      <div
        data-cy={'viewport-overlay-top-left'}
        className={classnames(overlay, classes.topLeft)}
      >
        {topLeft}
      </div>
      <div
        data-cy={'viewport-overlay-top-right'}
        className={classnames(overlay, classes.topRight)}
        style={{ transform: 'translateX(-8px)' }} // shift right side overlays by 4px for better alignment with ViewportActionCorners' icons
      >
        <div
          onClick={e => toggleFullScreen(e)}
          className="z-1000"
          style={{ zIndex: '10000', pointerEvents: 'auto', filter: 'invert(1)' }}
        >
          <Icon
            name="maximize-icon"
            style={{ color: 'white' }}
          />
        </div>
        {topRight}
      </div>
      <div
        data-cy={'viewport-overlay-bottom-right'}
        className={classnames(overlay, classes.bottomRight)}
        style={{ transform: 'translateX(-8px)' }} // shift right side overlays by 4px for better alignment with ViewportActionCorners' icons
      >
        {bottomRight}
      </div>
      <div
        data-cy={'viewport-overlay-bottom-left'}
        className={classnames(overlay, classes.bottomLeft)}
      >
        {bottomLeft}
      </div>
    </div>
  );
};

export default ViewportOverlay;
