import { createSlice } from '@reduxjs/toolkit';
import { updateReport } from '../../../rtk-apis/Reports/updateReport';

const initialState = {
  status: '',
  result: null,
  msg: '',
  loading: false,
  error: '',
};

const updateReportSlice = createSlice({
  name: 'updateReportSlice',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = null;
      state.msg = '';
      state.loading = false;
      state.error = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(updateReport.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateReport.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
        state.msg = action?.payload?.msg;
      })
      .addCase(updateReport.rejected, (state, action) => {
        state.loading = false;
        state.status = 'ERROR';
        state.result = null;
        state.msg = (action?.payload as string) || 'Something went wrong!';
      });
  },
});

export default updateReportSlice.reducer;
export const { clearState } = updateReportSlice.actions;
