import { createSlice } from '@reduxjs/toolkit';
import { updateProfileDetails } from '../../../rtk-apis/profile/updateProfileDetails';

const initialState = {
  status: '',
  data: '',
  msg: '',
  loading: false,
};

const updateProfileDetailsSlice = createSlice({
  name: 'updateProfileDetailsSlice',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.data = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(updateProfileDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateProfileDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.data = action?.payload;
        state.msg = action?.payload?.msg;
      })
      .addCase(updateProfileDetails.rejected, (state, action: any) => {
        state.loading = false;
        state.status = 'FAILED';
        state.data = action?.payload;
      });
  },
});

export default updateProfileDetailsSlice.reducer;
export const { clearState } = updateProfileDetailsSlice.actions;
