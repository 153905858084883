import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { Icon } from '@ohif/ui';
import {
  CustomTable,
  Header,
  Pagination,
  SideMenu,
  Search,
  Loader,
  ConfirmationModal,
  Success,
  Failed,
} from '../../components';
import { capitalizeFirstLetterOfEachWord, customStyles } from '../../Utils/utils';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getClinicApi } from '../../rtk-apis/clinics/getClinic';
import { clearState } from '../../store/slices/clinics/getClinic';
import { deleteClinicApi } from '../../rtk-apis/clinics/deleteClinic';
import { closeModal } from '../../store/slices/clinics/deleteClinic';
import useDebounce from "../../components/useDebounce";
import Filter from "../../components/Filter"
import SortComponent from "../../components/SortComponent"

const ClinicsList = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState(null);
  const searchQuery = useDebounce(searchTerm, 1000);

  const [displayMessage, setDisplayMessage] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

  const columns = [
    { key: 'name', title: 'Name' },
    { key: 'email', title: 'Email Address' },
    { key: 'adminName', title: 'Admin' },
    { key: 'contactDetails', title: 'Contact Details' },
    { key: 'Address', title: 'Location' },
  ];
  const actions = [
    {
      label: 'Edit',
      iconName: 'edit-icon',
      onClick: (item: any) => {
        navigate(`/updateclinic/${item?._id}`, { state: { clinicDetails: item } });
      },
    },
    {
      label: 'Delete',
      iconName: 'delete-icon',
      onClick: (item: any) => {
        setSelectedRow(item?._id);
        setIsConfirmationModalOpen(true);
      },
    },
  ];

  const breadcrumbs = [{ label: 'Manage Clinics', icon: 'sidebar-icon' }];

  // *********************** Redux States ************************************

  const dispatch = useDispatch<AppDispatch>();
  const { status, result, msg, loading } = useSelector(
    (state: RootState) => state.getClinicResponse
  );
  const deleteClinicResponse = useSelector((state: RootState) => state.deleteClinicResponse);

  // ******************** Use-Effects *******************************************

  useEffect(() => {
    const payload = {
      page: currentPage,
      limit: 20,
      search: searchQuery
    };
    dispatch(getClinicApi(payload));
  }, [searchQuery, currentPage, deleteClinicResponse.status]);

  useEffect(() => {
    if (status === 'SUCCESS') {
      console.log('🚀 ~ useEffect ~ result:', result);
      const modifiedResponse = result?.data?.map((item) => {
        const adminName = capitalizeFirstLetterOfEachWord(item.adminDetails?.[0]?.name) || null;
        const contactDetails = item?.countryCode + ' ' + item?.contactDetails
        return {
          ...item,
          adminName,
          contactDetails,
        };
      });
      setTableData(modifiedResponse);
      setFilteredData(modifiedResponse);
    }
    if (status === 'FAILED') {
      if (msg === 'No clinics found.') {
        setTableData([]);
        setFilteredData([]);
      }
    }
    dispatch(clearState());
  }, [status]);

  useEffect(() => {
    if (deleteClinicResponse?.status === 'SUCCESS') {
      setDisplayMessage(deleteClinicResponse?.msg);
      setIsSuccessModalOpen(true);
    }
    if (deleteClinicResponse?.status === 'ERROR') {
      setDisplayMessage(deleteClinicResponse?.msg);
      setIsFailedModalOpen(true);
    }
    dispatch(closeModal());
  }, [deleteClinicResponse.status]);

  const handleDeleteClinic = () => {
    const payload = {
      id: selectedRow,
    };
    dispatch(deleteClinicApi(payload));
    setSelectedRow(null);
  };

  useEffect(() => {
    const filtered = tableData?.filter(item =>
      item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchQuery, tableData]);

  return (
    <>
      <section
        className="layoutContainer"
        style={customStyles.layoutBackground}
      >
        <SideMenu />
        <div className="layoutGrid">
          <div className="page page--fullBody">
            <Header breadcrumbs={breadcrumbs} />
            <div className="page__body">
              <div className="page__content">
                <div className="page__body__head">
                  <h1 className="title title--h6 title--normal">Clinic List</h1>
                  <div className="btn__group">
                    <button
                      type="button"
                      className="btn btn__primary"
                      onClick={() => {
                        navigate('/addclinic');
                      }}
                    >
                      <Icon name="add-icon" />
                      Add Clinic
                    </button>
                  </div>
                </div>
                <div className="tableWrap">
                  <div className="tableWrap__head">
                    <div className="formRow">
                      <div className="formCol relative">
                        <input
                          className="form__input form__input--search"
                          type="text"
                          placeholder="Search by Name"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e?.target?.value)}
                        />
                        <span className="absolute right-4 bottom-3 cursor-pointer">
                          <Icon name="search-icon" />
                        </span>
                      </div>
                    </div>
                    <div className="btn__group">
                      <SortComponent
                        searchQuery={searchQuery}
                        data={tableData}
                        setFilteredData={setFilteredData}
                        sortKey="name"
                      />
                    </div>
                  </div>
                  <CustomTable
                    actions={actions}
                    columns={columns}
                    tableData={currentItems}
                  />
                </div>
              </div>
            </div>
            {filteredData?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                itemsPerPage={itemsPerPage}
                totalItems={filteredData?.length}
                onPageChange={setCurrentPage}
                onItemsPerPageChange={setItemsPerPage}
              />
            )}
          </div>
        </div>
      </section>
      <Loader isLoading={loading || deleteClinicResponse.loading} />
      <ConfirmationModal
        title="Delete Clinic"
        description="Are you sure you want to delete this clinic?"
        isConfirmationModalOpen={isConfirmationModalOpen}
        handleCloseModal={() => setIsConfirmationModalOpen(false)}
        handleConfirm={() => {
          setIsConfirmationModalOpen(false);
          handleDeleteClinic();
        }}
        cancelActionText="Cancel"
        confirmActionText="Yes"
      />
      <Success
        title={displayMessage}
        isSuccessModalOpen={isSuccessModalOpen}
        handleCloseModal={() => setIsSuccessModalOpen(false)}
      />
      <Failed
        title={displayMessage}
        isFailedModalOpen={isFailedModalOpen}
        handleCloseModal={() => setIsFailedModalOpen(false)}
      />
    </>
  );
};

export default ClinicsList;
