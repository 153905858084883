import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { USERS } from '../../constant';

type Payload = {
  _id: string;
};

export const deleteUserApi = createAsyncThunk(
  'deleteUser',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await fetch(USERS.DELETE_USER_URL, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.msg || 'Delete User failed');
      }

      const result = await response.json();

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
