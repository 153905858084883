import { getToken } from '../../Utils/authUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLINICS } from '../../constant';

interface Payload {
  hospitalId: string;
  hospitalLogo: string;
  hospitalHeader: {
    hospitalName: string;
    contactNumberCountryCode: string;
    contactNumber: string;
    email: string;
    address: string;
    zipCode?: string;
    country?: string;
    state?: string;
    city?: string;
  };
}

export const hospitalTemplateApi = createAsyncThunk(
  'hospitalTemplate/update',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
      };

      const res = await fetch(CLINICS.HOSPITAL_TEMPLATE_URL, requestOptions);

      if (!res.ok) {
        const errorData = await res.json();
        return rejectWithValue(errorData.msg || 'Update failed');
      }

      const data = await res.json();
      console.log('data', data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
