import { createAsyncThunk } from '@reduxjs/toolkit';
import { REPORTS } from '../../constant';
import { getToken } from "../../Utils/authUtils"

interface Payload {
  limit: number;
  search: string;
  hospitalId: string;
  page: number;
}

export const assignReportToDoctor = createAsyncThunk(
  'assignReportToDoctor',
  async (payload: Payload, { rejectWithValue }) => {
    try {
      const token = getToken();
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      };
      let res = await fetch(`${REPORTS?.ASSIGN_REPORT}`, requestOptions);

      // Check if the response is not okay (status is 400 or other errors)
      if (!res.ok) {
        const errorData = await res.json(); // Extract error message from the res
        return rejectWithValue(errorData.message || 'Fetch Reports List Failed!');
      }

      const data = await res.json();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
