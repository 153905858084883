import React, { useEffect, useState } from 'react';

import { extensionManager, servicesManager } from '../../../../../app/src/App';
import { DicomMetadataStore } from '@ohif/core';

import { utils } from '@ohif/core';
import { OHIFCornerstoneSRContainer } from '../../../../../../extensions/cornerstone-dicom-sr/src/components/OHIFCornerstoneSRContainer';
import formatContentItemValue from '../../../../../../extensions/cornerstone-dicom-sr/src/utils/formatContentItem';

import ReactDOMServer from 'react-dom/server';
import { MeasurementPage } from '../../../../components';

const PatientMeasurements = ({ ReportData, setHitGetReportApi }) => {
  console.log('ReportData', ReportData);

  // const [measData, setMeasData] = useState(null);
  // console.log('🚀 ~ PatientMeasurements ~ measData:', measData);
  // const [isStudyMetaDataFetched, setIsStudyMetaDataFetched] = useState(false);

  // const dataSource = extensionManager.getActiveDataSource()[0];

  // async function fetchAndStoreStudyMetadata(StudyInstanceUID) {
  //   try {
  //     // Retrieve all series metadata for the given study instance UID
  //     const seriesMetadataPromises = await dataSource.retrieve.series.metadata({
  //       StudyInstanceUID,
  //       returnPromises: true,
  //     });

  //     // Await all series metadata promises and store each instance in DicomMetadataStore
  //     const seriesMetadataArray = await Promise.all(seriesMetadataPromises.map(p => p.start()));

  //     seriesMetadataArray.forEach(seriesMetadata => {
  //       if (seriesMetadata && seriesMetadata.instances) {
  //         // Store each instance in the DicomMetadataStore
  //         seriesMetadata.instances.forEach(instance => {
  //           DicomMetadataStore.addInstance(instance);
  //         });
  //       }
  //     });
  //     setIsStudyMetaDataFetched(true);
  //   } catch (error) {
  //     console.error(
  //       `Failed to fetch or store metadata for StudyInstanceUID: ${StudyInstanceUID}`,
  //       error
  //     );
  //   }
  // }

  // function getSeriesMetadata(StudyInstanceUID, SeriesInstanceUID) {
  //   // Retrieve the series metadata using StudyInstanceUID and SeriesInstanceUID
  //   const seriesMetadata = DicomMetadataStore.getSeries(StudyInstanceUID, SeriesInstanceUID);
  //   if (!seriesMetadata) {
  //     console.warn(
  //       `No series metadata found for StudyInstanceUID: ${StudyInstanceUID} and SeriesInstanceUID: ${SeriesInstanceUID}`
  //     );
  //     return null;
  //   }
  //   return seriesMetadata;
  // }

  // useEffect(() => {
  //   fetchAndStoreStudyMetadata(ReportData?.studyInstanceUID);
  // }, []);

  // useEffect(() => {
  //   if (isStudyMetaDataFetched) {
  //     const seriesMeta = getSeriesMetadata(
  //       ReportData?.studyInstanceUID,
  //       ReportData?.seriesInstanceUID
  //     );
  //     setMeasData(extractContainerData(seriesMeta?.instances[0]));
  //   }
  // }, [isStudyMetaDataFetched]);

  // function extractContainerData(container, nodeIndexesTree = [0], containerNumberedTree = [1]) {
  //   const { ContinuityOfContent, ConceptNameCodeSequence, ContentSequence } = container;
  //   const { CodeMeaning } = ConceptNameCodeSequence ?? {};
  //   let childContainerIndex = 1;

  //   const result = {
  //     codeMeaning: CodeMeaning,
  //     containerNumberedTree: containerNumberedTree.join('.'),
  //     continuityOfContent: ContinuityOfContent,
  //     contentItems: [],
  //   };

  //   if (ContentSequence && Array.isArray(ContentSequence)) {
  //     result.contentItems = ContentSequence.map((contentItem, i) => {
  //       const { ValueType } = contentItem;
  //       const childNodeLevel = [...nodeIndexesTree, i];

  //       if (ValueType === 'CONTAINER') {
  //         const childContainerNumberedTree = [...containerNumberedTree, childContainerIndex++];
  //         return extractContainerData(contentItem, childNodeLevel, childContainerNumberedTree);
  //       } else {
  //         return extractContentItemData(contentItem, childNodeLevel, ContinuityOfContent);
  //       }
  //     });
  //   }

  //   return result;
  // }

  // function extractContentItemData(contentItem, nodeIndexesTree, continuityOfContent) {
  //   const { ConceptNameCodeSequence } = contentItem;
  //   const { CodeValue, CodeMeaning } = ConceptNameCodeSequence ?? {};
  //   const formattedValue = formatContentItemValue(contentItem) || '[empty]';

  //   return {
  //     codeValue: CodeValue,
  //     codeMeaning: CodeMeaning,
  //     value: formattedValue,
  //     nodeIndexesTree: nodeIndexesTree.join('.'),
  //     isContinuous: continuityOfContent === 'CONTINUOUS',
  //   };
  // }

  // return measData && <MeasurementPage measData={measData} />;
  return (
    <MeasurementPage
      measData={ReportData}
      setHitGetReportApi={setHitGetReportApi}
    />
  );
};

export default PatientMeasurements;
