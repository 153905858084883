import { user } from '@ohif/core';

export const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const isUserLogedIn = () => {
  return Boolean(user.getAccessToken());
};

const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getToken = () => {
  return sessionStorage.getItem('token') || localStorage.getItem('token');
};

export const getRole = () => {
  return sessionStorage.getItem('role') || localStorage.getItem('role');
};

export const getHospitalId = () => {
  return sessionStorage.getItem('hospitalId') || localStorage.getItem('hospitalId');
};

export const getHospitalName = () => {
  return sessionStorage.getItem('hospitalName') || localStorage.getItem('hospitalName');
};

export const isValidUser = () => {
  const userToken = sessionStorage.getItem('token') || localStorage.getItem('token');

  if (userToken) {
    const decodedJwt = parseJwt(userToken);
    if (decodedJwt.exp * 1000 < Date.now()) {
      // props.logOut();
      return false;
    }
  } else {
    return false;
  }

  return true;
};

// export const removeUserCredentials = () => {
//   sessionStorage.removeItem(AUTH_TOKEN_KEY);
//   sessionStorage.removeItem('role');
//   sessionStorage.removeItem('name');
//   sessionStorage.removeItem('hospitalId');
//   sessionStorage.removeItem('hospitalName');
//   localStorage.removeItem(AUTH_TOKEN_KEY);
//   localStorage.removeItem('role');
//   localStorage.removeItem('name');
//   localStorage.removeItem('hospitalId');
//   localStorage.removeItem('hospitalName');
// };
