import { createSlice } from '@reduxjs/toolkit';
import { assignReportToDoctor } from '../../../rtk-apis/Reports/assignReportToDoctor';

const initialState = {
  status: '',
  data: '',
  msg: '',
  loading: false,
};

const assignReportToDoctorSlice = createSlice({
  name: 'getHospitalDoctorsListSlice',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.data = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(assignReportToDoctor.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(assignReportToDoctor.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.data = action?.payload?.data;
        state.msg = action?.payload?.message;
      })
      .addCase(assignReportToDoctor.rejected, (state, action) => {
        state.loading = false;
        state.status = 'FAILED';
        state.data = (action?.payload as string) || 'Something went wrong';
      });
  },
});

export const { clearState } = assignReportToDoctorSlice.actions;
export default assignReportToDoctorSlice.reducer;
