import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { SideMenu, Header, Success, Failed, Loader } from '../../components';
import { customStyles } from '../../Utils/utils';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Icon } from '@ohif/ui';
import { myProfileValidationSchema } from '../auth/helper/Schema';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDetails } from '../../rtk-apis/profile/getProfileDetails';
import { updateProfileDetails } from "../../rtk-apis/profile/updateProfileDetails"
import { clearState } from "../../store/slices/profile/updateProfileDetails"

const myProfile = () => {
  const dispatch: AppDispatch = useDispatch();
  const [AdminDetails, setAdminDetails] = useState([]);
  const [displayMessage, setDisplayMessage] = useState('');
  const [OpenSuccessModal, setOpenSuccessModal] = useState(false);
  const [OpenFailureModal, setOpenFailureModal] = useState(false);
  const IsClinicAdmin = sessionStorage?.getItem("role") === "Admin"

  const breadcrumbs = [{ label: 'My Profile', icon: 'sidebar-icon' }];

  // ****************************** Redux States *****************************************
  const { status, data, msg, loading } = useSelector(
    (state: RootState) => state.getProfileDetailsResponse
  );

  const { status: updateProfileStatus, msg: updateProfileMessage, loading: updateProfileLoading } = useSelector(
    (state: RootState) => state.updateProfileDetailsResponse
  );

  // ************************ Use-Effects ****************************************8

  useEffect(() => {
    if (status === "SUCCESS" && data?.adminDetails?.length > 0) {
      setAdminDetails(data?.adminDetails[0])
    }
  }, [status, data])

  const myProfileInitialValues = {
    name: IsClinicAdmin ? AdminDetails?.name : data?.name,
    role: data?.role ?? '',
    email: IsClinicAdmin ? AdminDetails?.email : data?.email,
    contactNumber: IsClinicAdmin ? AdminDetails?.contactNo : data?.contactDetails,
    countryCode: IsClinicAdmin ? AdminDetails?.countryCode : data?.countryCode
  };

  useEffect(() => {
    getLoggedInUserProfileDetails();
  }, [updateProfileStatus])

  // ******************************************************************************

  const getLoggedInUserProfileDetails = () => {
    const payload = {}
    dispatch(getProfileDetails(payload));
  }

  const handleSubmit = (values: any) => {
    const payload = IsClinicAdmin
      ? {
        adminDetails: [
          {
            name: values?.name,
            email: values?.email,
            contactNo: values?.contactNumber,
            countryCode: values?.countryCode,
          }
        ]
      }
      : {
        name: values?.name,
        email: values?.email,
        contactDetails: values?.contactNumber,
        countryCode: values?.countryCode,
      };
    dispatch(updateProfileDetails(payload));
  };

  useEffect(() => {
    const handleSuccess = message => {
      setDisplayMessage(message);
      setOpenSuccessModal(true);
      setTimeout(() => {
        setOpenSuccessModal(false);
        setDisplayMessage('');
        getLoggedInUserProfileDetails();
      }, 1500);
    };

    const handleFailure = message => {
      setDisplayMessage(message);
      setOpenFailureModal(true);
      setTimeout(() => {
        setOpenFailureModal(false);
        setDisplayMessage('');
        getLoggedInUserProfileDetails();
      }, 1500);
    };

    if (updateProfileStatus === 'SUCCESS') {
      handleSuccess(updateProfileMessage);
    } else if (updateProfileStatus === 'FAILED') {
      handleFailure(updateProfileMessage);
    }

    return () => {
      dispatch(clearState());
    };
  }, [updateProfileStatus, updateProfileMessage]);


  return (
    <>
      <section
        className="layoutContainer"
        style={customStyles.layoutBackground}
      >
        <SideMenu />
        <div className="layoutGrid">
          <div className="page">
            <Header breadcrumbs={breadcrumbs} />
            <div className="page__body">
              <Formik
                initialValues={myProfileInitialValues}
                validationSchema={myProfileValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ isSubmitting, values }) => (
                  <>
                    <Form className="form">
                      <div className="page__body__head">
                        <h1 className="title title--h6 title--normal">Update Profile Information</h1>
                        <div className="btn__group">
                          <button
                            className="btn btn__white btn__outlined"
                            type="button"
                            onClick={() => { }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn__primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                      <section className="form__section">
                        <h1 className="form__section__title">Profile Details</h1>

                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="name"
                                className="form__label"
                              >
                                Name
                                <sup className="form__label__asterisk">*</sup>
                              </label>
                              <Field
                                type="text"
                                id="name"
                                name="name"
                                className="form__input form__input--sm"
                                placeholder="Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="role"
                                className="form__label"
                              >
                                Role
                              </label>
                              <Field
                                type="text"
                                id="role"
                                name="role"
                                className="form__input form__input--sm form__input--disabled"
                                placeholder="Role"
                                disabled
                                value={values?.role}
                              ></Field>
                              <ErrorMessage
                                name="role"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                        </div>
                        {/* Dummy hidden fields */}
                        <input
                          type="text"
                          name="fakeEmail"
                          autoComplete="off"
                          style={{ display: 'none' }}
                        />
                        <input
                          type="password"
                          name="fakePassword"
                          autoComplete="new-password"
                          style={{ display: 'none' }}
                        />
                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="email"
                                className="form__label"
                              >
                                Email Address
                              </label>
                              <Field
                                type="text"
                                id="email"
                                name="email"
                                placeholder="Email Address"
                                autoComplete="newEmail"
                                className="form__input form__input--sm form__input--disabled"
                                disabled
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="contactNumber"
                                className="form__label"
                              >
                                Contact Number
                              </label>
                              <div className="form__groupField form__groupField--phonenumber">
                                <Field
                                  as="select"
                                  name="contactNumber"
                                  className="form__select form__select--sm"
                                  id="contactNumber"
                                  defaultValue="+91"
                                >
                                  <option value="1">+91</option>
                                  <option value="2">+61</option>
                                </Field>
                                <Field
                                  type="tel"
                                  name="contactNumber"
                                  className="form__input form__input--sm"
                                  placeholder="Contact Number"
                                  maxLength={10}
                                  onInput={e => (e.target.value = e.target.value.replace(/\D/, ''))}
                                />
                              </div>
                              <ErrorMessage
                                name="contactNumber"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
      <Loader isLoading={loading || updateProfileLoading} />
      <Success
        isSuccessModalOpen={OpenSuccessModal}
        handleCloseModal={() => {
          setOpenSuccessModal(false);
        }}
        title={displayMessage}
      />
      <Failed
        isFailedModalOpen={OpenFailureModal}
        handleCloseModal={() => {
          setOpenFailureModal(false);
        }}
        title={displayMessage}
      />
    </>
  );
};

export default myProfile;
