import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { Icon } from '@ohif/ui';
import { CustomTable, Header, Pagination, SideMenu, Search } from '../../components';
import { customStyles } from '../../Utils/utils';
import useDebounce from "../../components/useDebounce"
import Filter from "../../components/Filter"
import SortComponent from "../../components/SortComponent";

const auditLogs = () => {
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const searchQuery = useDebounce(searchTerm, 1000);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Search filtering logic
  useEffect(() => {
    let filtered = tableData?.filter(
      item =>
        item?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        item?.email?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    setFilteredData(filtered);
  }, [searchTerm, tableData]);

  // Fetch data (for demonstration purposes, assume data is an array of objects)
  useEffect(() => {
    // Simulated fetch call
    const fetchData = [
      {
        _id: '1',
        name: 'John Doe',
        role: 'Radiologist',
        occured: '25-09-2023, 01:23 pm',
        status: 'Report Generated',
      },
      {
        _id: '2',
        name: 'John Doe',
        role: 'Radiologist',
        occured: '25-09-2023, 01:23 pm',
        status: 'Report Generated',
      },
    ];
    setTableData(fetchData);
    setFilteredData(fetchData);
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

  const columns = [
    { key: 'name', title: 'Name' },
    { key: 'role', title: 'Role' },
    { key: 'occured', title: 'Occured' },
    { key: 'status', title: 'Status' },
  ];
  const actions = [
    {
      label: 'View Details',
      onClick: (item: any) => {
        console.log(item);
      },
    },
  ];
  const breadcrumbs = [{ label: 'Manage Clinics', icon: 'sidebar-icon' }];
  return (
    <section
      className="layoutContainer"
      style={customStyles.layoutBackground}
    >
      <SideMenu />
      <div className="layoutGrid">
        <div className="page page--fullBody">
          <Header breadcrumbs={breadcrumbs} />
          <div className="page__body">
            <div className="page__content">
              <div className="page__body__head">
                <h1 className="title title--h6 title--normal">Audit Log List</h1>
              </div>
              <div className="tableWrap">
                <div className="tableWrap__head">
                  {/* Search */}
                  <div className="formRow">
                    <div className="formCol relative">
                      <input
                        className="form__input form__input--search"
                        type="text"
                        placeholder="Search by Name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e?.target?.value)}
                      />
                      <span className="absolute right-4 bottom-3 cursor-pointer">
                        <Icon name="search-icon" />
                      </span>
                    </div>
                  </div>
                  <div className="btn__group">
                    {/* <Filter /> */}
                    <SortComponent
                      searchQuery={searchQuery}
                      data={tableData}
                      setFilteredData={setFilteredData}
                      sortKey="name"
                    />
                  </div>
                </div>
                <CustomTable
                  actions={actions}
                  columns={columns}
                  tableData={currentItems}
                  from="auditLogs"
                />
              </div>
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            itemsPerPage={itemsPerPage}
            totalItems={filteredData?.length}
            onPageChange={setCurrentPage}
            onItemsPerPageChange={setItemsPerPage}
          />
        </div>
      </div>
    </section>
  );
};

export default auditLogs;
