import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  area: 0,
  areaUnit: '',
};

const volumeROIDataSlice = createSlice({
  name: 'volumeROIDataSlice',
  initialState,
  reducers: {
    addArea: (state, { payload }) => {
      state.area = payload.area || 0;
      state.areaUnit = payload.areaUnit || '';
    },
    reset: state => {
      state = {
        area: 0,
        areaUnit: '',
      };
    },
  },
});

export const { addArea, reset } = volumeROIDataSlice.actions;
export default volumeROIDataSlice.reducer;
