import { createSlice } from '@reduxjs/toolkit';
import { getUserApi } from '../../../rtk-apis/users/getUser';

const initialState = {
  status: '',
  result: '',
  msg: '',
  loading: false,
};

const getUserSlice = createSlice({
  name: 'getUser',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUserApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserApi.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
        state.msg = action?.payload?.msg || 'User fetched successfully.';
      })
      .addCase(getUserApi.rejected, (state, action) => {
        state.loading = false;
        state.status = 'FAILED';
        state.result = (action?.payload as string) || 'Something went wrong';
      });
  },
});

export const { clearState } = getUserSlice.actions;
export default getUserSlice.reducer;
