import React, { useEffect, useState } from 'react';
import '../../styled/pages/dashboard.scss';
import { SideMenu, Header, Loader, Success, Failed } from '../../components';
import { customStyles } from '../../Utils/utils';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Country, State, City } from 'country-state-city';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { addClinicApi } from '../../rtk-apis/clinics/addClinic';
import { editClinicApi } from '../../rtk-apis/clinics/editClinic';
import { clearState as clearEditState } from '../../store/slices/clinics/editClinic';
import { clearState } from '../../store/slices/clinics/addClinic';
import { clinicFormValidationSchema } from '../auth/helper/Schema';

const AddUpdateClinic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [editMode, setEditMode] = useState(location?.pathname?.includes('updateclinic') ?? false);
  const { clinicDetails } = location?.state || {};

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [displayMessage, setDisplayMessage] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);

  console.log('clinicDetails', clinicDetails);

  const breadcrumbs = [
    { label: 'Manage Clinics', link: '/clinics', icon: 'sidebar-icon' }, // previous page
    { label: `${editMode ? 'Edit' : 'ADD'} Clinic` }, // current page
  ];

  // ****************** Initail Formik States *********************************
  const contactDetailsWithoutCountryCode = clinicDetails?.contactDetails?.replace(clinicDetails?.countryCode, '').trim() ?? '';

  const clinicFormInitialValues = {
    name: clinicDetails?.name ?? '',
    email: clinicDetails?.email ?? '',
    address: clinicDetails?.Address ?? '',
    zipCode: clinicDetails?.ZipCode ?? '',
    country: clinicDetails?.Country ?? '',
    city: clinicDetails?.City ?? '',
    state: clinicDetails?.State ?? '',
    countryCode: clinicDetails?.countryCode ?? '+91',
    contactNumber: contactDetailsWithoutCountryCode,
    website: clinicDetails?.website ?? '',
    fax: clinicDetails?.fax ?? '',
    adminName: clinicDetails?.adminDetails?.[0]?.name ?? '',
    adminEmail: clinicDetails?.adminDetails?.[0]?.email ?? '',
    adminContactNumber: clinicDetails?.adminDetails?.[0]?.contactNo ?? '',
    adminCountryCode: clinicDetails?.adminDetails?.[0]?.countryCode ?? '+91',
  };

  // ******************* Redux States ************************

  const { status, result, msg, loading } = useSelector(
    (state: RootState) => state.addClinicResponse
  );

  const {
    status: editStatus,
    result: editResult,
    msg: editMsg,
    loading: editLoading,
  } = useSelector((state: RootState) => state.editClinicResponse);

  // ******************************** Use-Effects ***********************************

  useEffect(() => {
    if (selectedCountry) {
      const states = State.getStatesOfCountry(selectedCountry);
      setStateOptions(states);
      setSelectedState('');
      setCityOptions([]);
      setSelectedCity('');
    }
  }, [selectedCountry]);

  // Update cities when state changes
  useEffect(() => {
    if (selectedState) {
      const cities = City.getCitiesOfState(selectedCountry, selectedState);
      setCityOptions(cities);
      setSelectedCity(''); // Reset city selection when state changes
    }
  }, [selectedState, selectedCountry]);

  useEffect(() => {
    if (clinicDetails) {
      setSelectedCountry(clinicDetails?.Country);
      setSelectedState(clinicDetails?.State);
      setSelectedCity(clinicDetails?.City);
    }
  }, [clinicDetails]);

  // Fetch states when country changes
  useEffect(() => {
    if (selectedCountry) {
      const states = State.getStatesOfCountry(selectedCountry);
      setStateOptions(states);
      if (clinicDetails?.State) {
        setSelectedState(clinicDetails?.State); // Prepopulate state
      }
    } else {
      setStateOptions([]);
      setSelectedState('');
    }
  }, [selectedCountry]);

  // Fetch cities when state changes
  useEffect(() => {
    if (selectedState && selectedCountry) {
      const cities = City.getCitiesOfState(selectedCountry, selectedState);
      setCityOptions(cities);
      if (clinicDetails?.City) {
        setSelectedCity(clinicDetails?.City); // Prepopulate city
      }
    } else {
      setCityOptions([]);
      setSelectedCity('');
    }
  }, [selectedState, selectedCountry]);

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = {
      id: editMode ? clinicDetails?._id : undefined,
      name: values.name,
      email: values.email.toLowerCase(),
      countryCode: values.countryCode,
      contactDetails: values.contactNumber,
      Address: values.address,
      ZipCode: values.zipCode,
      Country: values.country,
      State: values.state,
      City: values.city,
      adminDetails: [
        {
          name: values?.adminName,
          email: values?.adminEmail,
          // fax: values.fax,
          // website: values.website,
          contactNo: values?.adminContactNumber,
          countryCode: values?.adminCountryCode,
        },
      ],
    };

    if (editMode) {
      dispatch(editClinicApi(payload));
    } else {
      dispatch(addClinicApi(payload));
    }
    setSubmitting(false);
  };

  useEffect(() => {
    const handleSuccess = message => {
      setDisplayMessage(message);
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        setIsSuccessModalOpen(false);
        setDisplayMessage('');
        navigate('/clinics');
      }, 1500);
    };

    const handleFailure = message => {
      setDisplayMessage(message);
      setIsFailedModalOpen(true);
      setTimeout(() => {
        setIsFailedModalOpen(false);
        setDisplayMessage('');
      }, 1500);
    };

    if (status === 'SUCCESS') {
      handleSuccess(msg);
    } else if (editStatus === 'SUCCESS') {
      handleSuccess(editMsg);
    }

    if (status === 'FAILED') {
      handleFailure(msg);
    } else if (editStatus === 'FAILED') {
      handleFailure(editMsg);
    }

    return () => {
      dispatch(clearState());
      dispatch(clearEditState());
    };
  }, [status, editStatus, msg, editMsg, dispatch, navigate]);

  return (
    <>
      <section
        className="layoutContainer"
        style={customStyles.layoutBackground}
      >
        <SideMenu />
        <div className="layoutGrid">
          <div className="page">
            <Header breadcrumbs={breadcrumbs} />
            <div className="page__body">
              <Formik
                initialValues={clinicFormInitialValues}
                validationSchema={clinicFormValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <>
                    <Form className="form">
                      <div className="page__body__head">
                        <h1 className="title title--h6 title--normal">
                          {`${editMode ? 'Update' : 'Add'} Clinic`}
                        </h1>
                        <div className="btn__group">
                          <button
                            className="btn btn__white btn__outlined"
                            type="button"
                            onClick={() => navigate('/clinics')}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn__primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {editMode ? 'Update' : 'Save'}
                          </button>
                        </div>
                      </div>
                      <section className="form__section">
                        <h1 className="form__section__title">Clinic Details</h1>
                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="name"
                                className="form__label"
                              >
                                Name<span className="required-asterisk">*</span>
                              </label>
                              <Field
                                type="text"
                                id="name"
                                name="name"
                                className="form__input form__input--sm"
                                placeholder="Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>

                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="email"
                                className="form__label"
                              >
                                Email Address<span className="required-asterisk">*</span>
                              </label>
                              <Field
                                type="text"
                                id="email"
                                name="email"
                                className="form__input form__input--sm"
                                placeholder="Email Address"
                                disabled={editMode}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="address"
                                className="form__label"
                              >
                                Address<span className="required-asterisk">*</span>
                              </label>
                              <Field
                                type="text"
                                id="address"
                                name="address"
                                className="form__input form__input--sm"
                                placeholder="Address"
                              />
                              <ErrorMessage
                                name="address"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>

                          <div className="formCol">
                            <div className="formRow formRow__4">
                              <div className="formCol">
                                <div className="form__group">
                                  <label
                                    htmlFor="zipCode"
                                    className="form__label"
                                  >
                                    Zip Code<span className="required-asterisk">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    id="zipCode"
                                    name="zipCode"
                                    className="form__input form__input--sm"
                                    placeholder="Zip Code"
                                  />
                                  <ErrorMessage
                                    name="zipCode"
                                    component="div"
                                    className="form__error"
                                  />
                                </div>
                              </div>

                              <div className="formCol">
                                <div className="form__group">
                                  <label
                                    htmlFor="country"
                                    className="form__label"
                                  >
                                    Country<span className="required-asterisk">*</span>
                                  </label>
                                  <Field
                                    as="select"
                                    id="country"
                                    name="country"
                                    value={selectedCountry}
                                    onChange={e => {
                                      setSelectedCountry(e.target.value);
                                      setFieldValue('country', e.target.value);
                                      setFieldValue('state', '');
                                      setFieldValue('city', '');
                                      setSelectedState('');
                                      setSelectedCity('');
                                    }}
                                    className="form__select form__select--sm"
                                  >
                                    <option value="">-Select-</option>
                                    {Country.getAllCountries().map(country => (
                                      <option
                                        key={country.isoCode}
                                        value={country.isoCode}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name="country"
                                    component="div"
                                    className="form__error"
                                  />
                                </div>
                              </div>

                              <div className="formCol">
                                <div className="form__group">
                                  <label
                                    htmlFor="State"
                                    className="form__label"
                                  >
                                    State<span className="required-asterisk">*</span>
                                  </label>
                                  <Field
                                    as="select"
                                    id="state"
                                    name="state"
                                    value={selectedState}
                                    onChange={e => {
                                      setSelectedState(e.target.value);
                                      setFieldValue('state', e.target.value);
                                      setFieldValue('city', '');
                                      setSelectedCity('');
                                    }}
                                    className="form__select form__select--sm"
                                    disabled={!selectedCountry}
                                  >
                                    <option value="">-Select-</option>
                                    {stateOptions.map(state => (
                                      <option
                                        key={state.isoCode}
                                        value={state.isoCode}
                                      >
                                        {state.name}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name="city"
                                    component="div"
                                    className="form__error"
                                  />
                                </div>
                              </div>

                              <div className="formCol">
                                <div className="form__group">
                                  <label
                                    htmlFor="city"
                                    className="form__label"
                                  >
                                    City<span className="required-asterisk">*</span>
                                  </label>
                                  <Field
                                    as="select"
                                    id="city"
                                    name="city"
                                    value={selectedCity}
                                    onChange={e => {
                                      setSelectedCity(e.target.value);
                                      setFieldValue('city', e.target.value);
                                    }}
                                    className="form__select form__select--sm"
                                    disabled={!selectedState}
                                  >
                                    <option value="">-Select-</option>
                                    {cityOptions.map(city => (
                                      <option
                                        key={city.name}
                                        value={city.name}
                                      >
                                        {city.name}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name="state"
                                    component="div"
                                    className="form__error"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="contactNumber"
                                className="form__label"
                              >
                                Contact Number<span className="required-asterisk">*</span>
                              </label>
                              <div className="form__groupField form__groupField--phonenumber">
                                <Field
                                  as="select"
                                  name="countryCode"
                                  className="form__select form__select--sm"
                                  id="countryCode"
                                >
                                  <option value="+91">+91</option>
                                  <option value="+61">+61</option>
                                </Field>
                                <Field
                                  type="tel"
                                  name="contactNumber"
                                  className="form__input form__input--sm"
                                  placeholder="Contact Number"
                                  maxLength={10}
                                  onInput={e => (e.target.value = e.target.value.replace(/\D/, ''))}
                                />
                              </div>
                              <ErrorMessage
                                name="contactNumber"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="website"
                                className="form__label"
                              >
                                Website
                              </label>
                              <Field
                                type="text"
                                id="website"
                                name="website"
                                className="form__input form__input--sm"
                                placeholder="Website"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="fax"
                                className="form__label"
                              >
                                Fax
                              </label>
                              <Field
                                type="text"
                                id="fax"
                                name="fax"
                                className="form__input form__input--sm"
                                placeholder="Fax"
                              />
                            </div>
                          </div>
                        </div>

                        {/* ADMIN DEATILS */}
                        <div className="form__section__divider"></div>
                        <h1 className="form__section__title">Admin Details</h1>
                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="name"
                                className="form__label"
                              >
                                Name<span className="required-asterisk">*</span>
                              </label>
                              <Field
                                type="text"
                                id="adminName"
                                name="adminName"
                                className="form__input form__input--sm"
                                placeholder="Name"
                              />
                              <ErrorMessage
                                name="adminName"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>

                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="email"
                                className="form__label"
                              >
                                Email Address<span className="required-asterisk">*</span>
                              </label>
                              <Field
                                type="text"
                                id="adminEmail"
                                name="adminEmail"
                                className="form__input form__input--sm"
                                placeholder="Email Address"
                                disabled={editMode}
                              />
                              <ErrorMessage
                                name="adminEmail"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div className="form__group">
                              <label
                                htmlFor="footerContactNumber"
                                className="form__label"
                              >
                                Contact Number<span className="required-asterisk">*</span>
                              </label>
                              <div className="form__groupField form__groupField--phonenumber">
                                <Field
                                  as="select"
                                  name="adminCountryCode"
                                  className="form__select form__select--sm"
                                  defaultValue="+91"
                                >
                                  <option value="+91">+91</option>
                                  <option value="+61">+61</option>
                                </Field>
                                <Field
                                  type="text"
                                  id="adminContactNumber"
                                  name="adminContactNumber"
                                  className="form__input form__input--sm"
                                  placeholder="Contact Number"
                                  maxLength={10}
                                />
                              </div>
                              <ErrorMessage
                                name="adminContactNumber"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Loader isLoading={loading} />
      </section>
      <Success
        isSuccessModalOpen={isSuccessModalOpen}
        handleCloseModal={() => {
          setIsSuccessModalOpen(false);
        }}
        title={displayMessage}
      />
      <Failed
        isFailedModalOpen={isFailedModalOpen}
        handleCloseModal={() => {
          setIsFailedModalOpen(false);
        }}
        title={displayMessage}
      />
    </>
  );
};

export default AddUpdateClinic;
