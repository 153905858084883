import StarterKit from '@tiptap/starter-kit';
import { Mark, mergeAttributes } from '@tiptap/core';
import { Color } from '@tiptap/extension-color';
import Document from '@tiptap/extension-document';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import Heading from '@tiptap/extension-heading';

/** This allows a plain `<span>` (but not any attributes on it) */
export interface SpanOptions {
  HTMLAttributes: Record<string, any>;
}
export const Span = Mark.create<SpanOptions>({
  name: 'span',
  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      customClass: {
        default: null,
        parseHTML: element => element.getAttribute('data-class'),
        renderHTML: attributes => {
          return {
            class: attributes.customClass || '',
          };
        },
      },
      // Add the second customClass
      additionalClass: {
        default: null,
        parseHTML: element => element.getAttribute('class'),
        renderHTML: attributes => {
          // This will not sanitize the class, allowing any value to be applied
          return {
            class: attributes.additionalClass || '',
          };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: element => {
          return {};
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: element => element.getAttribute('data-background-color'),
        renderHTML: attributes => {
          return {
            'data-background-color': attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
    };
  },
});

const CustomTable = Table.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      customClass: {
        default: null,
        parseHTML: element => element.getAttribute('data-class'),
        renderHTML: attributes => {
          return {
            class: attributes.customClass || '',
          };
        },
      },
      // Add the second customClass
      additionalClass: {
        default: null,
        parseHTML: element => element.getAttribute('class'),
        renderHTML: attributes => {
          // This will not sanitize the class, allowing any value to be applied
          return {
            class: attributes.additionalClass || '',
          };
        },
      },
    };
  },
});

const CustomHeader = Heading.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      customClass: {
        default: null,
        parseHTML: element => element.getAttribute('data-class'),
        renderHTML: attributes => {
          return {
            class: attributes.customClass || '',
          };
        },
      },
      // Add the second customClass
      additionalClass: {
        default: null,
        parseHTML: element => element.getAttribute('class'),
        renderHTML: attributes => {
          // This will not sanitize the class, allowing any value to be applied
          return {
            class: attributes.additionalClass || '',
          };
        },
      },
    };
  },
});

export const extensions = [
  Document,
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  BubbleMenu.configure({
    element: document.querySelector('.bubble-menu'),
  }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
      HTMLAttributes: { class: 'editor-list-bullet' },
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
      HTMLAttributes: { class: 'editor-list-ordered' },
    },
    // heading: { HTMLAttributes: { class: 'editor-heading' } },
    bold: { HTMLAttributes: { class: 'editor-bold' } },
    italic: { HTMLAttributes: { class: 'editor-italic' } },
    paragraph: { HTMLAttributes: { class: 'editor-paragraph' } },
    code: { HTMLAttributes: { class: 'editor-code' } },
    strike: { HTMLAttributes: { class: 'editor-strike' } },
    codeBlock: { HTMLAttributes: { class: 'editor-code-block' } },
    horizontalRule: { HTMLAttributes: { class: 'editor-horizontal-rule' } },
    listItem: { HTMLAttributes: { class: 'editor-list-item' } },
    blockquote: { HTMLAttributes: { class: 'editor-blockquote' } },
  }),
  Span,
  CustomHeader,
  CustomTable,
  TableRow,
  TableHeader,
  // Default TableCell
  // TableCell,
  // Custom TableCell with backgroundColor attribute
  CustomTableCell,
];
