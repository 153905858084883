import React, { useState } from 'react';
import '../styled/pages/dashboard.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { getIcon, Icon } from '@ohif/ui';
import {
  getInitials,
  capitalizeFirstLetterOfEachWord,
  formatDateTime,
  formatTimeInAMPM,
  formatDateToDDMMYYYY,
} from '../Utils/utils';
import AssignDoctorModal from '../pages/patients/components/assignToModal';

type Column = {
  key: string;
  title: string;
};

type Action = {
  label: string;
  iconName?: string;
  dropdownItems?: any[];
  onClick: (rowData: any) => void;
};

type Props = {
  columns: Column[];
  tableData: any[];
  actions?: Action[];
  from?: string;
  setOnAssigningReport?: any;
};

const CustomTable = ({ columns, tableData, actions, from, setOnAssigningReport }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedRows, setSelectedRows] = useState<boolean[]>(Array(tableData?.length).fill(false));
  const [selectAll, setSelectAll] = useState(false);
  const [activeIndex, setActiveIndex] = useState<string | null>(null); // Track active action for each row
  const [isAssignDoctorModalOpen, setAssignDoctorModalOpen] = useState(false);
  const [ReportId, setReportId] = useState('');
  const [AssignReportStatus, setAssignReportStatus] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');

  // Toggle popup visibility for a specific row and action
  const handleMoreIconClick = (event: React.MouseEvent, actionIndex: number, rowIndex: number) => {
    event.stopPropagation(); // Prevent event bubbling
    const uniqueKey = `${rowIndex}-${actionIndex}`; // Create a unique key based on row and action
    setActiveIndex(prevIndex => (prevIndex === uniqueKey ? null : uniqueKey)); // Toggle the specific popup
  };

  // Handle Select All toggle
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(Array(tableData?.length).fill(newSelectAll));
  };

  // Handle individual row selection
  const handleRowSelect = (rowIndex: number) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows[rowIndex] = !newSelectedRows[rowIndex];
    setSelectedRows(newSelectedRows);
    setSelectAll(newSelectedRows.every(isSelected => isSelected));
  };

  const handleAssignClick = (reportId, status) => {
    console.log(isAssignDoctorModalOpen, 'jhfkjshkjfhskjf');
    setAssignReportStatus(status);
    setReportId(reportId);
    setAssignDoctorModalOpen(true);
  };

  const role = sessionStorage.getItem('role');

  const handleNameClick = data => {
    if (location.pathname.includes('/reports')) {
      navigate('/patientReports', {
        state: {
          reportId: data?._id,
        },
      });
    }
  };

  const handleFileUpload = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'application/pdf';
    fileInput.onchange = event => {
      const file = event.target.files[0];
      if (file) {
        setUploadedFileName(file.name);
        console.log('Uploaded file:', file);
      }
    };
    fileInput.click();
  };

  return (
    <div className="tableContainer">
      <table className="table">
        <thead>
          <tr>
            {columns?.map(column => (
              <th key={column.key}>
                <div className="cellText">{column.title}</div>
              </th>
            ))}
            {/* Render the "Actions" header if actions are provided */}
            {actions && (
              <th>
                <div className="actions">Action</div>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {tableData?.length > 0 ? (
            tableData?.map((rowData, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  {columns?.map((column, colIndex) => {
                    return (
                      <td key={colIndex}>
                        {column.key === 'name' ? (
                          <div
                            className="profile"
                            onClick={() => handleNameClick(rowData)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className="profile__initials">
                              {getInitials(rowData[column.key])}
                            </div>
                            <span className="profile__name">
                              {capitalizeFirstLetterOfEachWord(rowData[column.key])}
                            </span>
                          </div>
                        ) : column.key === 'status' ? (
                          <div className="actions">
                            <span className="btn btn__success">{rowData[column.key]}</span>
                          </div>
                        ) : column.key === 'role' ? (
                          <div className="actions">
                            <span className="btn btn__yellow">{rowData[column.key]}</span>
                          </div>
                        ) : column.key === 'adminName' ? (
                          <div className="actions">
                            <span className="btn btn__yellow">{rowData[column.key]}</span>
                          </div>
                        ) : column.key === 'port' ? (
                          <div className="actions">
                            <span className="btn btn__yellow">{rowData[column.key]}</span>
                          </div>
                        ) : column.key === 'file' ? (
                          <button
                            className="assign-button"
                            onClick={handleFileUpload}
                          >
                            <div className="cellText">
                              <span className="cellText__primary cellText--medium">
                                {/* {uploadedFileName && <Icon name="pdf-file" />} */}+ Add File
                              </span>
                            </div>
                          </button>
                        ) : column.key === 'assignTo' ? (
                          <div className="cellText cellText--flex">
                            {role === 'Admin' ? (
                              !rowData['reportAssignedTo'] ? (
                                <button
                                  className="assign-button"
                                  onClick={() => handleAssignClick(rowData?._id, 'Assign')}
                                >
                                  <div className="cellText">
                                    <span className="cellText__primary cellText--medium cellText--underline">
                                      + Assign
                                    </span>
                                  </div>
                                </button>
                              ) : (
                                <>
                                  <span className="cellText__Dark">
                                    {capitalizeFirstLetterOfEachWord(rowData['reportAssignedTo'])}{' '}
                                    {capitalizeFirstLetterOfEachWord(rowData[column.key])}
                                  </span>
                                  <div className="actions">
                                    <button
                                      className="btn btn__yellow"
                                      onClick={() => handleAssignClick(rowData?._id, 'Reassign')}
                                    >
                                      <div className="flex gap-1">Update</div>
                                    </button>
                                  </div>
                                </>
                              )
                            ) : rowData['reportAssignedTo'] ? (
                              <span className="cellText__Dark">{rowData['reportAssignedTo']}</span>
                            ) : (
                              <span className="cellText__Dark">{'--'}</span>
                            )}
                          </div>
                        ) : column.key === 'date' ? (
                          <div className="cellText">
                            <span className="cellText__dark">
                              {`${formatDateToDDMMYYYY(rowData?.date)}, ${formatTimeInAMPM(rowData?.time)}`}
                            </span>
                          </div>
                        ) : (
                          <div className="cellText">
                            <span className="cellText__dark">{rowData[column.key]}</span>
                          </div>
                        )}
                      </td>
                    );
                  })}
                  {/* Render the action buttons if actions exist */}
                  {actions && (
                    <td>
                      <div className="actions">
                        {actions?.map((action, actionIndex) => (
                          <li
                            className="dropdown__item"
                            key={actionIndex}
                          >
                            <button
                              className="btn p_0 border_0"
                              onClick={event => {
                                if (action.iconName === 'more-icon') {
                                  handleMoreIconClick(event, actionIndex, rowIndex); // Toggle popup for this specific action
                                } else {
                                  action.onClick(rowData); // Execute other actions normally
                                }
                              }}
                              title={action.label}
                            >
                              {from === 'auditLogs' ? (
                                // <p className="actions__link">{action.label}</p>
                                <div className="actions">
                                  <button className="link link__primary">View Details</button>
                                </div>
                              ) : (
                                <Icon name={action.iconName} />
                              )}
                            </button>
                            <ul className="dropdown">
                              {activeIndex === `${rowIndex}-${actionIndex}` &&
                                action.dropdownItems && (
                                  <ul className="dropdownMenu dropdownMenu--open">
                                    {action?.dropdownItems?.map((dropdownItem, index) => {
                                      return (
                                        <>
                                          {from === 'reports' &&
                                            rowData['status'] === 'Finalized' &&
                                            dropdownItem.id !== 'view-report' ? (
                                            ''
                                          ) : from === 'reports' &&
                                            rowData['status'] === dropdownItem.id ? (
                                            ''
                                          ) : (
                                            <li
                                              className="dropdown__item"
                                              onClick={() => dropdownItem.onClick(rowData)}
                                              key={index}
                                            >
                                              <span className="icon">
                                                <Icon name={dropdownItem.iconName} />
                                              </span>
                                              {dropdownItem.label}
                                            </li>
                                          )}
                                        </>
                                      );
                                    })}
                                  </ul>
                                )}
                            </ul>
                          </li>
                        ))}
                      </div>
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <p> No Result Found!</p>
          )}
        </tbody>
      </table>
      <AssignDoctorModal
        onClose={() => setAssignDoctorModalOpen(false)}
        reportId={ReportId}
        setOnAssigningReport={setOnAssigningReport}
        AssignReportStatus={AssignReportStatus}
        isAssignDoctorModalOpen={isAssignDoctorModalOpen}
      />
    </div>
  );
};

export default CustomTable;
