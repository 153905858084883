import { createSlice } from '@reduxjs/toolkit';
import { addUserApi } from '../../../rtk-apis/users/addUser';

const initialState = {
  status: '',
  result: '',
  msg: '',
  loading: false,
};

const addUserSlice = createSlice({
  name: 'addUser',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(addUserApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addUserApi.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
        state.msg = action?.payload?.msg;
      })
      .addCase(addUserApi.rejected, (state, action: any) => {
        state.loading = false;
        state.status = 'FAILED';
        state.msg = (action?.payload as string) || 'Something went wrong';
      });
  },
});

export default addUserSlice.reducer;
export const { clearState } = addUserSlice.actions;
