import React, { useState, useEffect } from 'react';
import { Icon } from '@ohif/ui';
import '../styled/pages/dashboard.scss';

interface SearchProps {
  data: any[]; // Unfiltered data passed from the parent
  setFilteredData: (data: any[]) => void; // Callback to pass the filtered and sorted data to the parent
  sortKey: string; // Key to sort by
  placeholder: string;
  searchTerm: string;
  setSearchTerm: (data: any[]) => void;
}

const Search: React.FC<SearchProps> = ({
  data, // Original data from parent
  setFilteredData,
  sortKey,
  searchQuery
}) => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [localData, setLocalData] = useState([...data]);
  const [OpenFilterModal, setOpenFilterModal] = useState(false);

  // Update localData whenever the original data changes
  useEffect(() => {
    setLocalData([...data]);
    handleFilteringAndSorting();
  }, [data]);

  // Filter and sort data whenever debouncedSearchTerm or sortOrder changes
  useEffect(() => {
    handleFilteringAndSorting();
  }, [searchQuery, sortOrder]);

  // Filter and sort logic combined
  const handleFilteringAndSorting = () => {
    let filteredData = localData.filter(
      item =>
        item?.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        item?.email?.toLowerCase().includes(searchQuery?.toLowerCase())
    );

    filteredData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) return sortOrder === 'asc' ? -1 : 1;
      if (a[sortKey] > b[sortKey]) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    // Update the parent component with the filtered and sorted data
    setFilteredData(filteredData);
  };

  // Toggle sorting order when sort button is clicked
  const toggleSortOrder = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  return (

    <div className="btn__group">
      {/* FILTER */}
      <div className={`filter ${OpenFilterModal ? "filter--open" : ""}`}>
        <button className="btn p_0 border_0" onClick={() => setOpenFilterModal(!OpenFilterModal)}>
          <Icon name="filter-icon" />
        </button>
        <div className="filter__dropdown">
          <div className="filter__dropdown__body">
            <h3 className="title">
              <span className="icon">
                <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.375 5C14.375 5.16576 14.3092 5.32473 14.1919 5.44194C14.0747 5.55915 13.9158 5.625 13.75 5.625H3.75C3.58424 5.625 3.42527 5.55915 3.30806 5.44194C3.19085 5.32473 3.125 5.16576 3.125 5C3.125 4.83424 3.19085 4.67527 3.30806 4.55806C3.42527 4.44085 3.58424 4.375 3.75 4.375H13.75C13.9158 4.375 14.0747 4.44085 14.1919 4.55806C14.3092 4.67527 14.375 4.83424 14.375 5ZM16.875 0.625H0.625C0.45924 0.625 0.300268 0.690848 0.183058 0.808058C0.065848 0.925269 0 1.08424 0 1.25C0 1.41576 0.065848 1.57473 0.183058 1.69194C0.300268 1.80915 0.45924 1.875 0.625 1.875H16.875C17.0408 1.875 17.1997 1.80915 17.3169 1.69194C17.4342 1.57473 17.5 1.41576 17.5 1.25C17.5 1.08424 17.4342 0.925269 17.3169 0.808058C17.1997 0.690848 17.0408 0.625 16.875 0.625ZM10.625 8.125H6.875C6.70924 8.125 6.55027 8.19085 6.43306 8.30806C6.31585 8.42527 6.25 8.58424 6.25 8.75C6.25 8.91576 6.31585 9.07473 6.43306 9.19194C6.55027 9.30915 6.70924 9.375 6.875 9.375H10.625C10.7908 9.375 10.9497 9.30915 11.0669 9.19194C11.1842 9.07473 11.25 8.91576 11.25 8.75C11.25 8.58424 11.1842 8.42527 11.0669 8.30806C10.9497 8.19085 10.7908 8.125 10.625 8.125Z" fill="white" />
                </svg>
              </span>
              Filter By
            </h3>
            <div className="formRow">
              <div className="formCol">
                <div className="form__Field">
                  <label for="assignedTo" className="form__label">Assigned To</label>
                  <select id="assignedTo" className="form__select form__select--sm">
                    <option value="1" selected="">-Select-</option>
                    <option value="2">Option One</option>
                    <option value="3">Option Two</option>
                    <option value="4">Option Three</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="formRow formRow__2">
              <div className="formCol">
                <div className="form__Field">
                  <label for="startDate" className="form__label">Start Date</label>
                  <input type="date" id="startDate" className="form__date form__date--sm" placeholder="" />
                </div>
              </div>
              <div className="formCol">
                <div className="form__Field">
                  <label for="endDate" className="form__label">End Date</label>
                  <input type="date" id="endDate" className="form__date form__date--sm" placeholder="" />
                </div>
              </div>
            </div>
            <div className="formRow">
              <div className="formCol">
                <div className="form__Field">
                  <label for="reportStatus" className="form__label">Report Status</label>
                  <select id="reportStatus" className="form__select form__select--sm">
                    <option value="1" selected="">Search</option>
                    <option value="2">Option One</option>
                    <option value="3">Option Two</option>
                    <option value="4">Option Three</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="formRow">
              <div className="formCol">
                <div className="form__Field">
                  <label for="reportType" className="form__label">Report Type</label>
                  <select id="reportType" className="form__select form__select--sm">
                    <option value="1" selected="">Search</option>
                    <option value="2">Option One</option>
                    <option value="3">Option Two</option>
                    <option value="4">Option Three</option>
                  </select>
                </div>
              </div>
            </div>
            {/* <div className="formRow">
                <div className="formCol">
                  <div className="form__Field">
                    <label for="labels" className="form__label">Labels</label>
                    <select id="labels" className="form__select form__select--sm">
                      <option value="1" selected="">Search</option>
                      <option value="2">Option One</option>
                      <option value="3">Option Two</option>
                      <option value="4">Option Three</option>
                    </select>
                  </div>
                </div>
              </div> */}
            <div className="btn__group">
              <button className="btn btn__white btn__outlined">Clear</button>
              <button className="btn btn__primary">Apply</button>
            </div>
          </div>
        </div>
      </div>
      {/* SORT */}
      <button
        className="btn p_0 border_0"
        onClick={toggleSortOrder}
      >
        <Icon name="sort-icon" />
      </button>
    </div>
  );
};

export default Search;
