import { configureStore } from '@reduxjs/toolkit';
import loginSlice from './slices/Auth/login';
import forgotPasswordSlice from './slices/Auth/forgotPassword';
import resetPasswordSlice from './slices/Auth/resetPassword';
import otpVerificationSlice from './slices/Auth/otpVerification';
import addClinicSlice from './slices/clinics/addClinic';
import getClinicSlice from './slices/clinics/getClinic';
import deleteClinicSlice from './slices/clinics/deleteClinic';
import editClinicSlice from './slices/clinics/editClinic';
import addUserSlice from './slices/users/addUser';
import editUserSlice from './slices/users/editUser';
import getUserSlice from './slices/users/getUser';
import deleteUserSlice from './slices/users/deleteUser';
import getProfileDetailsSlice from './slices/profile/getProfileDetails';
import updateProfileDetailsSlice from './slices/profile/updateProfileDetails';
import getCommonDataSlice from './slices/Common/getCommonData';
import getHospitalDoctorsListSlice from './slices/Reports/getHospitalDoctorsList';
import getHospitalReportsListSlice from './slices/Reports/getHospitalReportsList';
import assignReportToDoctorSlice from './slices/Reports/assignReportToDoctor';
import changePasswordSlice from './slices/Auth/changePassword';
import reportTemplateApiSlice from './slices/clinics/reportTemplate';
import getReportTemplateApiSlice from './slices/clinics/getReportTemplate';
import uploadClinicLogoApiSlice from './slices/clinics/uploadClinicLogo';
import getReportTemplatesSlice from './slices/reports/getReportTemplates';
import createReportSlice from './slices/reports/createReport';
import getCurrentReportSlice from './slices/reports/getCurrentReport';
import fetchDoctorsToAssignSlice from './slices/Reports/fetchDoctorsToassign';
import changeReportStatusSlice from './slices/Reports/changeReportStatus';
import updateReportSlice from './slices/Reports/updateReport';
import getReportDataByIdSlice from './slices/Reports/getReportDataById';
import volumeROIDataSlice from './slices/Measurements/volumeROIData';
import getDashboardDataSlice from './slices/dashboard/getDashboardData';
import addPacsSlice from './slices/pacs/addPacs';
import getPacsDataSlice from './slices/pacs/getPacs';
import editPacsDataSlice from './slices/pacs/editPacs';
import deletePacsApiSlice from './slices/pacs/deletePacs';

const store = configureStore({
  reducer: {
    loginResponse: loginSlice,
    forgotPasswordResponse: forgotPasswordSlice,
    resetPasswordResponse: resetPasswordSlice,
    otpVerificationResponse: otpVerificationSlice,
    changePasswordResponse: changePasswordSlice,
    addClinicResponse: addClinicSlice,
    getClinicResponse: getClinicSlice,
    deleteClinicResponse: deleteClinicSlice,
    editClinicResponse: editClinicSlice,
    addUserResponse: addUserSlice,
    editUserResponse: editUserSlice,
    getUserResponse: getUserSlice,
    deleteUserResponse: deleteUserSlice,
    getProfileDetailsResponse: getProfileDetailsSlice,
    updateProfileDetailsResponse: updateProfileDetailsSlice,
    reportTemplateApiResponse: reportTemplateApiSlice,
    getReportTemplatesResponse: getReportTemplatesSlice,
    getCommonDataSliceResponse: getCommonDataSlice,
    getHospitalDoctorsListResponse: getHospitalDoctorsListSlice,
    getHospitalReportsListResponse: getHospitalReportsListSlice,
    assignReportToDoctorResponse: assignReportToDoctorSlice,
    getReportTemplateApiResponse: getReportTemplateApiSlice,
    uploadClinicLogoResponse: uploadClinicLogoApiSlice,
    createReportResponse: createReportSlice,
    getCurrentReportResponse: getCurrentReportSlice,
    fetchDoctorsToAssignResponse: fetchDoctorsToAssignSlice,
    changeReportStatusResponse: changeReportStatusSlice,
    updateReportResponse: updateReportSlice,
    getReportDataByIdResponse: getReportDataByIdSlice,
    volumeROIData: volumeROIDataSlice,
    getDashboardDataResponse: getDashboardDataSlice,
    addPacsResponse: addPacsSlice,
    getPacsDataResponse: getPacsDataSlice,
    editPacsDataResponse: editPacsDataSlice,
    deletePacsApiResponse: deletePacsApiSlice,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
