import React from 'react';
import '../../styled/pages/dashboard.scss';
import { SideMenu, Header } from '../../components';
import { customStyles } from '../../Utils/utils';
import { getIcon, Icon } from '@ohif/ui';

const TemplateModal = () => {
  const breadcrumbs = [{ label: 'Templates', icon: 'sidebar-icon' }];

  return (
    <section
      className="layoutContainer"
      style={customStyles.layoutBackground}
    >
      <SideMenu />
      <div className="layoutGrid">
        <div className="page">
          <Header breadcrumbs={breadcrumbs} />
          <div className="page__body">
            <div className="templateDetailWrap">
              <div className="templateDetailWrap__head">
                <h1 className="title title--h4 title--semibold">Review Template</h1>
                <p className="para">Select your preferred template below according to your use</p>
              </div>
              <div className="templateCards">
                <div className="templateCards__item">
                  <div className="templateCards__card">
                    <h3 className="title">
                      {getIcon('template-icon')}
                      Template Name
                    </h3>
                    <div className="templateCards__card__row">
                      <div className="pdfBox">
                        <Icon name="reportPreview" />
                      </div>
                      <div className="content">
                        <p>
                          In publishing and graphic design, Lorem ipsum is a placeholder text
                          commonly used to demonstrate the visual form of a document or a typeface
                          without relying on meaningful content.
                        </p>
                      </div>
                    </div>
                    <div className="btn__group">
                      <button className="btn btn__primary btn--full">Review Template</button>
                    </div>
                  </div>
                </div>
                <div className="templateCards__item">
                  <div className="templateCards__card">
                    <h3 className="title">
                      {getIcon('template-icon')}
                      Template Name
                    </h3>
                    <div className="templateCards__card__row">
                      <div className="pdfBox">
                        <Icon name="reportPreview" />
                      </div>
                      <div className="content">
                        <p>
                          In publishing and graphic design, Lorem ipsum is a placeholder text
                          commonly used to demonstrate the visual form of a document or a typeface
                          without relying on meaningful content.
                        </p>
                      </div>
                    </div>
                    <div className="btn__group">
                      <button className="btn btn__primary btn--full">Review Template</button>
                    </div>
                  </div>
                </div>
                <div className="templateCards__item">
                  <div className="templateCards__card">
                    <h3 className="title">
                      {getIcon('template-icon')}
                      Template Name
                    </h3>
                    <div className="templateCards__card__row">
                      <div className="pdfBox">
                        <Icon name="reportPreview" />
                      </div>
                      <div className="content">
                        <p>
                          In publishing and graphic design, Lorem ipsum is a placeholder text
                          commonly used to demonstrate the visual form of a document or a typeface
                          without relying on meaningful content.
                        </p>
                      </div>
                    </div>
                    <div className="btn__group">
                      <button className="btn btn__primary btn--full">Review Template</button>
                    </div>
                  </div>
                </div>
                <div className="templateCards__item">
                  <div className="templateCards__card">
                    <h3 className="title">
                      {getIcon('template-icon')}
                      Template Name
                    </h3>
                    <div className="templateCards__card__row">
                      <div className="pdfBox">
                        <Icon name="reportPreview" />
                      </div>
                      <div className="content">
                        <p>
                          In publishing and graphic design, Lorem ipsum is a placeholder text
                          commonly used to demonstrate the visual form of a document or a typeface
                          without relying on meaningful content.
                        </p>
                      </div>
                    </div>
                    <div className="btn__group">
                      <button className="btn btn__primary btn--full">Review Template</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TemplateModal;
