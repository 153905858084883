import { createSlice } from '@reduxjs/toolkit';
import { getClinicApi } from '../../../rtk-apis/clinics/getClinic';

const initialState = {
  status: '',
  result: '',
  msg: '',
  loading: false,
};

const getClinicSlice = createSlice({
  name: 'getClinic',
  initialState,
  reducers: {
    clearState: state => {
      state.status = '';
      state.result = '';
      state.msg = '';
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getClinicApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getClinicApi.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'SUCCESS';
        state.result = action?.payload;
      })
      .addCase(getClinicApi.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'FAILED';
        state.msg = payload as string;
      });
  },
});

export const { clearState } = getClinicSlice.actions;
export default getClinicSlice.reducer;
