import React, { useEffect, useState } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import { extensions } from '../../../../Utils/reportExtension';
import { convertBlobToBase64, formatDateToDDMMYYYY } from '../../../../Utils/utils';
import { AppDispatch, RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { updateReport } from '../../../../rtk-apis/Reports/updateReport';
import '../../../reports/styles.scss';
import { Loader, Success, Failed } from '../../../../components';
import { clearState } from '../../../../store/slices/Reports/updateReport';
import { MenuBar } from '../../../../pages/reports/ReportsLayout';
import { StressBullsEyePlot } from '../../../../pages/reports/components/StressBullsEyePlot';
import { RestBullsEyePlot } from '../../../../pages/reports/components/RestBullsEyePlot';
import { Apical2Ch } from '../../../../pages/reports/components/Apical2Ch';
import { Apical3Ch } from '../../../../pages/reports/components/Apical3Ch';
import { Apical4Ch } from '../../../../pages/reports/components/Apical4Ch';

const ReportLayout = props => {
  // const { editor } = useCurrentEditor();

  const editor = useEditor({
    extensions,
    content: props.editorContent,
    editorProps: {
      attributes: {
        spellcheck: 'false',
      },
    },
  });

  useEffect(() => {
    const loadContent = async () => {
      if (editor && props?.reportTemplate?.data?.templateHTML) {
        try {
          // Create a deep copy of the templateHTML to avoid mutating readonly properties
          const templateHTML = JSON.parse(JSON.stringify(props.reportTemplate.data.templateHTML));

          // Safely navigate to reportStatus
          const reportStatus =
            templateHTML?.content?.[2]?.content?.[3]?.content?.[3]?.content?.[0]?.content?.[0];

          // if (reportStatus) {
          //   // Modify the `text` and `type` properties
          //   reportStatus.text = 'Updated Text';
          // } else {
          //   console.warn('reportStatus object not found in templateHTML');
          // }

          console.log('Modified content...', templateHTML);

          // Set the modified content in the editor
          await editor.commands.setContent(templateHTML);

          // Update editor content state
          props.setEditorContent(templateHTML);
          props.setUpdatedTemplateContent(templateHTML);
        } catch (error) {
          console.error('Error parsing or setting editor content:', error);
        }
      }
    };

    // Load content every time the reportTemplate changes
    loadContent();

    // Cleanup previous editor content when switching between patients
    return () => {
      editor?.commands.clearContent();
    };
  }, [editor, props?.reportTemplate?.data?.templateHTML]);

  // Capture changes in the editor and update the specific section
  useEffect(() => {
    if (editor) {
      editor.on('update', () => {
        const updatedContent = editor.getJSON(); // Get content as JSON
        props?.setEditorContent(updatedContent);
        props?.setUpdatedTemplateContent(updatedContent);
      });
    }
  }, [editor]);

  if (!editor) return null;

  return (
    <>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </>
  );
};

export default function PatientReportIndex({
  reportData,
  setHitGetReportApi,
  printRef,
  setUpdatedTemplateContent,
}) {
  const [imageSrc, setImageSrc] = useState<string>();
  const [editorContent, setEditorContent] = useState({});
  const [ReportData, setReportData] = useState({});
  const [displayMessage, setDisplayMessage] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const { status, msg, loading } = useSelector((state: RootState) => state.updateReportResponse);

  console.log(reportData?.data?.reportType, 'reportData');

  useEffect(() => {
    if (Object.keys(reportData).length) {
      setReportData(reportData);
    }
  }, [reportData]);

  const handleSave = () => {
    const payload = {
      reportId: ReportData?.data?._id,
      templateHTML: editorContent,
    };
    dispatch(updateReport(payload));
  };

  useEffect(() => {
    if (ReportData?.hospitalLogo) {
      convertBlobToBase64(ReportData?.hospitalLogo)
        .then(base64Image => setImageSrc(base64Image))
        .catch(error => console.error('Error converting blob to base64:', error));
    }
  }, [ReportData?.hospitalLogo]);

  useEffect(() => {
    const handleSuccess = message => {
      setDisplayMessage(message);
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        setIsSuccessModalOpen(false);
        setDisplayMessage('');
        setHitGetReportApi(true);
      }, 1500);
    };

    const handleFailure = message => {
      setDisplayMessage(message);
      setIsFailedModalOpen(true);
      setTimeout(() => {
        setIsFailedModalOpen(false);
        setDisplayMessage('');
      }, 1500);
    };
    if (status === 'SUCCESS') {
      handleSuccess(msg);
    }

    if (status === 'FAILED') {
      handleFailure(msg);
    }

    return () => {
      dispatch(clearState());
    };
  }, [status]);

  // const printAsPdf = () => {
  //   const printContents = printRef.current.innerHTML; // Get the inner HTML of the div
  //   const originalContents = document.body.innerHTML; // Save the original HTML of the body

  //   document.body.innerHTML = printContents; // Replace the body's HTML with the div's HTML
  //   window.print(); // Trigger the print dialog
  //   document.body.innerHTML = originalContents; // Restore the original HTML
  //   window.location.reload(); // Reload the page to restore event bindings
  // };

  // const printAsPdf = () => {
  //   const printContents = printRef.current.innerHTML;

  //   // Create a new iframe for printing
  //   const printWindow = document.createElement('iframe');
  //   printWindow.style.position = 'absolute';
  //   printWindow.style.top = '-1000px'; // Move it out of view
  //   printWindow.style.left = '-1000px';
  //   document.body.appendChild(printWindow);

  //   const printDocument = printWindow.contentDocument || printWindow.contentWindow.document;
  //   printDocument.open();
  //   printDocument.write(`
  //     <html>
  //       <head>
  //         <title>Print</title>
  //         <style>
  //           /* Add styles if needed */
  //           body {
  //             font-family: Arial, sans-serif;
  //             padding: 20px;
  //           }
  //         </style>
  //       </head>
  //       <body>${printContents}</body>
  //     </html>
  //   `);
  //   printDocument.close();

  //   printWindow.contentWindow.focus();
  //   printWindow.contentWindow.print();

  //   // Clean up by removing the iframe after printing
  //   document.body.removeChild(printWindow);
  // };

  return (
    <>
      <div
        ref={printRef}
        className="whiteTemplateBox"
        id="whiteTemplateBox"
      >
        <div className="mb-4 flex items-center justify-between">
          <div
            className=""
            style={{ height: '124px', width: '124px', overflow: 'content' }}
          >
            <img
              src={imageSrc}
              alt="Hospital Logo"
              className="h-full w-full object-contain"
            />
            {/* <img
                src={props?.reportTemplate?.hospitalLogo}
                alt="Hospital Logo"
                className="h-10 w-32 text-black"
              /> */}
          </div>
          <div className="text-normal text-right text-black">
            <p>{ReportData?.hospitalHeader?.hospitalName}</p>
            <p>{ReportData?.hospitalHeader?.address}</p>
            <p>
              {ReportData?.hospitalHeader?.city}, {ReportData?.hospitalHeader?.zipCode}
            </p>
            <p>
              <strong>Tel: </strong>({ReportData?.hospitalHeader?.contactNumberCountryCode}){' '}
              {ReportData?.hospitalHeader?.contactNumber} <strong>Fax: </strong>
              {ReportData?.hospitalHeader?.fax}
            </p>
            <p>
              <strong>E: </strong> {ReportData?.hospitalHeader?.email}
            </p>
            <p>
              <strong>W: </strong>{' '}
              <a
                href={ReportData?.hospitalHeader?.website}
                className="text-primary"
              >
                {ReportData?.hospitalHeader?.website}
              </a>
            </p>
          </div>
        </div>
        <ReportLayout
          reportTemplate={ReportData}
          setEditorContent={setEditorContent}
          editorContent={editorContent}
          setUpdatedTemplateContent={setUpdatedTemplateContent}
        />
        {reportData?.data?.reportType == 'Stress Echo 2' && (
          <div className="report-diagrams">
            <div className="target">
              <RestBullsEyePlot />
              <StressBullsEyePlot />

              <div className="target-code">
                <div className="target-code-container">
                  <div className="color-code color-green"></div>
                  <div className="color-name">Normal (blank)</div>
                </div>
                <div className="target-code-container">
                  <div className="color-code color-sky-blue"></div>
                  <div className="color-name">Hyperkinetic (1)</div>
                </div>
                <div className="target-code-container">
                  <div className="color-code color-light-yellow"></div>
                  <div className="color-name">Mildly hypokinetic (2)</div>
                </div>
                <div className="target-code-container">
                  <div className="color-code color-yellow"></div>
                  <div className="color-name">Moderately hypokinetic (3)</div>
                </div>
                <div className="target-code-container">
                  <div className="color-code color-orange"></div>
                  <div className="color-name">Severely hypokinetic (4)</div>
                </div>
                <div className="target-code-container">
                  <div className="color-code color-red"></div>
                  <div className="color-name">Akinetic (5)</div>
                </div>
                <div className="target-code-container">
                  <div className="color-code color-grey"></div>
                  <div className="color-name">Dyskinetic (6)</div>
                </div>
                <div className="target-code-container">
                  <div className="color-code color-purple"></div>
                  <div className="color-name">Thinned and scarred (7)</div>
                </div>
              </div>
            </div>
            <div className="rest-heart-chambers">
              <p className="title title--h5 title--semibold">Rest</p>
              <Apical2Ch />
              <Apical3Ch />
              <Apical4Ch />
            </div>
            <div className="rest-heart-chambers">
              <p className="title title--h5 title--semibold">Stress</p>
              <Apical2Ch />
              <Apical3Ch />
              <Apical4Ch />
            </div>
          </div>
        )}
      </div>
      <div className="button-group">
        <button
          onClick={handleSave}
          className={`save-button text-black`}
        >
          Update Report
        </button>
      </div>
      <Loader isLoading={loading} />
      <Success
        handleCloseModal={() => setIsSuccessModalOpen(false)}
        title={displayMessage}
        isSuccessModalOpen={isSuccessModalOpen}
      />
      <Failed
        handleCloseModal={() => setIsFailedModalOpen(false)}
        title={displayMessage}
        isFailedModalOpen={isFailedModalOpen}
      />
    </>
  );
}
