import React from 'react';
import '../styled/pages/dashboard.scss';

const ConfirmationModal = ({
  title,
  description,
  isConfirmationModalOpen,
  handleCloseModal,
  handleConfirm,
  cancelActionText,
  confirmActionText,
}) => {
  if (!isConfirmationModalOpen) return null;

  return (
    <div className="modal modalTrash modal--open">
      <div className="modal__dialog">
        <div className="modal__content">
          <div className="modal__header">
            <h4 className="title title--h4 title--semibold">{title}</h4>
            <p className="para">{description}</p>
          </div>
          <div className="modal__footer">
            <div className="btn__group">
              <button
                className="btn btn__white btn__outlined"
                onClick={handleCloseModal}
              >
                {cancelActionText}
              </button>
              <button
                className="btn btn__primary"
                onClick={handleConfirm}
              >
                {confirmActionText}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal__backdrop"></div>
    </div>
  );
};

export default ConfirmationModal;
